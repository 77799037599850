import React, { useState, useEffect } from "react";
import { Paper, Button, TextField, Grid, Autocomplete } from "@mui/material";
import {
  getEstadoManifiestoRutaxId,
  getEstadoManifiestoRutaChofer,
  generarExcelRutasOperaciones,
} from "../../../services/operacionesService";
import TablaManifiestos from "../componentesManifiestos/TablaManifiestos";
import { useSelector } from "react-redux";
import { getChoferesXId } from "../../../services/ManifiestosServices";

const EstadoManifiestoRuta = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [manifiestoId, setManifiestoId] = useState("");
  const [dataOrdenes, setDataOrdenes] = useState([]);
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [choferes, setChoferes] = useState([]);
  const [fecha, setFecha] = useState("");
  const [resetForm, setResetForm] = useState(false);
  const [isDescargarExcelDisabled, setIsDescargarExcelDisabled] = useState(false);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "MANIFIESTO", headerName: "MANIFIESTO", width: 200 },
    { field: "PROCESO", headerName: "PROCESO", width: 200 },
    { field: "CLIENTE_NOMBRE", headerName: "CLIENTE", width: 400 },
    { field: "ULTIMO_ESTADO", headerName: "ESTADO", width: 200 },
    { field: "FECHA_ULTIMO_ESTADO", headerName: "FECHA", width: 200 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const choferesData = await getChoferesXId(userInfo.id);
        const choferesOptions = choferesData.map((chofer) => ({
          value: chofer.COD_CHOFER,
          label: chofer.NOMBRE,
        }));
        setChoferes(choferesOptions);
      } catch (error) {
        console.error("Error al obtener choferes:", error);
      }
    };
    fetchData();
  }, [userInfo.id]);

  const handleChange = (event) => setManifiestoId(event.target.value);
  const handleChangeChoferes = (event, newValue) => setSelectedChofer(newValue);
  const handleChangeFecha = (e) => setFecha(e.target.value);

  const handleGenerarClick = async () => {
    setDataOrdenes([]);
    const data = await getEstadoManifiestoRutaxId(manifiestoId);
    const dataTransformada = data.map((item) => ({
      ...item,
      ULTIMO_ESTADO: item.ULTIMO_ESTADO || "EN TRANSITO",
    }));
    setDataOrdenes(dataTransformada);
  };

  const handleBuscar = async () => {
    setDataOrdenes([]);
    const data = await getEstadoManifiestoRutaChofer(selectedChofer?.value, userInfo.iata_agentes, fecha);
    const dataTransformada = data.map((item) => ({
      ...item,
      ULTIMO_ESTADO: item.ULTIMO_ESTADO || "EN TRANSITO",
    }));
    setDataOrdenes(dataTransformada);
  };

  const handleReiniciar = () => {
    setManifiestoId("");
    setSelectedChofer(null);
    setFecha("");
    setResetForm(true);
    setTimeout(() => setResetForm(false), 0);
  };

  const handleDescargarExcel = async () => {
    setIsDescargarExcelDisabled(true);
    console.log("Descargar Excel");
    const data = await generarExcelRutasOperaciones();
    console.log(data);
    setIsDescargarExcelDisabled(false);
  };

  // Determinar deshabilitación respetando las condiciones originales
  const isManifiestoDisabled = !resetForm && (!!selectedChofer || !!fecha);
  const isChoferFechaDisabled = !resetForm && !!manifiestoId;

  return (
    <Paper style={{ padding: "10px", marginBottom: "10px" }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {userInfo.tipo_usuario !== "AGENTE" &&
          userInfo.tipo_usuario !== "AGENTE_HUB" &&
          userInfo.tipo_usuario !== "AGENTE_ADMIN" && (
            <>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <TextField
                  label="Número de Manifiesto"
                  variant="outlined"
                  value={manifiestoId}
                  type="search"
                  size="small"
                  onChange={handleChange}
                  fullWidth
                  disabled={isManifiestoDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Button
                  variant="contained"
                  onClick={handleGenerarClick}
                  sx={{
                    backgroundColor: "#041562",
                    color: "#FFFFFF",
                    "&:hover": { backgroundColor: "#030e4f" },
                  }}
                  disabled={isManifiestoDisabled}
                >
                  Buscar
                </Button>
              </Grid>
            </>
          )}
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Autocomplete
            disableClearable
            value={selectedChofer}
            disablePortal
            size="small"
            options={choferes}
            onChange={handleChangeChoferes}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="CHOFER" />}
            disabled={isChoferFechaDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <TextField
            fullWidth
            type="date"
            size="small"
            label="Fecha"
            value={fecha}
            onChange={handleChangeFecha}
            InputLabelProps={{ shrink: true }}
            disabled={isChoferFechaDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Button
            variant="contained"
            onClick={handleBuscar}
            sx={{
              backgroundColor: "#041562",
              color: "#FFFFFF",
              "&:hover": { backgroundColor: "#030e4f" },
            }}
            disabled={isChoferFechaDisabled}
          >
            Buscar
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          {userInfo.tipo_usuario !== "AGENTE" &&
            userInfo.tipo_usuario !== "AGENTE_HUB" &&
            userInfo.tipo_usuario !== "AGENTE_ADMIN" && (
              <Button variant="contained" onClick={handleDescargarExcel} disabled={isDescargarExcelDisabled} sx={{ backgroundColor: "#3d834c", color: "#FFFFFF", "&:hover": { backgroundColor: "#2e623e" } }}>
                DESCARGAR EXCEL
              </Button>
            )}
        </Grid>
        {userInfo.tipo_usuario !== "AGENTE" &&
          userInfo.tipo_usuario !== "AGENTE_HUB" &&
          userInfo.tipo_usuario !== "AGENTE_ADMIN" && (
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Button variant="outlined" onClick={handleReiniciar} sx={{ borderColor: "#041562", color: "#041562" }}>
                Volver a Habilitar
              </Button>
            </Grid>
          )}
      </Grid>
      <Grid style={{ marginTop: "10px" }}>
        <TablaManifiestos rows={dataOrdenes} columns={columns} />
      </Grid>
    </Paper>
  );
};

export default EstadoManifiestoRuta;
