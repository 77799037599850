import {
  Box,Card,CardContent,Typography,Grid,TextField,
  Modal, Chip, IconButton,Fade,Backdrop, Autocomplete
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Close';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import { useEffect } from 'react';

export function ReturnCard({ data, isDelayed, onDelete, onUpdate }){
  console.log(data)
  return (
    <Card 
      sx={{ 
        mb: 2, 
        border: isDelayed ? '1px solid #ff4444' : '1px solid #2196f3',
        boxShadow: 3
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
          <Typography variant="h6">
            {data.client}
          </Typography>
          <Box>
            <IconButton 
              color="primary" 
              onClick={() => onUpdate(data)}
              size="small"
            >
              <EditIcon />
            </IconButton>
            <IconButton 
              color="error" 
              onClick={() => onDelete(data)}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <LocationOnIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary">
            {data.direccion}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <PersonIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary">
            Conductor: {data.driver}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <DirectionsCarIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary">
            Vehículo: {data.vehicle}
          </Typography>
        </Box>

        <Typography variant="subtitle2" gutterBottom>
          Horarios programados:
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
          {data.timeSlots.map((time, index) => (
            <Chip
              key={index}
              icon={<AccessTimeIcon />}
              label={time}
              size="small"
              color={isDelayed ? "error" : "primary"}
              variant="outlined"
            />
          ))}
        </Box>

      {/*
      
        <Typography variant="subtitle2" gutterBottom>
          Personal de apoyo: {data.length === 0 && ("N/A")}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {data.apoyoSlots.map((person, index) => (
            <Chip
              key={index}
              icon={<PersonIcon />}
              label={person}
              size="small"
              variant="outlined"
            />
          ))}
        </Box>
      */}
      </CardContent>
    </Card>
  );
};

export function SearchFilters ({ filters, onFilterChange }) {
  return (
    <Box sx={{ mb: 3 }}>
    <Typography>Filtra tu busqueda</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Cliente"
            value={filters.client}
            onChange={(e) => onFilterChange('client', e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Conductor"
            value={filters.driver}
            onChange={(e) => onFilterChange('driver', e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Vehículo"
            value={filters.vehicle}
            onChange={(e) => onFilterChange('vehicle', e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Dirección"
            value={filters.direccion}
            onChange={(e) => onFilterChange('direccion', e.target.value)}
            size="small"
          />
        </Grid>
      </Grid>
    </Box>
  );
};


export function BeautifulModal ({ open, handleClose, title, children }) {
  // Modal styling
  const modalStyle = {
    position: "absolute", 
    top: "50%", 
    left: "50%", 
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 960 ? "95%": "95%",  
    height: window.innerHeight < 600 ? "95%": "95%",
    display: "flex",
    overflowY: "scroll",  
    flexDirection: "column",
    bgcolor: "background.paper", 
    borderRadius: 2, 
    boxShadow: 24, 
    p: 3, 
    outline: "none", 
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "0.5rem",
    mb: 2,
  };

  const closeButtonStyle = {
    position: "absolute",
    right: "1rem",
    top: "1rem",
    color: "grey.500",
  };

  return (
    <Modal

    open={open}
    onClose={handleClose}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{ backdrop: { timeout: 500 } }}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
    >
    <Fade in={open}>
    <Box sx={modalStyle}>
    <IconButton
    aria-label="close"
    onClick={handleClose}
    sx={closeButtonStyle}
    >
    <CloseIcon />
    </IconButton>

    <Box sx={headerStyle}>
    <Typography id="modal-title" variant="h6" component="h2">
    {title}
    </Typography>
    </Box>

    <Box id="modal-description">
    {children}
    </Box>
    </Box>
    </Fade>
    </Modal>
  );
};

export const formatTimeWithRules = (timeString) => {
  // Asegurarse de que tenemos un formato válido
  if (!timeString) return null;

  // Extraer horas y minutos
  let [hours, minutes] = timeString.split(':').map(num => parseInt(num));

  // Si la hora es menor a 10
  if (hours < 10) {
    if (minutes > 10) {
      if (minutes > 1 && minutes < 5) {
        minutes = 0;
      } else if (minutes >= 5 && minutes < 9) {
        hours += 1;
        minutes = 0;
      } else {
        // Redondear a la decena más cercana
        minutes = Math.round(minutes / 10) * 10;
      }
    }
  } else {
    // Para horas >= 10, solo redondear minutos a la decena más cercana
    minutes = Math.round(minutes / 10) * 10;
  }

  // Formatear el resultado
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

// Añadir esta función de ayuda para extraer las horas existentes
export const getExistingTimes = (retirosAgregados, clientName) => {
  const existingTimes = new Set();
  
  if (retirosAgregados[clientName]) {
    // Revisar horas en retrasados
    Object.values(retirosAgregados[clientName].retrasados).forEach(retiros => {
      retiros.forEach(retiro => {
        // Almacenar hora junto con el nombre del chofer
        existingTimes.add(`${retiro.HORA_RECOLECCION.substring(0, 5)}|${retiro.NOMBRE_CHOFER}`);
      });
    });
    
    // Revisar horas en aTiempo
    Object.values(retirosAgregados[clientName].aTiempo).forEach(retiros => {
      retiros.forEach(retiro => {
        // Almacenar hora junto con el nombre del chofer
        existingTimes.add(`${retiro.HORA_RECOLECCION.substring(0, 5)}|${retiro.NOMBRE_CHOFER}`);
      });
    });
  }
  
  return existingTimes;
};

export function AddressAutoCompleter({asignedAddresses, setSelectedAddress, storedData, value}){
  useEffect(() => {
    if (asignedAddresses.length === 0) {
      setSelectedAddress(null);
      return;
    }
    
    // Más lógica...
  }, [asignedAddresses, storedData, value, setSelectedAddress]);

  return (
    <>
    {asignedAddresses.length > 0 && (
      <Autocomplete
        id="direcciones"
        options={asignedAddresses}
        getOptionLabel={(option) => option.DIRECCION}
        onChange={(_, newValue) => setSelectedAddress(newValue)}
        value={value} 
        // Más props...
      />
    )}
    </>
  )
}