import React, { useState, useRef } from "react";
import { Grid, Button, Card, CardContent, Paper, TextField, Autocomplete, Container } from "@mui/material";
import Tabla from "../../components/OperacionesComponentes/componentesCubicos/tabla";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { updateVia } from "../../services/ordenesServices";
import {
  toastDatosEnBlanco,
  ordenNoModificada,
  ordenModificada,
} from "../../components/OperacionesComponentes/componentesCubicos/Alertas";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ValidadorArchivo,
  limiteDeFilas,
  LinearProgressOverlay,
} from "../../components/OperacionesComponentes/componentesCubicos/Funciones";
import "../../components/assets/retiros.css";
import { useSelector } from "react-redux";

const GestorOrdenesMasivas = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const id_usuario = userInfo ? userInfo.id : null;
  const [key, setKey] = useState(Date.now());
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [jsonDataArray, setJsonDataArray] = useState([]);
  const viaOption = ["AEREO", "TERRESTRE"];
  const [via, setVia] = React.useState(null);

  // La cabecera predefinida, excel debe terner los mismo datos.
  const predefinedHeaders = ["OD"];

  // Las columnas obligatorias segun cabecera
  const obligatoriasHeaders = ["OD"];

  //Funcion validar que la cabecera del excel sea la misma del codigo
  const validateHeaders = (parsedData, predefinedHeaders) => {
    const fileHeaders = parsedData.data[0];
    console.log("Encabezados del archivo CSV:", fileHeaders);
    console.log("Encabezados predefinidos:", predefinedHeaders);
    for (let i = 0; i < predefinedHeaders.length; i++) {
      if (fileHeaders[i] !== predefinedHeaders[i]) {
        return false;
      }
    }
    return true;
  };
  const cargaExcelTabla = async (e) => {
    if (!via) {
      toast.error("Debe seleccionar una Via");
      fileInputRef.current.value = "";
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    await new Promise((resolve) => {
      reader.onload = async (event) => {
        const binaryString = new Uint8Array(event.target.result).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          "",
        );

        const workbook = XLSX.read(binaryString, { type: "binary" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvData = XLSX.utils.sheet_to_csv(firstSheet);

        Papa.parse(csvData, {
          complete: async (parsedData) => {
            const headersMatch = validateHeaders(parsedData, predefinedHeaders);

            if (ValidadorArchivo(headersMatch)) {
              Papa.parse(csvData, {
                complete: (parsedData) => {
                  const newHeaders = predefinedHeaders;
                  setHeaders(newHeaders);

                  const dataSinObjetosVacios = parsedData.data
                    .slice(0)
                    .map((row) => {
                      let obj = {};
                      newHeaders.forEach((header, index) => {
                        obj[header] = row[header];
                      });

                      return obj;
                    })
                    .filter((obj) => {
                      for (const key in obj) {
                        if (obj[key] !== "") {
                          return true;
                        }
                      }
                      return false;
                    });

                  setData(dataSinObjetosVacios);

                  parsedData.data.slice(0).forEach((row, index) => {
                    const rowData = {};
                    rowData["OD"] = row["OD"];
                    rowData["VIA"] = via;

                    rowData["USUARIO"] = id_usuario;

                    if (limiteDeFilas(row)) {
                      jsonDataArray.push(JSON.stringify(rowData));
                    }
                  });

                  for (let rowIndex = 0; rowIndex < parsedData.data.length; rowIndex++) {
                    const row = parsedData.data[rowIndex];
                    if (limiteDeFilas(row)) {
                      const columnasFaltantes = obligatoriasHeaders.filter((columna) => !row[columna]);
                      if (columnasFaltantes.length > 0) {
                        toastDatosEnBlanco(columnasFaltantes.join(", "), rowIndex + 2);
                        e.target.value = "";
                        setData([]);
                        setJsonDataArray([]);
                        return;
                      } else {
                        resolve();
                      }
                    }
                  }
                },
                header: true,
              });
              setProgress(100);
              setIsVisible(false);
              setProgress(0);
              setCompleted(false);
            } else {
              e.target.value = "";
              setKey(Date.now());
              fileInputRef.current.value = "";
              setData([]);
              setJsonDataArray([]);
            }
          },
        });
      };
    });
  };

  //Limpiamos Array
  const LimpiaArray = (e) => {
    setJsonDataArray([]);
  };

  const resetState = () => {
    setData([]);
    setJsonDataArray([]);
    setIsVisible(false);
    setProgress(0);
    setCompleted(false);
    fileInputRef.current.value = "";
    setVia(null);
  };

  //Actualizacion de datos
  const subirDatos = async () => {
    if (!via) {
      toast.error("Debe seleccionar una via");
      return;
    }
    if (!fileInputRef.current || !fileInputRef.current.files[0]) {
      toast.error("Debe adjuntar un archivo");
      return;
    }

    const totalOrders = jsonDataArray.length;
    let updateOrdersCount = 0;

    const updateOrders = [];

    for (const jsonData of jsonDataArray) {
      try {
        setIsVisible(true);

        const response = await updateVia(jsonData);

        updateOrdersCount++;
        const newProgress = (updateOrdersCount / totalOrders) * 100;
        setProgress(newProgress);

        if (response && response.success) {
          updateOrders.push(response);
          const newProgress = (updateOrdersCount / totalOrders) * 100;
          setProgress(newProgress);
          if (newProgress === 100) {
            setCompleted(true);
          }
        }
      } catch (error) {
        toast.error("Error al modificar ordenes. Por favor, inténtalo de nuevo.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsVisible(false);
        setProgress(0);
        setCompleted(false);
      }
    }
    setProgress(100);

    if (updateOrdersCount > 0) {
      ordenModificada();
      setIsVisible(false);
      setProgress(0);
      setCompleted(false);
      //resetState();

      setTimeout(() => {
        setKey(Date.now());
        fileInputRef.current.value = "";
        setData([]);
        setJsonDataArray([]);
        resetState();
      }, 2000);
    } else {
      ordenNoModificada();
    }
  };

  const handleChangeVia = (event, newValue) => {
    setVia(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Paper style={{ padding: "10px" }}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Autocomplete
                value={via}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={viaOption}
                onChange={handleChangeVia}
                sx={{ width: "100%" }}
                isOptionEqualToValue={(option, value) => 
                  option === value || (!option && !value)
                }
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="VIA" />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Form.Group controlId="formFile">
                <Form.Label className="tu-archivo-de-estilos1">Seleccione Archivo Excel</Form.Label>
                <Form.Control
                  type="file"
                  ref={fileInputRef}
                  key={key}
                  onChange={cargaExcelTabla}
                  onClick={LimpiaArray}
                  size="sm"
                />
              </Form.Group>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end" marginTop={"15px"}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={subirDatos}
              >
                Enviar
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#DA251C",
                }}
                onClick={resetState}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
          <div>
            <LinearProgressOverlay isVisible={isVisible} progress={progress} completed={completed} disabled={false} />
          </div>
          {data.length > 0 && (
            <Grid item xs={10} sm={6} md={6} lg={6} style={{ width: "100%", maxWidth: "100%" }}>
              <Card elevation={10} className="cardContentWithMargin">
                <CardContent
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    Width: "100%",
                    maxHeight: "75vh",
                    overflowX: "auto",
                    overflowY: "auto",
                  }}
                >
                  <Tabla
                    data={data}
                    headers={headers}
                    onCpdateellU={(rowIndex, header, newValue) => {
                      const newData = [...data];
                      newData[rowIndex][header] = newValue;
                      setData(newData);
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Form>
      </Paper>
    </Container>
  );
};

export default GestorOrdenesMasivas;
