import {
  Box,Typography,TextField,Autocomplete,Avatar,Chip
} from '@mui/material';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import BadgeIcon from '@mui/icons-material/Badge';
import { useEffect, useState, Fragment } from 'react';
import WarningIcon from '@mui/icons-material/Warning';

const colors = {red:"#DA251C", blue:"#041562",green:"#046A38",orange:"#FF7700" }
const getInitials = (name) => {
  if(!name) {
    return
  }
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};


export const ClientesAutocompleter = ({
  clientes,
  selectedClient,
  setSelectedClient,
  setAsignedAddresses,
  retirosAgregados,
  setClienteExistente
}) => {
  // Estado para controlar si el usuario ha cambiado manualmente
  const [userManuallyChanged, setUserManuallyChanged] = useState(false);

  // Efecto para verificar si el cliente tiene retiros existentes al cargar
  useEffect(() => {
    if (selectedClient && retirosAgregados && typeof setClienteExistente === 'function' && !userManuallyChanged) {
      // Buscar si hay alguna entrada en retirosAgregados que comience con el nombre del cliente
      const tieneRetiros = Object.keys(retirosAgregados).some(key => 
        key.startsWith(`${selectedClient.NOMBRE} - `) || key === selectedClient.NOMBRE
      );
      
      setClienteExistente(tieneRetiros);
    }
  }, [selectedClient, retirosAgregados, setClienteExistente, userManuallyChanged]);

  return (
    <Autocomplete
      options={clientes}
      getOptionLabel={(option) => `${option.NOMBRE}`}
      isOptionEqualToValue={(option, value) => {
        // Comparar por ID si está disponible, si no por nombre
        if (option.ID && value.ID) {
          return option.ID === value.ID;
        }
        return option.NOMBRE === value.NOMBRE;
      }}
      onChange={(_, newValue) => {
        setUserManuallyChanged(true);
        setSelectedClient(newValue);
        setAsignedAddresses([]);
        
        if (!newValue) {
          if(typeof setClienteExistente === 'function') {
            setClienteExistente(false);
          }
        } else {
          // Verificar si el cliente tiene retiros existentes
          if(retirosAgregados && typeof setClienteExistente === 'function') {
            // Buscar si hay alguna entrada en retirosAgregados que comience con el nombre del cliente
            const tieneRetiros = Object.keys(retirosAgregados).some(key => 
              key.startsWith(`${newValue.NOMBRE} - `) || key === newValue.NOMBRE
            );
            
            setClienteExistente(tieneRetiros);
          }
        }
      }}
      value={selectedClient}
      renderOption={(props, option) => (
        <li {...props} 
          key={option.ID}
          style={{
            padding: '8px 16px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 0.5
          }}>
            <Typography variant="body1">
              {option.NOMBRE}
            </Typography>
            {retirosAgregados && Object.keys(retirosAgregados).some(key => 
              key.startsWith(`${option.NOMBRE} - `) || key === option.NOMBRE
            ) && (
              <Typography 
                variant="caption" 
                color="warning.main"
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
              >
                <WarningIcon sx={{ fontSize: '0.875rem' }} />
                Pick up pendientes
              </Typography>
            )}
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Cliente"
          variant="outlined"
          error={!selectedClient}
          helperText={!selectedClient ? "Por favor seleccione un cliente" : ""}
        />
      )}
    />
  );
};


export function AddressAutoCompleter({asignedAddresses, setSelectedAddress, storedData, value}){
  const [userManuallyChanged, setUserManuallyChanged] = useState(false);

  // Función para normalizar direcciones
  const normalizeAddress = (address) => {
    if (!address) return '';
    return address
      .trim()
      .toLowerCase()
      .replace(/\s+/g, ' ')                    // Espacios múltiples a uno
      .replace(/boulevard poniente/g, 'boulevard')  // Normalizar boulevard
      .replace(/boulevar/g, 'boulevard')        // Corregir typo común
      .replace(/\s*-\s*/g, '-')                // Normalizar guiones
      .replace(/\s+y\s+/g, '-')                // Normalizar "y" a guión
      .replace(/\s+(\d)/g, ' $1')              // Espacio antes de números
      .replace(/(\d)\s+/g, '$1 ');             // Espacio después de números
  };

  // Función para normalizar el canal
  const normalizeCanal = (canal) => {
    if (!canal) return '';
    const base = canal.trim().toLowerCase();
    // Grupos de canales similares
    const canalGroups = {
      'lexmark': ['lexmark', 'lexmark-cl', 'lexmarx'],
      'ibm': ['ibm', 'ibm de chile s.a.c', 'ibm-cl'],
      'toshiba': ['toshiba', 'tosbiha', 'toshiba gcs chile spa', 'toshiba-cl'],
      'schneider': ['schneider', 'schenider', 'schneider electric c']
    };

    // Encontrar el grupo al que pertenece el canal
    for (const [mainCanal, variations] of Object.entries(canalGroups)) {
      if (variations.some(v => base.includes(v))) {
        return mainCanal;
      }
    }
    return base;
  };

  // Filtrar direcciones únicas considerando normalización
  const duplicateAddresses = [];
  const uniqueAddresses = asignedAddresses.reduce((unique, current) => {
    const normalizedAddr = normalizeAddress(current.DIRECCION);
    const normalizedCanal = normalizeCanal(current.CANAL);
    
    // Buscar si ya existe una dirección similar
    const existingAddr = unique.find(item => 
      normalizeAddress(item.DIRECCION) === normalizedAddr &&
      normalizeCanal(item.CANAL) === normalizedCanal
    );

    if (!existingAddr) {
      unique.push(current);
    } else {
      // Guardar la dirección duplicada junto con la dirección original que causó el duplicado
      duplicateAddresses.push({
        duplicate: current,
        original: existingAddr,
        normalizedAddr,
        normalizedCanal
      });
    }

    return unique;
  }, []);

  // Debug logs
  /*
  console.log('Direcciones originales:', asignedAddresses);
  console.log('Direcciones únicas:', uniqueAddresses);
  console.log('Direcciones duplicadas:', duplicateAddresses.map(item => ({
    duplicada: {
      direccion: item.duplicate.DIRECCION,
      canal: item.duplicate.CANAL
    },
    original: {
      direccion: item.original.DIRECCION,
      canal: item.original.CANAL
    },
    normalizadaA: {
      direccion: item.normalizedAddr,
      canal: item.normalizedCanal
    }
  })));
  */

  useEffect(() => {
    if (uniqueAddresses.length === 0) {
      setSelectedAddress(null);
      return;
    }
    
    // Solo aplicar la lógica de storedData si el usuario no ha cambiado manualmente
    if (!userManuallyChanged) {
      const hasValidRecoleccion = storedData?.recoleccion && storedData.recoleccion.length === 1;
      if (hasValidRecoleccion) {
        const direccion = storedData.recoleccion[0].DIRECCION;
        const direccionEncontrada = uniqueAddresses.find(addr => addr.DIRECCION === direccion);
        if (direccionEncontrada) {
          setSelectedAddress(direccionEncontrada);
        }
      } else if (!value) {
        setSelectedAddress(null);
      }
    }
  }, [uniqueAddresses, storedData, value, setSelectedAddress, userManuallyChanged]);

  return (
    <>
    {uniqueAddresses.length > 0 && (
      <Autocomplete
        id="direcciones"
        options={uniqueAddresses}
        getOptionLabel={(option) => option.DIRECCION}
        onChange={(_, newValue) => {
          setSelectedAddress(newValue);
          // Marcar que el usuario ha cambiado manualmente
          setUserManuallyChanged(true);
        }}
        value={value}
        renderOption={(props, option) => (
          <li {...props} key={`${option.DIRECCION}_${option.CIUDAD}_${option.ID || Math.random()}`}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '8px 0',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    sx={{ fontWeight: 500, color: 'primary.main' }}
                  >
                    {option.DIRECCION}
                  </Typography>
                  
                  {(option.ES_DIRECCION_PRINCIPAL || option.id === 'principal') && (
                    <Chip
                      label="Principal"
                      size="small"
                      sx={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        ml: 1,
                        height: '20px',
                        '& .MuiChip-label': {
                          px: 1,
                          fontSize: '0.7rem',
                          fontWeight: 500
                        }
                      }}
                    />
                  )}
                </Box>
                
                {option.CANAL && (
                  <Chip
                    label={option.CANAL}
                    size="small"
                    sx={{
                      backgroundColor: '#041562',
                      color: 'white',
                      ml: 1,
                      height: '20px',
                      '& .MuiChip-label': {
                        px: 1,
                        fontSize: '0.7rem',
                        fontWeight: 500
                      }
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mt: 0.5,
                }}
              >
                <LocationOnIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: 'text.secondary' }}
                >
                  {option.CIUDAD}
                </Typography>
              </Box>
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField 
            {...params} 
            label="Dirección cliente" 
            variant="outlined" 
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {value?.ES_DIRECCION_PRINCIPAL || value?.id === 'principal' ? (
                    <Chip
                      label="Principal"
                      size="small"
                      sx={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        mr: 1,
                        height: '20px',
                        '& .MuiChip-label': {
                          px: 1,
                          fontSize: '0.7rem',
                          fontWeight: 500
                        }
                      }}
                    />
                  ) : null}
                  {value?.CANAL && (
                    <Chip
                      label={value.CANAL}
                      size="small"
                      sx={{
                        backgroundColor: '#041562',
                        color: 'white',
                        mr: 1,
                        height: '20px',
                        '& .MuiChip-label': {
                          px: 1,
                          fontSize: '0.7rem',
                          fontWeight: 500
                        }
                      }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    )}
    </>
  )
}



export function ConductorAutocompelter({
  choferes, 
  setAsignedVehicles, 
  setSelectedDriver, 
  setSelectedVehicle, 
  selectedClient,
  value,
  slots = []
}){
  // Solo mantener la limpieza cuando el cliente es NULL
  useEffect(() => {
    if (!selectedClient) {
      setSelectedDriver(null);
      setAsignedVehicles([]);
      setSelectedVehicle(null);
    }
  }, [selectedClient, setSelectedDriver, setAsignedVehicles, setSelectedVehicle]);
  
  // Remove the filtering that excludes drivers already selected in support slots
  const filteredChoferes = choferes;

  return(
    <Autocomplete
      options={filteredChoferes}
      getOptionLabel={(option) => `${option.NOMBRE} / ${option.RUT}`} 
      onChange={(_, newValue) => {
        if(!newValue) {
          setSelectedDriver(null);
          setAsignedVehicles([]);
        } else {
          setSelectedDriver(newValue);
          // Ya no limpiamos el vehículo aquí
        }
      }}
      value={value}
      isOptionEqualToValue={(option, value) => {
        if (!option || !value) return false;
        return option.COD_CHOFER === value.COD_CHOFER || option.RUT === value.RUT;
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.ID || option.COD_CHOFER}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: 2,
            padding: '8px',
          }}>
            <Avatar sx={{
              bgcolor: `${colors.blue}`,
              width: 40,
              height: 40,
            }}>
              {getInitials(option.NOMBRE)}
            </Avatar>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}>
              <Typography variant="subtitle1" sx={{
                fontWeight: 500,
                color: 'text.primary',
              }}>
                {option.NOMBRE}
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mt: 0.5,
              }}>
                <BadgeIcon sx={{
                  color: 'text.secondary',
                  fontSize: 16,
                }}/>
                <Typography variant="body2" sx={{
                  color: 'text.secondary',
                  fontFamily: 'monospace',
                  letterSpacing: '0.5px',
                }}>
                  {option.RUT}
                </Typography>
              </Box>
            </Box>
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Chofer responsable" 
          variant="outlined" 
          error={!value}
          helperText={!value ? "Por favor seleccione un chofer" : ""}
        />
      )}
    />
  )
}



export function AsignedVehicleAutocompleter({
  asignedVehicles, 
  setSelectedVehicle, 
  value,
  selectedDriver
}){
  if (!selectedDriver) {
    return (
      <TextField
        label="Vehículo"
        variant="outlined"
        fullWidth
        disabled
        value=""
        helperText="Seleccione un conductor primero"
      />
    );
  }
  
  if (!asignedVehicles || asignedVehicles.length === 0) {
    return (
      <TextField
        label="Vehículo"
        variant="outlined"
        fullWidth
        disabled
        value=""
        helperText="No se encontraron vehículos asignados a este conductor"
        error
      />
    );
  }
  
  return (
    <Autocomplete
      options={asignedVehicles}
      getOptionLabel={(option) => 
        option ? `${option.PATENTE || ''} ${option.MODELO ? `- ${option.MODELO}` : ''}` : ''
      }
      value={value}
      onChange={(_, newValue) => {
        setSelectedVehicle(newValue);
      }}
      isOptionEqualToValue={(option, val) => {
        if (!option || !val) return false;
        return option.PATENTE === val.PATENTE;
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.PATENTE}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '8px 0',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
          }}>
            <Typography component="span" variant="subtitle1" sx={{ fontWeight: 500, color: 'primary.main' }}>
              {option.PATENTE}
            </Typography>
            {option.MODELO && (
              <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                {option.MODELO}
              </Typography>
            )}
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Vehículo"
          variant="outlined"
          fullWidth
          error={!value}
          helperText={!value ? "Seleccione un vehículo" : ""}
        />
      )}
    />
  );
}
