import React from "react";
import { Paper } from "@mui/material";

const YoutubeVideo = ({ videoId = "e5Rps0wHpJc" }) => {
  return (
    <Paper elevation={3} sx={{ p: 1, mt: 2 }}>
      <iframe
        width="100%"
        height="400"
        src={`https://www.youtube-nocookie.com/embed/${videoId}`}
        title="YouTube video player"
        loading="lazy"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Paper>
  );
};

export default YoutubeVideo; 