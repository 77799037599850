import {
  Box,
  Typography,
  Select,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';


// Componenete Horario
export default function DaySchedule ({ day, selectedHours, onHourSelect, onDelete }) {
  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0');
    return `${hour}:00:00`;
  });

  return (
    <Grid item xs={6} sm={4} md={3} key={day}>
    <Box sx={{ mb: 1 }}>
    <Typography variant="body2" sx={{ mb: 1, fontWeight: 'bold' }}>
    {day}
    </Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
    <FormControl size="small" sx={{ minWidth: 90 }}>
    <InputLabel id={`${day}-label`} sx={{ fontSize: '0.8rem' }}>Añadir hora</InputLabel>
    <Select
    labelId={`${day}-label`}
    value=""
    label="Añadir hora"
    onChange={(e) => onHourSelect(day, e.target.value)}
    sx={{ fontSize: '0.8rem' }}
    >
    {hours.map(hour => (
      <MenuItem
      key={hour}
      value={hour}
      sx={{
        backgroundColor: selectedHours.includes(hour)
          ? 'action.selected'
          : 'inherit',
          fontSize: '0.8rem',
      }}
      >
      {hour}
      {selectedHours.includes(hour) && ' ✓'}
      </MenuItem>
    ))}
    </Select>
    </FormControl>
    </Box>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width:selectedHours.length === 0  ? "100%" : "400px" }}>
    <Grid container spacing={0} sx={{width:"160px"}}>
    {selectedHours.length === 0 && (
      <Typography color="text.secondary" variant="body2" sx={{ fontSize: '0.75rem'}}>
      No se han seleccionado horarios
      </Typography>
    )}
    {selectedHours.map((hour) => (
      <Grid sx={{ mr:2}} item xs={6} key={hour}> 
      <Chip
      label={hour}
      onDelete={() => onDelete(day, hour)}
      color="primary"
      variant="outlined"
      size="small"
      sx={{ fontSize: '0.75rem' }}
      />
      </Grid>
    ))}
    </Grid>
    </Box>
    </Box>
    </Grid>
  );
};

