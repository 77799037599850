import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { deletePickingTempMasivo } from "../../../services/ManifiestosServices"; // Importa deletePickingTempMasivo desde tu archivo API
import {
  createManifiestoAndPicking,
  ordenesPendientesRutaStgo,
  ordenesPendientesRutaRegiones,
} from "../../../services/ManifiestosServices";

//Eliminar de Picking de manera masiva
export const handleCancelar = async ({ ordenesDataTabla, id_user, idChofer, proceso, resetState, showError }) => {
  if (ordenesDataTabla.length === 0) {
    toast.error("Manifiesto está vacío.");
    return;
  }

  const result = await Swal.fire({
    title: "Eliminación de Datos?",
    text: "Seguro de eliminar datos del Picking?????",
    icon: "warning",
    showCancelButton: true,
    cancelButtonText: "CANCELAR",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "ELIMINAR!!!",
  });

  if (result.isConfirmed) {
    const datos = {
      COD_USUARIO: id_user,
      COD_CHOFER: idChofer,
      PROCESO: proceso,
    };

    const response = await deletePickingTempMasivo(datos);
    if (response === true) {
      toast.success("Datos Eliminados.");
      resetState();
    } else {
      showError("Error al Eliminar !!!");
    }
  }
};

//Eliminar de manera individual desde la tabla
export const handleDelete = async ({
  orden,
  id_user,
  proceso,
  idChofer,
  deletePickingTemp,
  buscarDatosPickingTempManifiestoRuta,
  setLoadingEliminar,
  setOrdenesDataTabla,
  setTotalOrdenes,
  setTotalBultos,
  selectedComunas,
  setOrdenesPendientes,
  setTotalPendientes,
  iataUser,
  agenteUser,
  codigoCliente,
}) => {
  setLoadingEliminar(true);
  const result = await Swal.fire({
    title: "¿Estás seguro?",
    text: "No podrás revertir esta acción",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sí, eliminar!",
  });

  if (result.isConfirmed) {
    try {
      const response = await deletePickingTemp({
        OD: orden,
        COD_USUARIO: id_user,
        PROCESO: proceso,
      });

      if (response.message === "PickingTemp Eliminado con éxito") {
        const datos = {
          COD_USUARIO: id_user,
          COD_CHOFER: idChofer,
          PROCESO: proceso,
          COD_CLIENTE: codigoCliente,
        };
        try {
          const resultados = await buscarDatosPickingTempManifiestoRuta(datos);

          let totalBultosTemp = 0;
          if (resultados && resultados.results) {
            resultados.results.forEach((orden) => {
              totalBultosTemp += orden.BULTOS;
            });
            setTotalOrdenes(resultados.results.length);
          }

          setTotalBultos(totalBultosTemp);

          if (resultados && Array.isArray(resultados.results)) {
            setOrdenesDataTabla(resultados.results);
          } else {
            setOrdenesDataTabla([]);
          }
          try {
            if (iataUser === "SCL") {
              const response = await ordenesPendientesRutaStgo(
                selectedComunas.map((comuna) => comuna.IATA),
                codigoCliente,
              );
              setOrdenesPendientes(response);
              setTotalPendientes(response.length);
            } else {
              let datos = {
                DESTINO: iataUser,
                AGENTE_ASIGNADO: agenteUser,
              };

              if (codigoCliente === 632) {
                datos.COD_CLIENTE = codigoCliente;
              }

              const response = await ordenesPendientesRutaRegiones(datos);
              setOrdenesPendientes(response);
              setTotalPendientes(response.length);
            }
          } catch (error) {
            console.error("Error al actualizar órdenes pendientes:", error);
          }
        } catch (error) {
          console.error("Error al buscar datos de picking temp:", error);
          setOrdenesDataTabla([]);
        }

        Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
      } else {
        Swal.fire("No se pudo eliminar", response.message, "error");
      }
    } catch (error) {
      console.error("Error al eliminar el picking temp:", error);
      Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
    } finally {
      setLoadingEliminar(false);
    }
  }
  setLoadingEliminar(false);
};

//Funcion para obtener la fecha
export const getFecha = () => {
  try {
    const fechaActual = new Date();
    const options = {
      timeZone: "America/Santiago",
      hour12: false,
    };

    const fecha = fechaActual.toLocaleDateString("es-CL", options);

    const [dia, mes, año] = fecha.split("-");
    const nuevaFecha = `${año}-${mes}-${dia}`;

    const hora = fechaActual.toLocaleTimeString("es-CL", {
      ...options,
      hour12: false,
    });

    const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

    return fechaHoraFormateada;
  } catch (error) {
    console.error("Error al obtener la fecha:", error);
    return null;
  }
};

//Funcion para la creacion de manifiesto y devuelva el numero de este
export const crearManifiesto = async ({
  idChofer,
  idPeoneta,
  selectedComunas,
  id_user,
  proceso,
  codigoCliente,
  nombreUser,
  totalBultos,
  iataUser,
  nota,
  selectedPatente,
}) => {
  // Validación inicial
  if (idChofer === idPeoneta) {
    toast.error("Chofer y peoneta no deben ser los mismos");
    return;
  }

  try {
    // Concatenar los nombres de las comunas seleccionadas
    const nombresConcatenados = selectedComunas.map((obj) => obj.NOMBRE).join(" - ");
    const fecha = getFecha(); // Obtén la fecha actual

    // Datos para crear manifiesto y picking
    const data = {
      FH_CREACION: fecha,
      FH_CIERRE: fecha,
      COD_USUARIO: id_user,
      PROCESO: proceso,
      ID_CLIENTE: codigoCliente,
      COD_CHOFER: idChofer,
      ESTADO: "CERRADO",
      COD_OPERADOR_ENTRADA: idChofer,
      COD_CHOFER_ENTRADA: idChofer,
      NOMBRE_CHOFER_ENTRADA: nombreUser,
      SELLOS: "",
      CANTIDAD_VALIJAS: 0,
      TOTAL_BULTOS: totalBultos,
      TIPO_RUTA: "TERRESTRE",
      TIPO_MANIFIESTO: proceso,
      ORIGEN: iataUser,
      DESTINO: iataUser,
      NOTA: nota,
      FH_MAIL: "",
      RUTA: nombresConcatenados,
      COD_PEONETA: idPeoneta,
      PATENTE: selectedPatente.label,
      COD_CLIENTE: codigoCliente,
    };

    // Llama a la nueva función combinada
    const numeroManifiesto = await createManifiestoAndPicking(data);

    // Mensaje de éxito
    toast.success(`Manifiesto creado con éxito. N°: ${numeroManifiesto}`);
    return numeroManifiesto;
  } catch (error) {
    // Manejo de errores
    console.error("Error al crear el manifiesto y picking:", error);
    toast.error("Error al crear el manifiesto. Por favor, intente nuevamente.");
  }
};

//Funcion ingresar orden a tabla
export const ordenIngresada = async ({
  event,
  inputRef,
  idChofer,
  idPeoneta,
  selectedComunas,
  selectedPatente,
  userInfo,
  buscarDatosOrden,
  ordenesDataTabla,
  idClienteA,
  iataUser,
  ingresarOrdenTabla,
  showError,
  agenteUser,
}) => {
  event.preventDefault();
  const ordenValue = inputRef.current.value.trim();

  try {
    // Validaciones iniciales
    if (!idChofer) {
      showError("Debe seleccionar un chofer!!!");
      return;
    }

    if (selectedComunas.length === 0) {
      showError("Debe Seleccionar una Comuna!!!");
      return;
    }

    if (
      (userInfo.tipo_usuario !== "AGENTE" &&
        userInfo.tipo_usuario !== "AGENTE_HUB" &&
        userInfo.tipo_usuario !== "AGENTE_ADMIN") ||
      userInfo.AGENTE_ASIGNADO === "BODEGA SANTIAGO"
    ) {
      if (!selectedPatente) {
        showError("Debe seleccionar una patente antes de continuar!!!");
        return;
      }
    }

    const dataOrden = await buscarDatosOrden(ordenValue);

    if (!dataOrden || dataOrden.length === 0) {
      showError("Orden no existe!!!");
      return;
    }

    if (idChofer === idPeoneta) {
      toast.error("Chofer y peoneta no deben ser los mismos");
      return;
    }

    if (!ordenValue) {
      Swal.fire({
        icon: "warning",
        title: "Orden vacía",
        text: "Debe ingresar un valor de orden.",
      });
      return;
    }

    const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);

    if (ordenExistente) {
      showError("Orden ya ingresada al manifiesto!!!");
      return;
    }

    //Se valida que la comuna de la orden este dentro de las comunas seleccionadas
    if (userInfo.iata_agentes === "SCL") {
      const iataOrden = dataOrden[0].COMUNA;
      const isIATAValida = selectedComunas.some((comuna) => comuna.IATA === iataOrden);

      if (!isIATAValida) {
        showError("El destino de la orden no está dentro de las comunas seleccionadas.");
        return;
      }
    }

    if (userInfo.iata_agentes !== "SCL") {
      const agente = dataOrden[0].AGENTE_ASIGNADO;
      if (agente !== agenteUser) {
        showError("Orden no pertecene a su agencia.");
        return;
      }
    }

    if (userInfo.iata_agentes !== "SCL") {
      if (dataOrden[0].TIENE_MANIFIESTO_ENTRADAAGENTE === 0) {
        showError("Orden no posee un manifesto de Entrada Agencia, favor de crearlo.");
        return;
      }
    } else {
      if (dataOrden[0].TIENE_MANIFIESTO_ENTRADAAGENTE === 0 && dataOrden[0].TIENE_MANIFIESTO_INGRESOSANTIAGO === 0) {
        showError("Orden no posee un manifesto de Entrada Agencia, favor de crearlo.");
        return;
      }
    }

    if (idClienteA) {
      if (dataOrden[0].COD_CLIENTE === idClienteA) {
        ingresarOrdenTabla(dataOrden);
        inputRef.current.value = "";
        return;
      } else {
        toast.error("Orden no es del cliente seleccionado");
        return;
      }
    }

    if (
      (dataOrden[0].TIPO_ORDEN === "ENTREGA" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_DESTINO) ||
      (dataOrden[0].TIPO_ORDEN === "RETIRO" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_ORIGEN) ||
      (dataOrden[0].TIPO_ORDEN === "RETIRO" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_DESTINO)
    ) {
      ingresarOrdenTabla(dataOrden);
      inputRef.current.value = "";
    } else {
      Swal.fire({
        title: "Comuna diferente",
        text: "La comuna de la orden no coincide con la ciudad del agente. ¿Deseas ingresarla igual?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          ingresarOrdenTabla(dataOrden);
          inputRef.current.value = "";
        } else {
          inputRef.current.value = "";
        }
      });
    }
  } catch (error) {
    console.error("Error al buscar los datos de la orden:", error);
  }
};

export const CodigoBarraIngresada = async ({
  event,
  inputRef,
  idChofer,
  idPeoneta,
  selectedComunas,
  selectedPatente,
  userInfo,
  getOrdenIdReferencia,
  ordenesDataTabla,
  idClienteA,
  iataUser,
  ingresarOrdenTabla,
  showError,
  agenteUser,
  codigoCliente,
}) => {
  event.preventDefault();
  const ordenValue = inputRef.current.value.trim();

  try {
    // Validaciones iniciales
    if (!idChofer) {
      showError("Debe seleccionar un chofer!!!");
      return;
    }

    if (selectedComunas.length === 0) {
      showError("Debe Seleccionar una Comuna!!!");
      return;
    }

    if (
      userInfo.tipo_usuario !== "AGENTE" &&
      userInfo.tipo_usuario !== "AGENTE_HUB" &&
      userInfo.tipo_usuario !== "AGENTE_ADMIN" &&
      !selectedPatente
    ) {
      showError("Debe seleccionar una patente antes de continuar!!!");
      return;
    }

    const datos = {
      COD_CLIENTE: codigoCliente,
      COD_BARRA: ordenValue,
    };

    const dataOrden = await getOrdenIdReferencia(datos);

    if (!dataOrden || dataOrden.length === 0) {
      showError("Orden no existe!!!");
      return;
    }

    if (idChofer === idPeoneta) {
      toast.error("Chofer y peoneta no deben ser los mismos");
      return;
    }

    if (!ordenValue) {
      Swal.fire({
        icon: "warning",
        title: "Orden vacía",
        text: "Debe ingresar un valor de orden.",
      });
      return;
    }

    const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);

    if (ordenExistente) {
      showError("Orden ya ingresada al manifiesto!!!");
      return;
    }

    //Se valida que la comuna de la orden este dentro de las comunas seleccionadas
    if (userInfo.iata_agentes === "SCL") {
      const iataOrden = dataOrden[0].COMUNA;
      const isIATAValida = selectedComunas.some((comuna) => comuna.IATA === iataOrden);

      if (!isIATAValida) {
        showError("El destino de la orden no está dentro de las comunas seleccionadas.");
        return;
      }
    }

    if (userInfo.iata_agentes !== "SCL") {
      if (dataOrden[0].TIENE_MANIFIESTO_ENTRADAAGENTE === 0) {
        showError("Orden no posee un manifesto de Entrada Agencia, favor de crearlo.");
        return;
      }
    }

    if (userInfo.iata_agentes !== "SCL") {
      if (dataOrden[0].AGENTE_ASIGNADO !== agenteUser) {
        showError("Orden no pertecene a su agencia.");
        return;
      }
    }
    if (idClienteA) {
      if (dataOrden[0].COD_CLIENTE === idClienteA) {
        ingresarOrdenTabla(dataOrden);
        inputRef.current.value = "";
        return;
      } else {
        toast.error("Orden no es del cliente seleccionado");
        return;
      }
    }

    if (
      (dataOrden[0].TIPO_ORDEN === "ENTREGA" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_DESTINO) ||
      (dataOrden[0].TIPO_ORDEN === "RETIRO" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_ORIGEN) ||
      (dataOrden[0].TIPO_ORDEN === "RETIRO" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_DESTINO)
    ) {
      ingresarOrdenTabla(dataOrden);
      inputRef.current.value = "";
    } else {
      Swal.fire({
        title: "Comuna diferente",
        text: "La comuna de la orden no coincide con la ciudad del agente. ¿Deseas ingresarla igual?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          ingresarOrdenTabla(dataOrden);
          inputRef.current.value = "";
        } else {
          inputRef.current.value = "";
        }
      });
    }
  } catch (error) {
    console.error("Error al buscar los datos de la orden:", error);
  }
};
