// Configuración compartida para Google Maps
export const libraries = ["places", "marker"];

export const mapOptions = {
  mapId: "8f112ffa7a55b6a0",
  disableDefaultUI: false,
  clickableIcons: false,
};

export const loaderOptions = {
  googleMapsApiKey: "AIzaSyBITfQPAHSXfc_Lw09kwU1TlwJVbJJwMtY",
  libraries,
  mapIds: ["8f112ffa7a55b6a0"]
}; 