import React, { useState, useEffect, useRef, useMemo} from 'react';
import { toast } from "react-toastify";
import {
  Box,Card,CardContent,CardHeader,Typography,Button,Grid,TextField,Autocomplete,
  Checkbox, FormControlLabel, FormGroup,Tooltip, Paper,Modal, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle,
  ToggleButtonGroup, ToggleButton, IconButton
} from '@mui/material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {getClients, getReportesAutomaticosProgramados, getDestinatarios, getDestinatariosAsignables, getRegistroDestinatariosCorreoEnviado, deleteActualReporteAutomatico, insertReporteAtuomaticoProgramado, getReporteCols, getAgentesReporteCols, insertReporteAutomaticoAgente, getDestinatariosAgencia, deleteReporteAutomaticoAgente} from "../../services/ReporteAutomaticoService"
import DaySchedule from '../../components/ReportesComponentes/componentesReporteAutomatico/Horario';
import { getAgentes } from '../../services/ReporteAutomaticoService';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@mui/icons-material/Group';

// Componente Expandible
const ExpandableCheckboxList = ({ cabeceras, reportes, setReportes, setCabeceras, selectedClient }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [draggedIndex, setDraggedIndex] = useState(null);

  // Cargar las cabeceras
  useEffect(() => {
    if (selectedClient && isFirstLoad && reportes.length > 0 && cabeceras.length === 0) {
      const report = reportes[0].CABECERAS_REPORTE;
      if (report) {
        setCabeceras(report);
      } else {
        const reportWithoutHeaders = reportes[0];
        getReporteCols(reportWithoutHeaders.COD_CLIENTE, setCabeceras);
      }
      setIsFirstLoad(false);
    } else if (selectedClient && reportes.some((r) => !r.CABECERAS_REPORTE)) {
      setReportes((prev) =>
        prev.map((reporte) =>
          !reporte.CABECERAS_REPORTE ? { ...reporte, CABECERAS_REPORTE: cabeceras } : reporte
        )
      );
    } else if (selectedClient && reportes.length === 0 && isFirstLoad && cabeceras.length === 0) {
      getReporteCols(selectedClient.ID, setCabeceras);
    }
  }, [reportes, cabeceras, setCabeceras, isFirstLoad, setReportes, selectedClient]);

  // Actualizar checkedItems cuando cambian las cabeceras
  useEffect(() => {
    if (cabeceras.length > 0) {
      const newCheckedItems = cabeceras.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});
      setCheckedItems(newCheckedItems);
    }
  }, [cabeceras]);

  // Sincronizar cabeceras cuando cambia checkedItems
  useEffect(() => {
    const nuevaCabeceras = cabeceras.map((item) => {
      const key = Object.keys(item)[0];
      return { [key]: checkedItems[key] ?? item[key] };
    });

    if (JSON.stringify(nuevaCabeceras) !== JSON.stringify(cabeceras)) {
      setCabeceras(nuevaCabeceras);
    }
  }, [checkedItems, cabeceras, setCabeceras]);

  // Manejar cambios en checkboxes
  const handleToggle = (key) => {
    setCheckedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Drag-and-drop handlers
  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Necesario para permitir soltar elementos
  };

  const handleDrop = (index) => {
    if (draggedIndex === null || draggedIndex === index) return;

    const newCabeceras = [...cabeceras];
    const [movedItem] = newCabeceras.splice(draggedIndex, 1);
    newCabeceras.splice(index, 0, movedItem);

    setCabeceras(newCabeceras);
    setDraggedIndex(null);
  };

  return (
    <Accordion sx={{ width: "100%", mx: "auto", my: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fill: "white", color: "white" }} />} sx={{ backgroundColor: colors.blue, color: "white", borderRadius: 1 }}>
        <Typography variant="h6">Cabeceras</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: 300, overflowY: "auto" }}>
        {cabeceras.map((item, idx) => {
          const key = Object.keys(item)[0];
          return (
            <div
              key={idx}
              draggable
              onDragStart={() => handleDragStart(idx)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(idx)}
              style={{ cursor: "grab", padding: "5px", borderBottom: "1px solid #ddd" }}
            >
              <FormControlLabel
                control={<Checkbox checked={checkedItems[key] || false} onChange={() => handleToggle(key)} />}
                label={key}
                sx={{ display: "block", textTransform: "capitalize" }}
              />
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};


const colors = {red:"#DA251C", blue:"#041562",green:"#046A38",orange:"#FF7700" }

const DeleteConfirmDialog = ({ open, onClose, onConfirm, title, content }) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 400,
          p: 1
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>{title || "Confirmar eliminación"}</DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Typography>
          {content || "¿Estás seguro de que quieres eliminar el registro? Esta acción no se puede deshacer."}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button 
          onClick={handleClose} 
          variant="outlined"
          sx={{ minWidth: 100 }}
        >
          Cancelar
        </Button>
        <Button 
          onClick={handleConfirm} 
          variant="contained"
          sx={{ 
            minWidth: 100,
            backgroundColor: colors.red,
            '&:hover': {
              backgroundColor: `${colors.red}dd`,
            },
          }}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Componente para selección de cliente
const ClientSelector = ({ clients, selectedClient, setSelectedClient }) => {
  return (
    <Autocomplete
      options={clients}
      getOptionLabel={(option) => option.NOMBRE}
      value={selectedClient}
      onChange={(_, value) => setSelectedClient(value)}
      renderOption={(props, option) => (
        <li {...props} key={option.ID}>
          {option.NOMBRE}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Selecciona un cliente"
          variant="outlined"
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: 'common.white',
              borderRadius: 2,
              height: 56
            }
          }}
        />
      )}
    />
  );
};

// Componente para configuración de horario
const ScheduleConfiguration = ({ schedule, handleHourSelect, handleDelete, isCompleted, btnDisabled, handleSave }) => {
  return (
    <Card
      sx={{
        width: '100%',
        boxShadow: (theme) => theme.shadows[3],
        minHeight: 200
      }}
    >
      <CardHeader
        title="Configuración del Horario"
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          background: `${colors.blue}`,
          color: 'common.white',
          padding: { xs: 2, sm: 3 },
          height: { xs: 64, sm: 72 },
          '& .MuiCardHeader-title': {
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            fontWeight: 600
          }
        }}
      />
      <CardContent
        sx={{
          padding: { xs: 2, sm: 3 },
          minHeight: 300,
          '& .MuiGrid-container': {
            margin: 0
          }
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, sm: 3 }}
          sx={{
            '& .MuiGrid-item': {
              width: '100%'
            }
          }}
        >
          {Object.entries(schedule).map(([day, selectedHours]) => (
            <DaySchedule
              key={day}
              day={day}
              selectedHours={selectedHours}
              onHourSelect={handleHourSelect}
              onDelete={handleDelete}
            />
          ))}
        </Grid>
        {isCompleted.readyToSave && (
          <Button
            variant="contained"
            fullWidth
            disabled={btnDisabled}
            sx={{
              mt: 4,
              py: 1.5,
              fontSize: '1.1rem',
              fontWeight: 600,
              borderRadius: 2,
              background: `${colors.blue}`,
              height: 48
            }}
            onClick={handleSave}
          >
            Guardar horario
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

// Componente para destinatarios
const DestinatariosSection = ({ 
  isCompleted, 
  destinatariosYaAsociados, 
  selectedDestinatarios, 
  handleChange, 
  handleOpen,
  open,
  handleClose,
  destinatariosAsignablesPorCliente,
  inputDestinatarioError,
  setInputDestinatarioError,
  setDestinatariosYaAsociados,
  btnDisabled,
  setOpen
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: { xs: 2, sm: 3 },
        display: (isCompleted.horario) ? "flex" : "none",
        flexDirection: "column",
        minHeight: { xs: 200, sm: 200 },
        width: '100%',
        overflowY: 'auto',
        borderRadius: 2,
        backgroundColor: 'common.white',
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: (theme) => theme.palette.grey[100],
          borderRadius: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: (theme) => theme.palette.grey[400],
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.grey[600]
          }
        }
      }}
    >
      {(isCompleted.horario) && (
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            fontWeight: 600,
            color: (theme) => theme.palette.text.primary,
            height: 40
          }}
        >
          Seleccione destinatarios
        </Typography>
      )}
      <FormGroup sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" },
            flexWrap: "wrap",
          }}
        >
          {destinatariosYaAsociados.map(({ EMAIL }, i) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              key={i}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Tooltip title="Click para seleccionar un destinatario" arrow placement="top">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!selectedDestinatarios[EMAIL]}
                      onChange={handleChange}
                      name={EMAIL}
                      sx={{
                        "&.Mui-checked": {
                          color: (theme) => theme.palette.primary.main,
                        },
                        height: 20,
                        wordBreak: "break-word"
                      }}
                    />
                  }
                  label={EMAIL}
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    wordBreak: "break-word",
                    flex: 1,
                    margin: 0,
                    padding: 1,
                    borderRadius: 1,
                    minHeight: 20,
                    "& .MuiFormControlLabel-label": {
                      width: "100%",
                      fontSize: { xs: "0.875rem", sm: "1rem" },
                    },
                  }}
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>

        <Button
          onClick={handleOpen}
          sx={{
            height: 48,
            mt:"auto"
          }}
        >
          Agregar destinatarios
        </Button>

        <AddDestinatarioModal 
          open={open}
          handleClose={handleClose}
          destinatariosAsignablesPorCliente={destinatariosAsignablesPorCliente}
          inputDestinatarioError={inputDestinatarioError}
          setInputDestinatarioError={setInputDestinatarioError}
          setDestinatariosYaAsociados={setDestinatariosYaAsociados}
          btnDisabled={btnDisabled}
          setOpen={setOpen}
        />
      </FormGroup>
    </Paper>
  );
};

// Componente para el modal de agregar destinatarios
const AddDestinatarioModal = ({ 
  open, 
  handleClose, 
  destinatariosAsignablesPorCliente, 
  inputDestinatarioError, 
  setInputDestinatarioError, 
  setDestinatariosYaAsociados, 
  btnDisabled,
  setOpen
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 450 },
          maxWidth: 500,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: (theme) => theme.shadows[24],
          p: { xs: 2, sm: 3 },
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxHeight: '90vh',
          overflowY: 'auto'
        }}
      >
        {/* Header */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 2
        }}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ 
              fontWeight: 600,
              color: 'text.primary'
            }}
          >
            Agregar Destinatario
          </Typography>
          <IconButton 
            onClick={handleClose}
            size="small"
            sx={{ 
              color: 'text.secondary',
              '&:hover': { 
                color: 'text.primary',
                bgcolor: 'action.hover'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Content */}
        <Box sx={{ flex: 1 }}>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ mb: 2 }}
          >
            Busca un destinatario existente o agrega uno nuevo ingresando su correo electrónico.
          </Typography>

          <Autocomplete
            options={destinatariosAsignablesPorCliente}
            id="add-destinatario"
            freeSolo
            getOptionLabel={(option) => typeof option === "string" ? option : option.EMAIL || ""}
            onChange={(_, value) => {
              if (typeof value === "string") {
                if(!value.includes("@")){
                  setInputDestinatarioError("Error porfavor introduzca un correo valido.")
                  return
                } else {
                  if(!value.substring(value.indexOf("@") + 1, value.length).includes(".")){
                    setInputDestinatarioError("Error porfavor introduzca un fin de correo valido.")
                    return
                  }
                }
                setInputDestinatarioError(null)
                setDestinatariosYaAsociados((prev) => {
                  if (!prev.some(dest => dest.EMAIL === value)) {
                    return [...prev, { EMAIL: value}];
                  }
                  return prev;
                });

                setTimeout(() => {
                  setOpen(false)            
                }, 100)
              } else if (value && value.EMAIL) {
                setDestinatariosYaAsociados((prev) => {
                  if (!prev.some(item => item.EMAIL === value.EMAIL)) {
                    return [...prev, value];
                  }
                  return prev;
                });

                setTimeout(() => {
                  setOpen(false)            
                }, 100)
              }
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                key={option.EMAIL}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  py: 1,
                  px: 2,
                  '&:hover': {
                    bgcolor: 'action.hover'
                  }
                }}
              >
                <EmailIcon sx={{ color: 'action.active', fontSize: 20 }} />
                <Typography 
                  sx={{
                    textWrap: "wrap",
                    wordBreak: "break-word",
                    flex: 1
                  }}
                >
                  {option.EMAIL}
                </Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Correo electrónico"
                placeholder="Buscar o ingresar nuevo correo"
                variant="outlined"
                error={!!inputDestinatarioError}
                helperText={inputDestinatarioError}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'background.paper',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      bgcolor: 'action.hover'
                    },
                    '&.Mui-focused': {
                      bgcolor: 'background.paper',
                      '& fieldset': {
                        borderWidth: '2px',
                        borderColor: 'primary.main'
                      }
                    }
                  }
                }}
              />
            )}
            ListboxProps={{
              sx: {
                maxHeight: '200px',
                '&::-webkit-scrollbar': {
                  width: '8px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'action.hover',
                  borderRadius: '4px'
                }
              }
            }}
          />
        </Box>

        {/* Actions */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          mt: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
          pt: 2
        }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              flex: 1,
              py: 1,
              textTransform: 'none'
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={btnDisabled}
            onClick={async () => {
              const inputElement = document.getElementById("add-destinatario");
              const value = inputElement.value
              if(!value.includes("@")){
                setInputDestinatarioError("Error porfavor introduzca un correo valido.")
                return
              } else {
                if(!value.substring(value.indexOf("@") + 1, value.length).includes(".")){
                  setInputDestinatarioError("Error porfavor introduzca un fin de correo valido.")
                  return
                }
              }

              setDestinatariosYaAsociados((prev) => {
                if (!prev.some(item => item.EMAIL === inputElement.value)) {
                  return [...prev,{EMAIL: inputElement.value}];
                }
                return prev;
              });

              setTimeout(() => {
                setOpen(false)            
              }, 100)
            }}
            sx={{
              flex: 1,
              py: 1,
              bgcolor: colors.blue,
              textTransform: 'none',
              '&:hover': {
                bgcolor: `${colors.blue}dd`
              }
            }}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

// Componente para la sección de Agente (versión simplificada)
const AgenteView = () => {
  const [agentes, setAgentes] = useState([]);
  const [selectedAgente, setSelectedAgente] = useState(null);
  const [isLoadingAgentes, setIsLoadingAgentes] = useState(false);
  const [schedule, setSchedule] = useState({
    Lunes: [],
    Martes: [],
    Miercoles: [],
    Jueves: [],
    Viernes: [],
    Sabado: [],
    Domingo: []
  });
  const [isCompleted, setIsCompleted] = useState({
    horario: false,
    destinatarios: false,
    readyToSave: false
  });
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [cabeceras, setCabeceras] = useState([]);

  // Nuevos estados para destinatarios
  const [destinatariosAgente, setDestinatariosAgente] = useState([]);
  const [selectedDestinatarios, setSelectedDestinatarios] = useState({});
  const [open, setOpen] = useState(false);
  const [inputDestinatarioError, setInputDestinatarioError] = useState(null);

  // Agregar un estado para controlar el reseteo del componente
  const [resetKey, setResetKey] = useState(0);

  // Añadir nuevo estado para el diálogo de confirmación
  const [openConfirm, setOpenConfirm] = useState(false);
  const [hasExistingConfig, setHasExistingConfig] = useState(false);

  // Cargar agentes y cabeceras al iniciar
  useEffect(() => {
    const loadAgentes = async () => {
      setIsLoadingAgentes(true);
      try {
        const response = await getAgentes();
        if (response.success) {
          setAgentes(response.data);
        }
      } catch (error) {
        console.error("Error cargando agentes:", error);
        toast.error("Error al cargar los agentes");
      } finally {
        setIsLoadingAgentes(false);
      }
    };
    
    loadAgentes();
  }, []);

  const filterOptions = (options, { inputValue }) => {
    const searchTerm = inputValue.toLowerCase();
    return options.filter(option => 
      option.EMAIL?.toLowerCase().includes(searchTerm) || 
      option.iata_agentes?.toLowerCase().includes(searchTerm)
    );
  };

  // Efecto para actualizar el estado de completado
  useEffect(() => {
    const hasSchedule = Object.values(schedule).some(day => day.length > 0);
    const hasAgent = selectedAgente !== null;

    setIsCompleted(prev => ({
      ...prev,
      horario: hasSchedule,
      destinatarios: hasAgent,
      readyToSave: hasSchedule && hasAgent
    }));
  }, [schedule, selectedAgente]); // Este efecto depende de schedule y selectedAgente

  // Modificar el efecto que carga las cabeceras para detectar configuración existente
  useEffect(() => {
    const loadCabeceras = async () => {
      if (!selectedAgente) {
        setCabeceras([]);
        setSchedule({
          Lunes: [],
          Martes: [],
          Miercoles: [],
          Jueves: [],
          Viernes: [],
          Sabado: [],
          Domingo: []
        });
        setDestinatariosAgente([]);
        setSelectedDestinatarios({});
        setHasExistingConfig(false);
        return;
      }

      try {
        const cabecerasResponse = await getAgentesReporteCols(selectedAgente.EMAIL);
        
        // Verificar si hay configuración existente
        if (cabecerasResponse?.data?.length > 0 && 
            (cabecerasResponse.data[0].CABECERAS_REPORTE || 
             cabecerasResponse.data.some(r => r.DIA_SEMANA && r.HORA_ENVIO))) {
          setHasExistingConfig(true);
        }

        // Cargar destinatarios
        const destinatariosResponse = await getDestinatariosAgencia(selectedAgente.EMAIL);
        
        // Procesar destinatarios
        if (destinatariosResponse?.length > 0) {
          setDestinatariosAgente(destinatariosResponse);
          
          // Crear objeto de destinatarios seleccionados
          const defaultSelected = destinatariosResponse.reduce((acc, { EMAIL }) => {
            acc[EMAIL] = true; // Marcar como seleccionado por defecto
            return acc;
          }, {});
          setSelectedDestinatarios(defaultSelected);
        }

        // Procesar cabeceras (resto del código existente)
        if (cabecerasResponse?.data) {
          const newSchedule = {
            Lunes: [],
            Martes: [],
            Miercoles: [],
            Jueves: [],
            Viernes: [],
            Sabado: [],
            Domingo: []
          };

          // Agrupar los horarios por día, solo si existe DIA_SEMANA y HORA_ENVIO
          cabecerasResponse.data.forEach(registro => {
            if (registro.DIA_SEMANA && registro.HORA_ENVIO) {
            const dia = registro.DIA_SEMANA.charAt(0) + registro.DIA_SEMANA.slice(1).toLowerCase();
            if (newSchedule[dia]) {
              newSchedule[dia].push(registro.HORA_ENVIO);
              }
            }
          });

          // Si hay registros y tienen CABECERAS_REPORTE, usarlos
          if (cabecerasResponse.data.length > 0 && cabecerasResponse.data[0].CABECERAS_REPORTE) {
            setCabeceras(cabecerasResponse.data[0].CABECERAS_REPORTE);
            setSchedule(newSchedule);
          } else if (cabecerasResponse.data.length > 0) {
            // Si hay datos pero no hay CABECERAS_REPORTE, mantener solo las cabeceras
            setCabeceras(cabecerasResponse.data);
            setSchedule({
              Lunes: [],
              Martes: [],
              Miercoles: [],
              Jueves: [],
              Viernes: [],
              Sabado: [],
              Domingo: []
            });
          } else {
            setCabeceras([]);
            setSchedule({
              Lunes: [],
              Martes: [],
              Miercoles: [],
              Jueves: [],
              Viernes: [],
              Sabado: [],
              Domingo: []
            });
          }
        }
      } catch (error) {
        console.error("Error cargando datos:", error);
        toast.error("Error al cargar los datos del agente");
        setHasExistingConfig(false);
        setCabeceras([]);
        setSchedule({
          Lunes: [],
          Martes: [],
          Miercoles: [],
          Jueves: [],
          Viernes: [],
          Sabado: [],
          Domingo: []
        });
        setDestinatariosAgente([]);
        setSelectedDestinatarios({});
      }
    };

    loadCabeceras();
  }, [selectedAgente]); // Solo depende del agente seleccionado

  // Función para manejar la selección de horas
  const handleHourSelect = (day, hour) => {
    setSchedule(prev => ({
      ...prev,
      [day]: [...prev[day], hour].sort()
    }));
  };

  // Función para manejar la eliminación de horas
  const handleDelete = (day, hour) => {
    setSchedule(prev => ({
      ...prev,
      [day]: prev[day].filter(h => h !== hour)
    }));
  };

  // Funciones para el modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handler para cambios en checkboxes de destinatarios
  const handleDestinatarioChange = (event) => {
    const { name, checked } = event.target;
    setSelectedDestinatarios(prev => ({
      ...prev,
      [name]: checked
    }));

    // Actualizar el estado de completado
    const hasSelectedDestinatarios = Object.values({
      ...selectedDestinatarios,
      [name]: checked
    }).some(value => value);

    setIsCompleted(prev => ({
      ...prev,
      destinatarios: hasSelectedDestinatarios,
      readyToSave: prev.horario && hasSelectedDestinatarios
    }));
  };

  // Modificar handleSave para incluir destinatarios
  const handleSave = async () => {
    if (!isCompleted.readyToSave) return;

    setBtnDisabled(true);
    try {
      const scheduleData = {
        agente: selectedAgente,
        horario: schedule,
        cabeceras: cabeceras,
        destinatarios: Object.keys(selectedDestinatarios).filter(key => selectedDestinatarios[key])
      };

      await insertReporteAutomaticoAgente(scheduleData);
      
      setSelectedAgente(null);
      resetStates();
      toast.success(`Configuración guardada exitosamente para ${selectedAgente.EMAIL}`);
    } catch (error) {
      console.error("Error al guardar:", error);
      toast.error("No se pudo guardar la configuración. Por favor intente nuevamente.");
    } finally {
      setBtnDisabled(false);
    }
  };

  // Modificar resetStates para incluir destinatarios
  const resetStates = () => {
    setSchedule({
      Lunes: [],
      Martes: [],
      Miercoles: [],
      Jueves: [],
      Viernes: [],
      Sabado: [],
      Domingo: []
    });
    setCabeceras([]);
    setIsCompleted({
      horario: false,
      destinatarios: false,
      readyToSave: false
    });
    setBtnDisabled(false);
    setResetKey(prev => prev + 1); // Incrementar resetKey para forzar el reset del componente
    setSelectedDestinatarios({});
    setDestinatariosAgente([]);
    setInputDestinatarioError(null);
  };

  // Modificar el ExpandableCheckboxListWithReset para usar la resetKey
  const ExpandableCheckboxListWithReset = useMemo(() => {
    return (
      <ExpandableCheckboxList 
        key={`${selectedAgente?.EMAIL || 'no-agent'}-${resetKey}`} // Agregar resetKey a la key
        cabeceras={cabeceras} 
        reportes={[]}
        setReportes={() => {}}
        setCabeceras={setCabeceras} 
        selectedClient={null}
      />
    );
  }, [selectedAgente, cabeceras, setCabeceras, resetKey]); // Agregar resetKey a las dependencias

  // Añadir función para manejar la eliminación
  const handleDeleteButton = async () => {
    if (!selectedAgente) return;

    setBtnDisabled(true);
    try {
      await deleteReporteAutomaticoAgente(selectedAgente.EMAIL);
      toast.success(`Configuración eliminada exitosamente para ${selectedAgente.EMAIL}`);
      setSelectedAgente(null);
      resetStates();
      setHasExistingConfig(false);
    } catch (error) {
      console.error("Error al eliminar:", error);
      toast.error("No se pudo eliminar la configuración. Por favor intente nuevamente.");
    } finally {
      setBtnDisabled(false);
      setOpenConfirm(false);
    }
  };

  return (
    <Box sx={{ 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 2, // Reducir el gap general
      maxWidth: 1000, // Limitar el ancho máximo
      margin: '0 auto' // Centrar el contenido
    }}>
      <Box sx={{ width: '100%' }}>
        <Autocomplete
          options={agentes}
          fullWidth={true}
          value={selectedAgente}
          onChange={async (_, newValue) => {
            setSelectedAgente(newValue);
            resetStates();
            
            if (newValue) {
              setIsLoadingAgentes(true);
              setIsLoadingAgentes(false);
            }
          }}
          getOptionLabel={(option) => option.EMAIL || ""}
          loading={isLoadingAgentes}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Buscar por Email o IATA"
              placeholder="Ingrese email o código IATA"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingAgentes ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                sx: {
                  backgroundColor: 'common.white',
                  borderRadius: 2,
                  height: 48,
                  '& fieldset': {
                    borderColor: 'divider'
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main'
                  }
                }
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                '&.MuiAutocomplete-option': {
                  padding: '12px 16px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 0.5
                }
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {option.EMAIL}
              </Typography>
              {option.iata_agentes && (
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'text.secondary',
                    backgroundColor: 'action.hover',
                    px: 1,
                    py: 0.25,
                    borderRadius: 1
                  }}
                >
                  IATA: {option.iata_agentes}
                </Typography>
              )}
            </Box>
          )}
          noOptionsText="No se encontraron agentes"
          loadingText="Cargando agentes..."
          sx={{
            width: '100%',
            '& .MuiAutocomplete-inputRoot': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'divider'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main'
              },
              height: 48
            }
          }}
        />
      </Box>

      {selectedAgente && (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 2,
          '& .MuiCard-root': { // Hacer las cards más compactas
            '& .MuiCardHeader-root': {
              py: 1 // Reducir el padding vertical del header
            },
            '& .MuiCardContent-root': {
              py: 1 // Reducir el padding vertical del contenido
            }
          }
        }}>
          <Typography 
            variant="h6" 
            color="primary" 
            sx={{ 
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              fontSize: '1.1rem' // Reducir tamaño de fuente
            }}
          >
            <span>Configurar horario para {selectedAgente.EMAIL}</span>
            {selectedAgente.iata_agentes && (
              <Typography 
                component="span" 
                variant="caption"
                sx={{ 
                  color: 'text.secondary',
                  backgroundColor: 'action.hover',
                  px: 1,
                  py: 0.25,
                  borderRadius: 1,
                  fontSize: '0.8rem'
                }}
              >
                IATA: {selectedAgente.iata_agentes}
              </Typography>
            )}
          </Typography>

          <Box sx={{ 
            '& .MuiAccordion-root': { // Hacer el acordeón más compacto
              '& .MuiAccordionSummary-root': {
                minHeight: 48,
                '& .MuiAccordionSummary-content': {
                  margin: '8px 0'
                }
              },
              '& .MuiAccordionDetails-root': {
                maxHeight: 250, // Reducir altura máxima
                overflowY: 'auto'
              }
            }
          }}>
            {ExpandableCheckboxListWithReset}
          </Box>

          <ScheduleConfiguration
            schedule={schedule}
            handleHourSelect={handleHourSelect}
            handleDelete={handleDelete}
            isCompleted={isCompleted}
            btnDisabled={btnDisabled}
            handleSave={handleSave}
          />

          <Paper
            elevation={3}
            sx={{
              padding: { xs: 2, sm: 3 },
              display: "flex",
              flexDirection: "column",
              minHeight: { xs: 200, sm: 200 },
              width: '100%',
              overflowY: 'auto',
              borderRadius: 2,
              backgroundColor: 'common.white',
              mt: 2
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                fontWeight: 600,
                color: (theme) => theme.palette.text.primary
              }}
            >
              Destinatarios del Agente
            </Typography>

            <FormGroup sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" },
                  flexWrap: "wrap",
                }}
              >
                {destinatariosAgente.map(({ EMAIL }, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    key={i}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Tooltip title="Click para seleccionar un destinatario" arrow placement="top">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!selectedDestinatarios[EMAIL]}
                            onChange={handleDestinatarioChange}
                            name={EMAIL}
                            sx={{
                              "&.Mui-checked": {
                                color: (theme) => theme.palette.primary.main,
                              }
                            }}
                          />
                        }
                        label={EMAIL}
                        sx={{
                          textAlign: "center",
                          width: "100%",
                          wordBreak: "break-word"
                        }}
                      />
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>

              <Button
                onClick={handleOpen}
                sx={{
                  height: 48,
                  mt: 2
                }}
              >
                Agregar destinatarios
              </Button>

              <AddDestinatarioModal 
                open={open}
                handleClose={handleClose}
                destinatariosAsignablesPorCliente={[]} // Pasar lista vacía o implementar lógica específica para agentes
                inputDestinatarioError={inputDestinatarioError}
                setInputDestinatarioError={setInputDestinatarioError}
                setDestinatariosYaAsociados={setDestinatariosAgente}
                btnDisabled={btnDisabled}
                setOpen={setOpen}
              />
            </FormGroup>
          </Paper>

          {hasExistingConfig && (
            <>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setOpenConfirm(true)}
                sx={{
                  height: 36,
                  mt: 2,
                  backgroundColor: colors.red,
                  '&:hover': {
                    backgroundColor: `${colors.red}dd`,
                  },
                }}
              >
                Eliminar configuración existente
              </Button>

              <DeleteConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                onConfirm={handleDeleteButton}
                title={`¿Eliminar configuración de ${selectedAgente.EMAIL}?`}
                content="Esta acción eliminará todos los horarios y destinatarios asociados. Esta acción no se puede deshacer."
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

const ReporteAutomaticoCliente = () => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [modo, setModo] = useState('cliente'); // 'cliente' o 'agente'
  const [schedule, setSchedule] = useState({
    Lunes: [],
    Martes: [],
    Miercoles: [],
    Jueves: [],
    Viernes: [],
    Sabado: [],
    Domingo: [],
  });

  const [clients, setClients ] = useState([])
  const [selectedDestinatarios, setSelectedDestinatarios] = useState([]);
  const [destinatariosYaAsociados, setDestinatariosYaAsociados] = useState([])
  const [destinatariosAsignablesPorCliente, setDestinatariosAsignablesPorCliente] = useState([])  
  const [reportesYaAsociados,setReportesYaAsociados] = useState([])
  const [selectedClient, setSelectedClient] = useState(null);
  const [destinatariosStatus, setDestinatariosStatus] = useState([])
  const [cabeceras, setCabeceras] = useState([])
  // Manejo de errores
  const [reportesError,setReportesError] = useState(null)
  const [destinatariosError, setDestinatariosError] = useState(null)
  const [clientReportError, setClientReportError] =  useState(null)
  const [deleteReportError, setDeleteReportError] =  useState(null)
  const [destinatariosStatusError, setDestinatariosStatusError] = useState(null)
  const [inputDestinatarioError, setInputDestinatarioError] = useState(null)
  const [isOk, setIsOk] = useState(false)
  const [requireUpdate, setRequireUpdate] = useState({})


  // Logica del modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Estados para carga visual
  const [isCompleted, setIsCompleted] = useState({horario:false, destinatarios:false,readyToSave:false})
  const [btnDisabled, setBtnDisabled] =  useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const prevRequireUpdate = useRef({});

  // handler cambio de destinatarios
  const handleChange = (event) => {
    const { name, checked } = event.target;

    if(!checked && (destinatariosYaAsociados.length > 0 || selectedDestinatarios.length > 0)){
      let isAllFalse = 1
      for(let email in selectedDestinatarios){
        if(!selectedDestinatarios[email]) isAllFalse += 1
      }
      if(isAllFalse === Object.keys(selectedDestinatarios).length) setIsCompleted({horario:true, destinatarios:false, readyToSave:false})
    }

    setSelectedDestinatarios((prev) => ({
      ...prev,
      [name]: checked,
    }));

  };

  // Manejar seleccion de horas
  const handleHourSelect = (day, hour) => {
    setSchedule(prev => ({
      ...prev,
      [day]: prev[day].includes(hour)
      ? prev[day].filter(h => h !== hour)
      : [...prev[day], hour].sort(),
    }));
  };

  // Manejar la eliminacion de la hora
  const handleDelete = (day, hourToDelete) => {
    setSchedule(prev => ({
      ...prev,
      [day]: prev[day].filter(hour => hour !== hourToDelete),
    }));
  };

  // Manejar la logica de envio de datos
  const handleSave = async () => {
    setBtnDisabled(true)

    let counter = 0
    // Validacion no enviar horario vacio
    for(let obj in schedule){
      if(schedule[obj].length === 0){
        counter += 1
      }
    }
    // Error en caso de no horario
    if(counter === 7){
      toast.warn("Porfavor añada un horario valido.")
      return
    }
    // Error no destinatario
    if(selectedDestinatarios.length === 0) {
      toast.warn("Porfavor seleccione al menos un destinatario.")
      return
    }
    let isValidLimitPerDay = false
    // Validacion cantidad maxima de horarios
    for(let obj in schedule){
      const elements = schedule[obj]
      if(elements.length > 4) {
        toast.error(`Solo se aceptan un maximo de 4 reportes por dia. Verifique el dia: ${obj} - ${elements.map(_ => String(_).toUpperCase()).join(", ")}`)
        isValidLimitPerDay = true
        break
      }
      setBtnDisabled(false)
    }

    // Envio de data si es que esta entre el rango valido de reportes por dia
    if(!isValidLimitPerDay){
      for (let email in selectedDestinatarios) {
        if (selectedDestinatarios[email]) {
          const match = destinatariosYaAsociados.find(dest => dest.EMAIL === email);
          if (match) {
            selectedDestinatarios[email] = {
              active: true,
              nombre: match.NOMBRE
            };
          }
        }
      }
      // Peticion POST para insertar el nuevo registro
      if (Object.keys(selectedDestinatarios).length > 0) {
        await insertReporteAtuomaticoProgramado(
          { cod_cliente: selectedClient.ID, horario: schedule, destinatarios: selectedDestinatarios, cabeceras:JSON.stringify(cabeceras) },
          setDestinatariosError
        );
        toast.success("Reporte asignado con exito.")
        // Volver activar el boton de guardar y re fetchear el elemento
        setBtnDisabled(false)
        setSelectedClient(null)
        await getReportesAutomaticosProgramados(selectedClient.ID, setReportesYaAsociados, setReportesError)
      } else {
        toast.warning("Por favor seleccione un destinatario válido.");
      }
    }
  };

  // Logica para eliminacion de toda data relacionada / (Horario, destinatarios)
  const handleDeleteButton = async (cod) => {
    const deleteStatus = await deleteActualReporteAutomatico(cod, setDeleteReportError);
    if (deleteStatus) {
      // Save the success message in localStorage
      toast.success( "Eliminado correctamente.");
      setSelectedClient(null)
    } else {
      toast.error(`Algo fue mal durante la eliminación del reporte asociado al cliente: ${cod}`);
    }
  };

  // Get Clients
  useEffect(() => {
    getClients(setClients, setClientReportError)
  }, [])

  // Manejar el estado del horario (Es valido / No es valido). Para render del resto del form
  useEffect(() => {
    if(selectedClient) {
      // contar cantidad de dias sin registros si son 7 significa que no relleno el horario.
        let counter = 0
      for(let dia in schedule){
        if(schedule[dia].length === 0) counter += 1
      }
      // Validar si el horario es valido es decir si tiene al menos un dia con reporte asignado.
        if(!isCompleted.horario && !isCompleted.destinatarios && !isCompleted.readyToSave){
          if(counter <= 6) setIsCompleted({horario:true, destinatarios:false, readyToSave:false})
        }else if (counter === 7) setIsCompleted({horario:false , destinatarios:false, readyToSave:false})

    }
  }, [selectedClient, schedule, isCompleted])


  // Manejar estado para renderizacion boton guardar horario (Destinatarios validos, debe estar seleccionado al menos uno para activar el boton de guardado)
  useEffect(() => {
    if(selectedClient && isCompleted.horario && !isCompleted.destinatarios){
      if(destinatariosYaAsociados.length === 0 && selectedDestinatarios.length === 0) setIsCompleted({horario:true,destinatarios:false, readyToSave:false})
      if(destinatariosYaAsociados.length > 0 || selectedDestinatarios.length > 0){
        for(let dest in selectedDestinatarios){
          if(selectedDestinatarios[dest]){
            setIsCompleted({horario:true,destinatarios:true, readyToSave:true})
            break
          }
        }
      }
      if(!destinatariosYaAsociados.length > 0 && !selectedDestinatarios.length > 0) setIsCompleted({horario:true, destinatarios:false, readyToSave:false})
    }
  }, [selectedClient,destinatariosYaAsociados, selectedDestinatarios, isCompleted])

  // Manjear estado del cliente seleccionado con el fin de limpiar estados no necesario o agregar nuevos valores a estados requeridos
  useEffect(() => {
    if (selectedClient) {
      // Realiza las peticiones al cambiar el cliente
      getReportesAutomaticosProgramados(selectedClient.ID, setReportesYaAsociados, setReportesError);
      getDestinatarios(selectedClient.ID, setDestinatariosYaAsociados, setDestinatariosError);
      getDestinatariosAsignables(selectedClient.ID, setDestinatariosAsignablesPorCliente, setDestinatariosError);
      getRegistroDestinatariosCorreoEnviado(selectedClient.ID, setDestinatariosStatus, setDestinatariosStatusError)
    }  
    setCabeceras([])
    prevRequireUpdate.current = {} // Limiar referencia cuando deseleccionamos un cliente
    // Si no hay cliente seleccionado, resetea los estados
    setSchedule({
      Lunes: [],
      Martes: [],
      Miercoles: [],
      Jueves: [],
      Viernes: [],
      Sabado: [],
      Domingo: [],
    });
    setSelectedDestinatarios([]);
    setDestinatariosYaAsociados([]);
    setDestinatariosAsignablesPorCliente([]);
    setReportesYaAsociados([])
    setDestinatariosStatus([])
    setDestinatariosError(null)
    setReportesError(null);
    setClientReportError(null);
    setInputDestinatarioError(null)
    setDeleteReportError(null);
    setDestinatariosStatusError(null);
    setIsLoading(true)
  }, [selectedClient]);


  // Renderizado de errores
  useEffect(() => {
    if(reportesError ){
      toast.warn(reportesError)
      setReportesError(null)
    }
    if(clientReportError ){
      toast.warn(clientReportError)
      setClientReportError(null)
    }
    if(deleteReportError){
      toast.warn(deleteReportError)
      setDeleteReportError(null)
    }
    if(destinatariosStatusError){
      toast.warn(destinatariosError)
      setDestinatariosStatusError(null)
    }
    if(reportesError && String(reportesError).includes("No se encontraron reportes")) setIsLoading(false)
  }, [destinatariosError, reportesError, clientReportError, deleteReportError, destinatariosStatusError, destinatariosYaAsociados])

// Verificacion de actualizacion de horario
useEffect(() => {
  setRequireUpdate({}); // Reseteamos el estado antes de calcular diferencias

  const newRequireUpdate = {};
  let hasUpdates = false;

  if (reportesYaAsociados.length > 0) {
    Object.keys(schedule).forEach((day) => {
      const dayMatch = reportesYaAsociados.filter(rep => rep.DIA_SEMANA === String(day).toUpperCase());

      if (dayMatch.length > 0 && JSON.stringify(dayMatch) !== JSON.stringify(schedule[day])) {
        newRequireUpdate[day] = dayMatch;
        hasUpdates = true;
      }
    });

  setRequireUpdate(newRequireUpdate);
  setIsOk(hasUpdates);
  }
}, [reportesYaAsociados, schedule]);

// Actualizacion visual de componenetes cuando cambia el horario
useEffect(() => {
  if (isOk && Object.keys(requireUpdate).length > 0) {
    // Evitar ejecución innecesaria si requireUpdate no cambió realmente
    if (JSON.stringify(prevRequireUpdate.current) !== JSON.stringify(requireUpdate)) {
      setSchedule((prevSchedule) => {
        const updatedSchedule = { ...prevSchedule };

        Object.keys(requireUpdate).forEach((day) => {
          updatedSchedule[day] = requireUpdate[day].map(rep => rep.HORA_ENVIO);
        });

        return updatedSchedule;
      });

      // Actualizamos la referencia para el próximo ciclo
      prevRequireUpdate.current = requireUpdate;
    }
  setIsLoading(false);
    setIsOk(false)
  }

}, [isOk, requireUpdate]);


  // Manejar destinatarios / Marcar como checked aquellos presentes y Manejar el caso de destinatarios no validos.
    useEffect(() => {
      const defaultSelected = destinatariosYaAsociados.reduce((acc, { EMAIL }) => {
        acc[EMAIL] = true;
        return acc;
      }, {});
      setSelectedDestinatarios(defaultSelected);

      if(selectedClient && destinatariosYaAsociados.length > 0 && destinatariosStatus.length > 0){
        const ds = destinatariosStatus.reduce((acc,va) => {
          if(va['ESTADO_ENVIO'] === "RECHAZADO"){
            if(!acc[va['ESTADO_ENVIO']] ){
              acc[va['ESTADO_ENVIO']] = []
            }
            if(!acc[va['ESTADO_ENVIO']].includes(va['EMAIL'])){
              acc[va['ESTADO_ENVIO']].push(va['EMAIL'])
            }
          }
          return acc
        }, {})
        if(ds.RECHAZADO && ds.RECHAZADO.length > 0){
          const filteredRejectedEmails = ds.RECHAZADO.filter(_ =>
            destinatariosYaAsociados.some(__ => __.EMAIL === _)
          );

          if(filteredRejectedEmails.length > 0) toast.error(`Porfavor deseleccione los siguientes destinatarios: ${filteredRejectedEmails.join(", ")} `)
          setDestinatariosStatusError(null)
          setDestinatariosStatus([])
        }
      }
    }, [selectedClient, destinatariosYaAsociados, destinatariosStatus, destinatariosStatusError]);

  // Manejador para cambio de modo
  const handleModoChange = (event, newModo) => {
    if (newModo !== null) {
      setModo(newModo);
    }
  };

  // Renderizar el contenido según el modo seleccionado
  const renderContent = () => {
    if (modo === 'cliente') {
return (
    <Box
    sx={{
      maxWidth: 1200,
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        minHeight: selectedClient ? 400 : 'auto'
    }}
    >
    {/* SELECCION CLIENTE */}
          <ClientSelector 
            clients={clients} 
            selectedClient={selectedClient} 
            setSelectedClient={setSelectedClient} 
    />

    {/* CONFIGURACION HORARIO */}
    {selectedClient && (
      <>
      {isLoading ? (
        <div style={{
          display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            width: '100%'
        }}>
        <CircularProgress />
        </div>
      ) : (
        <>
        <Box>
                    <ExpandableCheckboxList 
                      cabeceras={cabeceras} 
                      reportes={reportesYaAsociados} 
                      setReportes={setReportesYaAsociados} 
                      setCabeceras={setCabeceras} 
                      selectedClient={selectedClient}
                    />
                  </Box>
                  
                  <ScheduleConfiguration 
                    schedule={schedule}
                    handleHourSelect={handleHourSelect}
                    handleDelete={handleDelete}
                    isCompleted={isCompleted}
                    btnDisabled={btnDisabled}
                    handleSave={handleSave}
                  />

                  <DestinatariosSection 
                    isCompleted={isCompleted}
                    destinatariosYaAsociados={destinatariosYaAsociados}
                    selectedDestinatarios={selectedDestinatarios}
                    handleChange={handleChange}
                    handleOpen={handleOpen}
        open={open}
                    handleClose={handleClose}
                    destinatariosAsignablesPorCliente={destinatariosAsignablesPorCliente}
                    inputDestinatarioError={inputDestinatarioError}
                    setInputDestinatarioError={setInputDestinatarioError}
                    setDestinatariosYaAsociados={setDestinatariosYaAsociados}
                    btnDisabled={btnDisabled}
                    setOpen={setOpen}
                  />
                  
        {(reportesYaAsociados.length > 0) && (
          <>
          <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => setOpenConfirm(true)}
          sx={{
            height: 30
          }}
          >
          Eliminar reporte ya asignado
          </Button>

          <DeleteConfirmDialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          onConfirm={() => {handleDeleteButton(selectedClient.ID)}}
          />
          </>
        )}
        </>
      )}
      </>
    )}
    </Box>
      );
    } else {
      return <AgenteView />;
    }
  };

  return (
    <>
      <Box
        sx={{
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          gap: 2, // Reducido de 3
          padding: {
            xs: 1.5, // Reducido de 2
            sm: 2,   // Reducido de 3
            md: 3    // Reducido de 4
          },
          width: '100%'
        }}
      >
        {/* Selector de modo */}
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            mb: 1, // Reducido de 3
            pb: 1  // Añadido padding bottom más pequeño
          }}
        >
          <ToggleButtonGroup
            value={modo}
            exclusive
            onChange={handleModoChange}
            aria-label="modo de visualización"
            size="small" // Añadido para hacer los botones más pequeños
            sx={{
              mb: 1, // Reducido de 3
              backgroundColor: 'background.paper',
              padding: '2px', // Reducido de 4px
              borderRadius: '8px', // Reducido de 12px
              border: '1px solid',
              borderColor: 'divider',
              boxShadow: '0 1px 4px rgba(0,0,0,0.05)',
              '& .MuiToggleButton-root': {
                border: 'none',
                mx: 0.25, // Reducido de 0.5
                px: { xs: 1.5, sm: 2 }, // Reducido
                py: 0.75, // Reducido de 1
                borderRadius: '6px !important', // Reducido de 8px
                fontSize: { xs: '0.8rem', sm: '0.875rem' }, // Reducido
                fontWeight: 500,
                height: '32px', // Altura fija más pequeña
                '&.Mui-selected': {
                  backgroundColor: colors.blue,
                  color: 'white'
                }
              }
            }}
          >
            <ToggleButton value="cliente" aria-label="modo cliente">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <BusinessIcon sx={{ fontSize: '1rem' }} />
                <span>Cliente</span>
              </Box>
            </ToggleButton>
            <ToggleButton value="agente" aria-label="modo agente">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <PersonIcon sx={{ fontSize: '1rem' }} />
                <span>Agente</span>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>

          <Typography 
            variant="caption" 
            sx={{ 
              color: 'text.secondary',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              fontSize: '0.75rem'
            }}
          >
            <InfoOutlinedIcon sx={{ fontSize: '0.875rem' }} />
            {modo === 'cliente' ? 'Gestione reportes por cliente' : 'Gestione reportes por agente'}
          </Typography>
        </Box>

        {/* Header más compacto */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            mb: 2, // Reducido de 4
            pb: 1  // Reducido
          }}
        >
          <ScheduleIcon 
            sx={{ 
              fontSize: { xs: '1.5rem', sm: '1.75rem' },
              color: colors.blue
            }} 
          />
          <Box sx={{ textAlign: 'center' }}>
            <Typography 
              variant="h5" // Cambiado de h4
              component="h2" 
              sx={{
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                fontWeight: 600,
                color: colors.blue,
                mb: 0.5
              }}
            >
              Programación de Reportes Automáticos
            </Typography>
            
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'center',
                flexWrap: 'wrap',
                '& > div': {
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  color: 'text.secondary',
                  fontSize: '0.75rem'
                }
              }}
            >
              {[
                { icon: <CalendarTodayIcon />, text: 'Seleccione días' },
                { icon: <AccessTimeIcon />, text: 'Defina horarios' },
                { icon: <GroupIcon />, text: 'Asigne destinatarios' }
              ].map((item, index) => (
                <Box key={index}>
                  {React.cloneElement(item.icon, { sx: { fontSize: '0.875rem' } })}
                  <Typography variant="caption">{item.text}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        {renderContent()}
      </Box>
    </>
  );
};


export default ReporteAutomaticoCliente;

