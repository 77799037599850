import React, { memo, useState, useEffect } from "react";
import { obtenerVehiculos, eliminarVehiculos, actualizarVehiculos } from "../../services/PortalVehiculosService";
import TableVehiculos from "../../components/AdministracionComponentes/componentesVehiculos/tableVehiculos";
import EditModalVehiculos from "../../components/AdministracionComponentes/componentesVehiculos/EditModalVehiculos";
import DeleteModalVehiculos from "../../components/AdministracionComponentes/componentesVehiculos/DeleteModalVehiculos";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { camposVehiculos } from "../../components/AdministracionComponentes/componentesUsuariosChoferes/ConfigCampos";

const PortalVehiculos = () => {
  const [vehiculos, setVehiculos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedVehiculoForEdit, setSelectedVehiculoForEdit] = useState(null);
  const [selectedVehiculoForDelete, setSelectedVehiculoForDelete] = useState(null);

  useEffect(() => {
    const cargarVehiculos = async () => {
      setLoading(true);
      try {
        const data = await obtenerVehiculos();
        setVehiculos(data);
      } catch (error) {
        console.error("Error al obtener los vehiculos:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarVehiculos();
  }, []);
  

  const onEditClick = (vehiculo) => {
    setSelectedVehiculoForEdit(vehiculo);
    setEditModalOpen(true);
  };

  const onDeleteClick = (vehiculo) => {
    setSelectedVehiculoForDelete(vehiculo);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await eliminarVehiculos(selectedVehiculoForDelete.ID_VEHICULO);
      const filteredUsuarios = vehiculos.filter(
        (vehiculos) => vehiculos.ID_VEHICULO !== selectedVehiculoForDelete.ID_VEHICULO,
      );
      setVehiculos(filteredUsuarios);
      setDeleteModalOpen(false);
      toast.success("Vehiculo eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el Vehiculo:", error);
      toast.error("Error al eliminar el Vehiculo");
    }
  };

  const handleSaveEdit = async (updatedVehiculos) => {
    try {
      await actualizarVehiculos(updatedVehiculos.ID_VEHICULO, updatedVehiculos);
      await reloadVehiculos();
      setEditModalOpen(false);
      toast.success("Vehiculo actualizado con éxito");
    } catch (error) {
      console.error("Error al actualizar el Vehiculo:", error);
      toast.error("Error al actualizar el Vehiculo");
    }
  };

  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => onEditClick(params.row)}>
            <EditIcon style={{ color: "#041562" }} />
          </IconButton>
          <IconButton onClick={() => onDeleteClick(params.row)}>
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </>
      ),
      width: 100,
    },
    { field: "ID_VEHICULO", headerName: "ID VEHICULO", width: 60 },
    { field: "PATENTE", headerName: "Patente", width: 300 },
    { field: "MARCA", headerName: "Marca", width: 250 },
    { field: "MODELO", headerName: "Modelo", width: 250 },
    { field: "ANIO_FABRICACION", headerName: "Año de Fabricacion", width: 130 },
    { field: "CAPACIDAD_CARGA", headerName: "Capacidad de Carga", width: 100 },
    { field: "KILOMETRAJE", headerName: "Kilometraje", width: 130 },
    { field: "ESTADO", headerName: "Estado", width: 300 },
    { field: "OBSERVACIONES", headerName: "Observaciones", width: 220 },
    { field: "CLIMATIZACION", headerName: "Climatizacion", width: 150 },
    { field: "CIUDAD_NOMBRE", headerName: "Ciudad", width: 150 },
  ];

  useEffect(() => {
    const cargarVehiculos = async () => {
      setLoading(true);
      try {
        const data = await obtenerVehiculos();
        setVehiculos(data);
      } catch (error) {
        console.error("Error al obtener los Vehiculos:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarVehiculos();
  }, []);

  const reloadVehiculos = async () => {
    setLoading(true);
    try {
      const data = await obtenerVehiculos();
      setVehiculos(data);
    } catch (error) {
      console.error("Error al recargar los vehiculos:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <TableVehiculos columns={columns} rows={vehiculos} loading={loading} reloadVehiculos={reloadVehiculos} />
      {selectedVehiculoForEdit && (
        <EditModalVehiculos
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
            setSelectedVehiculoForEdit(null);
          }}
          vehiculo={selectedVehiculoForEdit}
          onSave={handleSaveEdit}
          campos={camposVehiculos}
        />
      )}
      {selectedVehiculoForDelete && (
        <DeleteModalVehiculos
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
            setSelectedVehiculoForDelete(null);
          }}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default memo(PortalVehiculos);
