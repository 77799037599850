import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Autocomplete, Typography, Paper, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import useSound from "use-sound";
import sonido1 from "../../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/AlertaMa.mp3";
import alertaManifiesto0 from "../../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/Alerta Manifiesto.mp3";
import {
  getChoferesXId,
  getOrdenIdReferencia,
  insertPickingTemp,
  deletePickingTemp,
  buscarDatosPickingTempManifiestoRuta,
  downloadManifestPdfGlobal,
  getPatentes,
  ordenesPendientesRutaStgo,
  ordenesPendientesRutaRegiones,
} from "../../../../services/ManifiestosServices";
import { buscarChoferes } from "../../../../services/ManifiestoComunaServices";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import TablaManifiestos from "../TablaManifiestos";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  handleChangeChoferes,
  handleChangePeoneta,
  handleChangePatente,
  handleChangeNota,
} from "../../componentesManifiestoRuta/EventHandler";
import {
  TextFieldPersonalizado,
  AutocompletePersonalizado,
  BotonPersonalizado,
} from "../../componentesVisuales/UtilesPersonalizados";
import {
  handleCancelar,
  handleDelete,
  crearManifiesto,
  CodigoBarraIngresada,
} from "../../componentesManifiestoRuta/FunctionsManifRuta";

const SalidaRutaCB = ({ idClienteA, procesoA }) => {
  const inputRef = useRef(null);

  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const ciudadUser = userInfo ? userInfo.nombre_ciudad_agentes : null;
  const nombreUser = userInfo ? userInfo.name : null;
  const iataUser = userInfo ? userInfo.iata_agentes : null;
  const agenteUser = userInfo ? userInfo.AGENTE_ASIGNADO : null;

  const [proceso] = useState("SALIDARUTA");
  const [fechaActual, setfechaActual] = useState("");
  const [nota, setNota] = useState("");
  const [choferes, setChoferes] = useState([]);
  const [idChofer, setIdChofer] = useState("");
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [selectedPeoneta, setSelectedPeoneta] = useState(null);
  const [idPeoneta, setIdPeoneta] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [ordenesPendientes, setOrdenesPendientes] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [totalOrdenes, setTotalOrdenes] = useState(0);
  const [totalPendientes, setTotalPendientes] = useState(0);
  const [playsound] = useSound(sonido1);
  const [loadingEliminar, setLoadingEliminar] = React.useState(false);
  const [codigoCliente, setCodigoCliente] = useState("");
  const [comunas, setComunas] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [selectedComunas, setSelectedComunas] = useState([]);
  const [patentes, setPatentes] = useState([]);
  const [selectedPatente, setSelectedPatente] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fechaActual = moment().format("YYYY-MM-DD");
        setfechaActual(fechaActual);

        let choferesData = [];

        if (iataUser !== "SCL") {
          choferesData = await buscarChoferes(agenteUser);
        } else {
          choferesData = await getChoferesXId(id_user);
        }
        const choferesOptions = choferesData.map((choferes) => ({
          value: choferes.COD_CHOFER,
          label: choferes.NOMBRE,
        }));
        setChoferes(choferesOptions);

        if (iataUser !== "SCL") {
          const datos = {
            DESTINO: iataUser,
            AGENTE_ASIGNADO: agenteUser,
            COD_CLIENTE: codigoCliente,
          };
          const response = await ordenesPendientesRutaRegiones(datos);
          setOrdenesPendientes(response);
          setTotalPendientes(response.length);
        }
      } catch {}
    };
    fetchData();
  }, [agenteUser, codigoCliente, iataUser, idClienteA, id_user, procesoA]);

  useEffect(() => {
    const Clientes = async () => {
      try {
        const clientesData = [{ label: "NOVOFARMA SERVICE S.A.", value: 632 }];

        setSelectedCliente(clientesData[0].label);
        setCodigoCliente(clientesData[0].value);
        setClientes(clientesData);
      } catch {}
    };
    Clientes();
  }, []);

  useEffect(() => {
    const Patentes = async () => {
      try {
        const patentesData = await getPatentes();
        const patentesOptions = patentesData.map((patentes) => ({
          value: patentes.ID,
          label: patentes.PATENTE,
        }));
        setPatentes(patentesOptions);
      } catch {}
    };
    Patentes();
  }, []);

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
    playsound();
    inputRef.current.value = "";
  };

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 400 },
    { field: "BULTOS", headerName: "Bultos", width: 100 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 200 },
    { field: "NOMBRE_DESTINO", headerName: "Comuna", width: 200 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon
          style={{ color: "#DA251C", cursor: "pointer" }}
          onClick={() =>
            handleDelete({
              orden: params.row.OD,
              id_user,
              proceso,
              idChofer,
              deletePickingTemp,
              buscarDatosPickingTempManifiestoRuta,
              setLoadingEliminar,
              setOrdenesDataTabla,
              setTotalOrdenes,
              setTotalBultos,
              selectedComunas,
              setOrdenesPendientes,
              setTotalPendientes,
              iataUser,
              agenteUser,
              codigoCliente,
            })
          }
        />
      ),
    },
  ];

  const columnsPendientes = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "CLIENTE", headerName: "Cliente", width: 350 },
    { field: "DIRECCION", headerName: "Direccion", width: 300 },
    { field: "DESTINO", headerName: "Destino", width: 170 },
  ];

  const resetState = () => {
    setNota("");
    setSelectedComunas([]);
    inputRef.current.value = "";
    setOrdenesDataTabla([]);
    setTotalBultos(0);
    setIdChofer("");
    setIdPeoneta("");
    const fechaActual = moment().format("YYYY-MM-DD");
    setfechaActual(fechaActual);
    setSelectedChofer(null);
    setSelectedPeoneta(null);
    setTotalOrdenes("");
    setLoading(false);
    setIsbtnEnviar(false);
    setSelectedPatente("");
    setOrdenesPendientes([]);
    setTotalPendientes(0);
  };

  const handleChangeClientes = async (event, newValue) => {
    setSelectedCliente(newValue);
    setCodigoCliente(newValue.value);
  };

  const handleIngresarOrden = async (event) => {
    await CodigoBarraIngresada({
      event,
      inputRef,
      idChofer,
      idPeoneta,
      selectedComunas,
      selectedPatente,
      userInfo,
      getOrdenIdReferencia,
      ordenesDataTabla,
      idClienteA,
      iataUser,
      ingresarOrdenTabla,
      showError,
      agenteUser,
      codigoCliente,
    });
  };

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {
        try {
          await insertPickingTemp({
            OD: dataOrden[0].OD,
            COD_USUARIO: id_user,
            COD_CHOFER: idChofer,
            COMUNA: iataUser,
            PROCESO: proceso,
            COD_CLIENTE: codigoCliente,
          });

          setOrdenesDataTabla([...ordenesDataTabla, dataOrden[0]]);
          setTotalBultos(totalBultos + dataOrden[0].BULTOS);
          setTotalOrdenes(totalOrdenes + 1);

          if (iataUser === "SCL") {
            const response = await ordenesPendientesRutaStgo(
              selectedComunas.map((comuna) => comuna.IATA),
              codigoCliente,
            );
            setOrdenesPendientes(response);
            setTotalPendientes(response.length);
          } else {
            const datos = {
              DESTINO: iataUser,
              AGENTE_ASIGNADO: agenteUser,
              COD_CLIENTE: codigoCliente,
            };
            const response = await ordenesPendientesRutaRegiones(datos);
            setOrdenesPendientes(response);
            setTotalPendientes(response.length);
          }
        } catch (error) {
          console.error("Error:", error);
        }
        inputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    setIsbtnEnviar(false);
    if (!idChofer) {
      showError("Debe seleccionar un chofer antes de continuar!!!");
      setLoading(false);
      return;
    } else if (
      userInfo.tipo_usuario !== "AGENTE" &&
      userInfo.tipo_usuario !== "AGENTE_HUB" &&
      userInfo.tipo_usuario !== "AGENTE_ADMIN" &&
      !selectedPatente
    ) {
      showError("Debe seleccionar una patente antes de continuar!!!");
      setLoading(false);
      return;
    } else if (selectedComunas.length === 0) {
      showError("Debe seleccionar una comuna antes de continuar!!!");
      setLoading(false);
      return;
    } else if (ordenesDataTabla.length === 0) {
      showError("Manifiestos debe contener una orden!!!");
      setLoading(false);
      return;
    } else {
      let mostrarConfirmacion = true;

      if (iataUser === "SCL") {
        const data = await ordenesPendientesRutaStgo(
          selectedComunas.map((comuna) => comuna.IATA),
          codigoCliente,
        );
        setOrdenesPendientes(data);
        setTotalPendientes(data.length);

        if (data.length !== 0) {
          const audioAlerta = new Audio(alertaManifiesto0);
          audioAlerta.play();

          const result = await Swal.fire({
            title: "¡Atención!",
            text: `Hay ${data.length} órdenes pendientes. ¿Desea continuar con la creación del manifiesto?`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "No, continuar ingresando",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, crear manifiesto",
          });

          audioAlerta.pause();
          audioAlerta.currentTime = 0;

          if (!result.isConfirmed) {
            setLoading(false);
            return;
          } else {
            mostrarConfirmacion = false;

            try {
              const numeroManifiesto = await crearManifiesto({
                idChofer,
                idPeoneta,
                selectedComunas,
                id_user,
                proceso,
                codigoCliente,
                nombreUser,
                totalBultos,
                iataUser,
                nota,
                selectedPatente,
                agenteUser,
              });

              Swal.fire({
                title: "Manifiesto Creado",
                text: "N°: " + numeroManifiesto,
                icon: "success",
              });

              try {
                await downloadManifestPdfGlobal(numeroManifiesto, proceso, "", selectedChofer.label, false);
              } catch (error) {
                showError("Error al descargar PDF!!!");
                setLoading(false);
                return;
              }

              resetState();
              setLoading(false);
            } catch (error) {
              console.error("Error al crear manifiesto:", error);
              showError("Error al crear el manifiesto");
              setLoading(false);
            }
          }
        }
      } else {
        const datos = {
          DESTINO: iataUser,
          AGENTE_ASIGNADO: agenteUser,
          COD_CLIENTE: codigoCliente,
        };
        const data = await ordenesPendientesRutaRegiones(datos);
        setOrdenesPendientes(data);
        setTotalPendientes(data.length);

        if (data.length !== 0) {
          const audioAlerta = new Audio(alertaManifiesto0);
          audioAlerta.play();

          const result = await Swal.fire({
            title: "¡Atención!",
            text: `Hay ${data.length} órdenes pendientes para la región. ¿Desea continuar con la creación del manifiesto?`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "No, continuar ingresando",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, crear manifiesto",
          });

          audioAlerta.pause();
          audioAlerta.currentTime = 0;

          if (!result.isConfirmed) {
            setLoading(false);
            return;
          } else {
            mostrarConfirmacion = false;

            try {
              const numeroManifiesto = await crearManifiesto({
                idChofer,
                idPeoneta,
                selectedComunas,
                id_user,
                proceso,
                codigoCliente,
                nombreUser,
                totalBultos,
                iataUser,
                nota,
                selectedPatente,
                agenteUser,
              });

              Swal.fire({
                title: "Manifiesto Creado",
                text: "N°: " + numeroManifiesto,
                icon: "success",
              });

              try {
                await downloadManifestPdfGlobal(numeroManifiesto, proceso, "", selectedChofer.label, false);
              } catch (error) {
                showError("Error al descargar PDF!!!");
                setLoading(false);
                return;
              }

              resetState();
              setLoading(false);
            } catch (error) {
              console.error("Error al crear manifiesto:", error);
              showError("Error al crear el manifiesto");
              setLoading(false);
            }
          }
        }
      }

      if (mostrarConfirmacion) {
        Swal.fire({
          title: "Seguro de Crear Manifiesto?",
          text: "Esta a punto de crear un nuevo Manifiesto!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const numeroManifiesto = await crearManifiesto({
              idChofer,
              idPeoneta,
              selectedComunas,
              id_user,
              proceso,
              codigoCliente,
              nombreUser,
              totalBultos,
              iataUser,
              nota,
              selectedPatente,
              agenteUser,
            });

            Swal.fire({
              title: "Manifiesto Creado",
              text: "N°: " + numeroManifiesto,
              icon: "success",
            });
            try {
              await downloadManifestPdfGlobal(numeroManifiesto, proceso, "", selectedChofer.label, false);
            } catch (error) {
              showError("Error al descargar PDF!!!");
              return;
            }
            resetState();
          }
          setLoading(false);
        });
      }
    }
  };
  const handleChangeComunas = async (newValue) => {
    setSelectedComunas(newValue);

    if (iataUser === "SCL") {
      const selectedIatas = newValue.map((comuna) => comuna.IATA);

      if (selectedIatas.length === 0) {
        setOrdenesPendientes([]);
        setTotalPendientes(0);
        console.log("No hay comunas seleccionadas, estado limpiado.");
        return;
      }

      if (selectedIatas && selectedIatas.length > 0) {
        try {
          const response = await ordenesPendientesRutaStgo(selectedIatas, codigoCliente);
          setOrdenesPendientes(response);
          setTotalPendientes(response.length);
          console.log("ORDENES PENDIENTES", response);
        } catch (error) {
          console.error("Error obteniendo órdenes pendientes:", error);
        }
      }
    }
  };

  const handleCancelarClick = () => {
    handleCancelar({
      ordenesDataTabla,
      id_user,
      idChofer,
      proceso,
      resetState,
      showError,
    });
  };

  return (
    /*  */
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Autocomplete
                disabled
                value={selectedCliente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={clientes}
                onChange={handleChangeClientes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CLIENTE" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextFieldPersonalizado label="Fecha" value={fechaActual} disabled />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="ORIGEN MANIFIESTO"
                value={ciudadUser}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={
                ["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) &&
                userInfo.AGENTE_ASIGNADO !== "BODEGA SANTIAGO"
                  ? 4
                  : 3
              }
              lg={
                ["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) &&
                userInfo.AGENTE_ASIGNADO !== "BODEGA SANTIAGO"
                  ? 4
                  : 3
              }
            >
              <AutocompletePersonalizado
                selectedValue={selectedChofer}
                setSelectedValue={setSelectedChofer}
                options={choferes}
                onChange={(event, newValue) =>
                  handleChangeChoferes(
                    event,
                    newValue,
                    setSelectedChofer,
                    setIdChofer,
                    setTotalOrdenes,
                    setTotalBultos,
                    setOrdenesDataTabla,
                    setComunas,
                    id_user,
                    proceso,
                    codigoCliente,
                  )
                }
                label="Seleccione un chofer"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={
                ["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) &&
                userInfo.AGENTE_ASIGNADO !== "BODEGA SANTIAGO"
                  ? 4
                  : 3
              }
              lg={
                ["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) &&
                userInfo.AGENTE_ASIGNADO !== "BODEGA SANTIAGO"
                  ? 4
                  : 3
              }
            >
              {/* Autocomplete para Peonetas */}
              <AutocompletePersonalizado
                disableClearable={false}
                selectedValue={selectedPeoneta}
                setSelectedValue={setSelectedPeoneta}
                options={choferes}
                onChange={(event, newValue) => handleChangePeoneta(event, newValue, setSelectedPeoneta, setIdPeoneta)}
                label="Seleccione un peoneta"
              />
            </Grid>

            {!["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ||
            userInfo.AGENTE_ASIGNADO === "BODEGA SANTIAGO" ? (
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <AutocompletePersonalizado
                  selectedValue={selectedPatente}
                  setSelectedValue={setSelectedPatente}
                  options={patentes}
                  onChange={(event, newValue) => handleChangePatente(event, newValue, setSelectedPatente)}
                  label="Seleccione una patente"
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={comunas}
                disableCloseOnSelect={false}
                getOptionLabel={(option) => option.NOMBRE}
                isOptionEqualToValue={(option, value) => option.ID_IATA === value.ID_IATA}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: 8 }} />
                    {option.NOMBRE}
                  </li>
                )}
                value={selectedComunas}
                onChange={(event, newValue) => {
                  setSelectedComunas(newValue);
                  handleChangeComunas(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione una comuna" variant="outlined" fullWidth size="small" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              {/* Campo de Texto para Nota */}
              <TextFieldPersonalizado
                label="Nota"
                value={nota}
                onChange={(event) => handleChangeNota(event, setNota)}
                multiline={true}
                rows={1}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} justifyContent="flex-end">
              <BotonPersonalizado
                label="Crear Manifiesto"
                onClick={handleCrearManifiesto}
                color="#041562"
                disabled={isbtnEnviar}
                loading={loading}
                size="medium"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} justifyContent="flex-end">
              <BotonPersonalizado
                label="CANCELAR"
                onClick={handleCancelarClick}
                color="#DA251C"
                disabled={isbtnEnviar}
                size="medium"
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper style={{ padding: "10px", marginTop: "10px" }}>
        <form>
          <Grid container spacing={2} sx={{ width: "100%", height: "100vh" }}>
            <Grid item xs={12} md={8} lg={8}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Ingrese Codigo Barra" /*  */
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        CodigoBarraIngresada({
                          event: e,
                          inputRef,
                          idChofer,
                          idPeoneta,
                          selectedComunas,
                          selectedPatente,
                          userInfo,
                          getOrdenIdReferencia,
                          ordenesDataTabla,
                          idClienteA,
                          iataUser,
                          ingresarOrdenTabla,
                          showError,
                          agenteUser,
                          codigoCliente,
                        });
                      }
                    }}
                    style={{
                      width: "100%",
                      padding: "5px",
                      fontSize: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <BotonPersonalizado
                    label="INGRESAR"
                    onClick={handleIngresarOrden}
                    color="#041562"
                    size="medium"
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Arial, sans-serif",
                      color: "#041562",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                    }}
                  >
                    ORDENES: {totalOrdenes}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Arial, sans-serif",
                      color: "#041562",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                    }}
                  >
                    BULTOS: {totalBultos}
                  </Typography>
                </Grid>
              </Grid>
              <TablaManifiestos rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Arial, sans-serif",
                      color: "#DA251C",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                    }}
                  >
                    PENDIENTES: {totalPendientes}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TablaManifiestos rows={ordenesPendientes || []} columns={columnsPendientes} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
export default SalidaRutaCB;
