import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Error404 from "../../components/assets/Error404";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, Button, Box } from "@mui/material";

const ConsultaMasivo = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const idUsuario = userInfo ? userInfo.id : null;
  const token = userInfo ? userInfo.token : null;
  const [iframeLoaded, setIframeLoaded] = useState(null);
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [showError, setShowError] = useState(false);

  const iframeUrl = `https://app.cargoex.cl/sistemamigracion/public/consultamasiva?idUsuario=${idUsuario}&token=${token}`;

  const handleRetry = () => {
    setLoading(true);
    setShowError(false);
    setRetryCount(0);
    setIframeLoaded(null);
  };

  useEffect(() => {
    if (!idUsuario || !token) {
      setIframeLoaded(false);
      setLoading(false);
      setShowError(true);
    }
  }, [idUsuario, token]);

  useEffect(() => {
    if (iframeLoaded === false && retryCount < 3) {
      const timer = setTimeout(() => {
        setLoading(true);
        setRetryCount(retryCount + 1);
        setIframeLoaded(null);
      }, 3000);
      return () => clearTimeout(timer);
    } else if (retryCount >= 3) {
      setShowError(true);
      setLoading(false);
    }
  }, [iframeLoaded, retryCount]);

  const handleLoad = () => {
    setIframeLoaded(true);
    setLoading(false);
    setRetryCount(0);
    setShowError(false);
  };

  const handleError = () => {
    setIframeLoaded(false);
    setLoading(false);
    if (retryCount >= 3) {
      setShowError(true);
    }
  };

  if (showError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        p={3}
      >
        <Typography variant="h6" color="error" gutterBottom>
          Error al cargar el contenido
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center" paragraph>
          No se pudo cargar el contenido. Esto puede deberse a problemas de conexión o mantenimiento del servidor.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRetry}
          style={{ marginTop: "1rem" }}
        >
          Reintentar
        </Button>
      </Box>
    );
  }

  return (
    <Box style={{ height: "100%", width: "100%", position: "relative" }}>
      {loading && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <CircularProgress />
          <Typography variant="body2" style={{ marginTop: "1rem" }}>
            Cargando contenido...
          </Typography>
        </Box>
      )}
      {!loading && iframeLoaded === false ? (
        <Error404 />
      ) : (
        <iframe
          src={iframeUrl}
          title="Reporte Agentes"
          width="100%"
          height="100%"
          style={{ 
            border: "none",
            display: loading ? "none" : "block"
          }}
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
    </Box>
  );
};

export default ConsultaMasivo;
