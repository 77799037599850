import React, { useState, useEffect, useMemo } from 'react';
import {
  Box, Typography, Button, Grid, TextField, Autocomplete,
  Divider, Chip,
  IconButton, CircularProgress, Container
} from '@mui/material';
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AlarmIcon from '@mui/icons-material/Alarm';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  Business as BusinessIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AddressAutoCompleter, AsignedVehicleAutocompleter, ClientesAutocompleter, ConductorAutocompelter } from './FormularioRComponents';
import { deleteRecoleccionPCEChofer, getChoferesRecoleccion, getDireccionesCliente, getRecoleccionesPCEjecutadas, getRecoleccionesPendientesByCliente, getVehiculoAsignados, getVehiculosService, insertRecoleccionPCE, insertRecolectaPlanta, insertRecolectaPlantaChofer, updateRecoleccionEjecucionStatus } from '../../../services/OperacionesNotificadorService';
import { formatTimeWithRules } from './UtilitiesComponents';
import { updateChoferRecoleccion } from '../../../services/OperacionesNotificadorService';
const colors = { red: "#DA251C", blue: "#041562", green: "#046A38", orange: "#FF7700" }

/**
 * @module FormularioRetiro
 * @description Módulo que contiene el formulario para la gestión de retiros de carga.
 * Permite crear nuevos retiros y editar retiros existentes, gestionando la asignación
 * de choferes, vehículos, horarios y direcciones.
 */

/**
 * Formatea una hora en formato HH:MM para su uso con CURRENT_TIMESTAMP en la base de datos
 * manteniendo la hora local sin conversión a UTC.
 * 
 * @function formatearHoraATimestamp
 * @param {string} hora - Hora en formato "HH:MM"
 * @returns {string} Fecha y hora formateada como YYYY-MM-DD HH:MM:SS
 */
const formatearHoraATimestamp = (hora) => {
  const now = new Date();
  const [hours, minutes] = hora.split(":").map(Number);

  // Crear fecha con la hora especificada
  const fecha = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, 0);

  // Formatear como YYYY-MM-DD HH:MM:SS (formato SQL)
  return fecha.getFullYear() + '-' +
    String(fecha.getMonth() + 1).padStart(2, '0') + '-' +
    String(fecha.getDate()).padStart(2, '0') + ' ' +
    String(fecha.getHours()).padStart(2, '0') + ':' +
    String(fecha.getMinutes()).padStart(2, '0') + ':00';
};

/**
 * @component RetiroForm
 * @description Componente principal para la gestión de formularios de retiro.
 * Permite crear nuevos retiros y editar retiros existentes, gestionando toda la información
 * necesaria como clientes, direcciones, choferes, vehículos y horarios.
 * 
 * Características principales:
 * - Gestión de modos inserción/actualización
 * - Autocompletado de datos relacionados
 * - Validación de datos en tiempo real
 * - Manejo de choferes principales y de apoyo
 * - Control de horarios según reglas de negocio
 * 
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.requiredData - Datos requeridos (choferes, clientes)
 * @param {Object} props.modalHandler - Manejador del modal contenedor
 * @param {Object} props.retirosAgregados - Retiros ya registrados en el sistema
 * @param {Object} props.initialData - Datos iniciales para modo edición
 * @param {Function} props.refetchData - Función para recargar datos
 */
const RetiroForm = ({
  requiredData,
  modalHandler,
  retirosAgregados,
  initialData,
  refetchData
}) => {
  // Add a formMode state variable to explicitly track mode
  const [formMode, setFormMode] = useState(() =>
    initialData && typeof initialData === 'object' && Object.keys(initialData).length > 0
      ? 'update'
      : 'insert'
  );

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [asignedVehicles, setAsignedVehicles] = useState([]);
  const [asignedAddresses, setAsignedAddresses] = useState([]);
  const [requestError, setRequestError] = useState({ asignedVehicles: false, asignedAdresses: false });
  const [slots, setSlots] = useState([]);
  const [principalTime, setPrincipalTime] = useState(() => {
    // Si hay datos iniciales y es una actualización, usar la hora inicial
    if (initialData?.hora) {
      return dayjs(initialData.hora, "HH:mm").isValid()
        ? initialData.hora
        : dayjs().add(2, 'hour').format("HH:mm");
    }
    // Si no hay datos iniciales, usar hora actual + 2 horas
    return dayjs().add(2, 'hour').format("HH:mm");
  });
  const { choferes, clientes } = requiredData;
  const [storedData, setStoredData] = useState({});
  const [clienteExistente, setClienteExistente] = useState(false);

  // Add a state to track manual changes to the driver
  const [driverManuallyChanged, setDriverManuallyChanged] = useState(false);

  // Update form mode when initialData changes
  useEffect(() => {
    if (initialData && typeof initialData === 'object' && Object.keys(initialData).length > 0) {
      setFormMode('update');
    } else if (clienteExistente) {
      setFormMode('update');
    } else {
      setFormMode('insert');
      // Clean up form data only when explicitly switching to insert mode with no initial data
      setSelectedClient(null);
      setSelectedAddress(null);
      setSelectedDriver(null);
      setSelectedVehicle(null);
      setAsignedVehicles([]);
      setAsignedAddresses([]);
      setSlots([]);
      // Only reset principalTime if there's no initialData
      if (!initialData) {
        setPrincipalTime(dayjs().add(2, 'hour').format("HH:mm"));
      }
      setStoredData({});
    }
  }, [initialData, clienteExistente]);

  // Add a state to track if we're currently loading initialData to prevent multiple data fetches
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [isLoadingAddressData, setIsLoadingAddressData] = useState(false);

  // New useEffect to handle clienteExistente changes
  useEffect(() => {
    if (clienteExistente && selectedClient) {
      setFormMode('update');
    } else if (!initialData) {
      // Solo volvemos a insert si no tenemos initialData
      setFormMode('insert');
    }
  }, [clienteExistente, selectedClient, initialData]);

  // Efecto para mantener el modo de actualización cuando cambia la dirección
  useEffect(() => {
    if (selectedClient && selectedAddress && formMode === 'update') {
      // No hacemos nada, solo mantenemos el modo 'update'
    }
  }, [selectedAddress, selectedClient, formMode]);

  // Efecto para cargar datos iniciales
  useEffect(() => {
    if (formMode === 'update' && initialData && requiredData && !isLoadingInitialData) {
      
      // Set loading states
      setIsLoadingInitialData(true);
      setStoredData(prev => ({...prev, isLoading: true}));
      
      // 1. First find and set the client
      const matchingClient = clientes.find(c => {
        return c.NOMBRE === initialData.client || c.ID === initialData.clientId;
      });

      if (matchingClient) {
        setSelectedClient(matchingClient);

        // 2. Load and set address after finding client
        setIsLoadingAddressData(true);
        getDireccionesCliente(matchingClient.ID, async (addresses) => {
          setAsignedAddresses(addresses);
          
          // Find matching address with more accurate matching
          const matchingAddress = addresses.find(addr => 
            addr.DIRECCION === initialData.direccion || 
            addr.DIRECCION.includes(initialData.direccion) || 
            initialData.direccion.includes(addr.DIRECCION)
          );

          if (matchingAddress) {
            setSelectedAddress(matchingAddress);

            // 3. Handle driver differently based on if we're loading a principal or support driver
            if (!driverManuallyChanged) {
              try {
                // Load all drivers for this collection
                const { data: choferesData } = await getChoferesRecoleccion(
                  null, null, null, 
                  initialData.id_recoleccion,
                  matchingClient.ID,
                  matchingAddress.DIRECCION
                );

                if (choferesData && choferesData.length > 0) {
                  // Find the principal driver
                  const principalDriverData = choferesData.find(d => d.TIPO_CHOFER === "PRINCIPAL");
                  const supportDriversData = choferesData.filter(d => d.TIPO_CHOFER === "APOYO");
                  
                  // Process principal driver
                  if (principalDriverData) {
                    const matchingDriver = choferes.find(c => c.COD_CHOFER === principalDriverData.COD_CHOFER);
                    
                    if (matchingDriver) {
                      setSelectedDriver(matchingDriver);
                      
                      // Load vehicles for principal driver
                      const vehicles = await getVehiculoAsignados(null, matchingDriver.COD_CHOFER);
                      setAsignedVehicles(vehicles || []);
                      
                      // Find and set the specific vehicle
                      const matchingVehicle = vehicles?.find(v => v.PATENTE === principalDriverData.PATENTE_VEHICULO);
                      if (matchingVehicle) {
                        setSelectedVehicle(matchingVehicle);
                      }

                      // Set principal time
                      if (principalDriverData.HORA_RECOLECCION) {
                        const horaFormateada = formatTimeWithRules(
                          String(principalDriverData.HORA_RECOLECCION).substring(0, 5)
                        );
                        setPrincipalTime(horaFormateada);
                      }
                    }
                  }

                  // Process support drivers
                  if (supportDriversData.length > 0) {
                    // Create slots for support drivers
                    const newSlots = supportDriversData.map((driver, index) => {
                      const chofer = choferes.find(c => c.COD_CHOFER === driver.COD_CHOFER);
                      const time = driver.HORA_APOYO || driver.HORA_RECOLECCION;
                      
                      return {
                        id: index + 1,
                        time: formatTimeWithRules(String(time).substring(0, 5)),
                        supports: chofer ? [chofer] : []
                      };
                    });

                    setSlots(newSlots);
                  }

                  // Store complete data
                  setStoredData(prev => ({
                    ...prev,
                    initialData: initialData,
                    id_recoleccion: initialData.id_recoleccion,
                    chofer: principalDriverData,
                    apoyo: supportDriversData,
                    isLoading: false
                  }));
                }
              } catch (error) {
                console.error('Error loading drivers:', error);
                setStoredData(prev => ({...prev, isLoading: false}));
              }
            }
          } else {
            console.warn('No matching address found for:', initialData.direccion);
            setStoredData(prev => ({...prev, isLoading: false}));
          }
          
          setIsLoadingAddressData(false);
        }, (error) => {
          console.error('Error loading addresses:', error);
          setRequestError(prev => ({...prev, asignedAdresses: error}));
          setStoredData(prev => ({...prev, isLoading: false}));
          setIsLoadingAddressData(false);
        });
      } else {
        console.warn('No matching client found for:', initialData.client || initialData.clientId);
        setStoredData(prev => ({...prev, isLoading: false}));
      }
      
      setIsLoadingInitialData(false);
    }
  }, [formMode, initialData, requiredData, choferes, clientes, driverManuallyChanged, isLoadingInitialData]);

  // Reset driverManuallyChanged when switching to insert mode
  useEffect(() => {
    if (formMode === 'insert') {
      setDriverManuallyChanged(false);
    }
  }, [formMode]);

  /**
   * @function handleTimeChange
   * @description Maneja el cambio de hora para un slot de retiro específico.
   * Aplica las reglas de formato de hora del negocio usando formatTimeWithRules.
   * 
   * @param {string} id - Identificador único del slot de tiempo
   * @param {Object} newTime - Nuevo valor de tiempo seleccionado (objeto dayjs)
   */
  const handleTimeChange = (id, newTime) => {
    const formattedTime = formatTimeWithRules(newTime.format("HH:mm"));
    setSlots((prevSlots) =>
      prevSlots.map((slot) =>
        slot.id === id ? { ...slot, time: formattedTime } : slot
      )
    );
  };

  useEffect(() => {
    /**
     * @function loadVehicles
     * @description Carga los vehículos asignados al chofer seleccionado.
     * Actualiza el estado de vehículos disponibles y maneja errores de carga.
     * 
     * @async
     * @returns {Promise<void>}
     */
    const loadVehicles = async () => {
    if (selectedDriver) {
        try {
          const vehicles = await getVehiculoAsignados(null, selectedDriver.COD_CHOFER);
          setAsignedVehicles(vehicles || []);
        } catch (error) {
          console.error("Error al cargar vehículos:", error);
          setRequestError(prev => ({...prev, asignedVehicles: "Error al cargar vehículos asignados"}));
          setAsignedVehicles([]);
        }
    } else {
        setAsignedVehicles([]);
      }
    };

    loadVehicles();

    // Manejar cambios del cliente por separado
    if (!selectedClient) {
      setStoredData({});
      setSelectedAddress(null);
      setAsignedVehicles([]);
    } else {
      getDireccionesCliente(selectedClient.ID, setAsignedAddresses, setRequestError);
    }
  }, [selectedDriver, selectedClient, storedData.chofer]);

  // useEffect to handle data fetching and processing
  useEffect(() => {
    // Skip data fetching if we're loading initialData or addresses
    if (isLoadingInitialData || isLoadingAddressData) {
      return;
    }
    
    // Flag to track if the component is still mounted when async operations complete
    let isMounted = true;
    
    /**
     * @function fetchData
     * @description Carga y procesa los datos de recolecciones pendientes para un cliente y dirección.
     * Gestiona la carga de choferes principales y de apoyo, sus vehículos asignados,
     * y actualiza el estado del formulario según los datos obtenidos.
     */
    const fetchData = async () => {
      // Only proceed if both client and address are selected
      if (!selectedClient || !selectedAddress) {
        if (isMounted) {
          setStoredData(prev => ({...prev, isLoading: false}));
          // No resetear slots aquí para mantener los cambios del usuario
        }
        return;
      }
      
      // Don't fetch data if we already have it loaded from initialData
      if (
        storedData.initialData && 
        storedData.id_recoleccion && 
        storedData.recoleccion && 
        storedData.recoleccion.length > 0
      ) {
        return;
      }
      
      try {
        // Set loading state
        if (isMounted) {
          setStoredData(prev => ({...prev, isLoading: true}));
        }
        
        // Fetch pending collections for the selected client and address
        const getRPC = await getRecoleccionesPendientesByCliente(
          null, 
          selectedClient.ID, 
          selectedAddress.DIRECCION
        );
      
        // Check if the client has multiple addresses
        const tieneMultiplesDirecciones = asignedAddresses.length > 1;
      
        // If there are pending collections
        if (getRPC && getRPC.length > 0 && isMounted) {
          const rceClient = getRPC[0];
        
          setFormMode('update');
          setClienteExistente(true);
        
          // Fetch drivers for this collection
          const { data } = await getChoferesRecoleccion(
            null, 
            null, 
            null, 
            rceClient.ID,
            selectedClient.ID,
            selectedAddress.DIRECCION
          );
        
          if (data && data.length > 0 && isMounted) {
            // Filter drivers for this specific collection
            
            const choferesDeEstaRecoleccion = data.filter(d => 
              d.ID_PICKUP === rceClient.ID
            );
          
            if (choferesDeEstaRecoleccion.length > 0) {
              // Separate principal and support drivers
              const principalDriver = choferesDeEstaRecoleccion.find(d => d.TIPO_CHOFER === "PRINCIPAL");
              const supportDrivers = choferesDeEstaRecoleccion.filter(d => d.TIPO_CHOFER === "APOYO");

              // Process principal driver if found
              if (principalDriver && isMounted) {
                const matchingDriver = choferes.find(c => c.COD_CHOFER === principalDriver.COD_CHOFER);
                
                // Only set driver if not manually changed
                if (matchingDriver && !driverManuallyChanged) {
                  setSelectedDriver(matchingDriver);
                
                  // Fetch and set vehicle for the principal driver
                  const vehicles = await getVehiculoAsignados(null, matchingDriver.COD_CHOFER);
                  
                  if (isMounted) {
                    setAsignedVehicles(vehicles || []);
                
                    // Find the specific vehicle that was assigned
                    const matchingVehicle = vehicles?.find(v => v.PATENTE === principalDriver.PATENTE_VEHICULO);
                    if (matchingVehicle) {
                      setSelectedVehicle(matchingVehicle);
                    }
                  }
                }

                // Update collection time if from database and not changed manually
                if (principalDriver.HORA_RECOLECCION && !principalTime && isMounted) {
                  const horaFormateada = formatTimeWithRules(
                    String(principalDriver.HORA_RECOLECCION).substring(0, 5)
                  );
                  setPrincipalTime(horaFormateada);
                }

                // Store collection and principal driver data
                if (isMounted) {
                  setStoredData(prev => ({ 
                    ...prev, 
                    chofer: principalDriver, 
                    recoleccion: [rceClient],
                    isLoading: false
                  }));
                }
              }

              // Process support drivers if found, but only if slots are empty
              // This prevents sobrescribir los datos cuando el usuario ya modificó los slots
              if (supportDrivers.length > 0 && isMounted && slots.length === 0) {
                // Group support drivers by time
                const newSlots = supportDrivers.reduce((acc, driver) => {
                  const time = driver.HORA_APOYO || driver.HORA_RECOLECCION;
                  if (!acc[time]) acc[time] = [];
                  acc[time].push(driver);
                  return acc;
                }, {});

                // Format and set slots with support drivers
                setSlots(Object.entries(newSlots).map(([time, drivers], index) => ({
                  id: index + 1,
                  time: time,
                  supports: drivers.map(d => {
                    const chofer = choferes.find(c => c.COD_CHOFER === d.COD_CHOFER);
                    return chofer || null;
                  }).filter(Boolean)
                })));
              
                // Store support drivers data
                setStoredData(prev => ({ 
                  ...prev, 
                  apoyo: supportDrivers, 
                  recoleccion: [rceClient],
                  isLoading: false
                }));
              } else if (isMounted) {
                // No support drivers found or user has already set slots
                setStoredData(prev => ({ ...prev, isLoading: false }));
              }
            } else if (isMounted) {
              // No drivers for this collection
              setStoredData(prev => ({ ...prev, isLoading: false }));
            }
          } else if (isMounted) {
            // No drivers data
            setStoredData(prev => ({ ...prev, isLoading: false }));
          }
        } else if (isMounted) {
          // Handle case when no pending collections are found
          if (formMode === 'update' && initialData) {
            // Keep update mode if coming from initialData
            setStoredData(prev => ({ ...prev, isLoading: false }));
          } else if (formMode === 'update' && clienteExistente) {
            // Keep update mode if client exists
            // Mark as new address if has multiple addresses
            setStoredData(prev => ({
              ...prev,
              isNewAddress: tieneMultiplesDirecciones,
              isLoading: false
            }));
          } else {
            // Reset to insert mode if not update with existing client
            setFormMode('insert');
            setClienteExistente(false);
            setStoredData(prev => ({...prev, isLoading: false}));
            // No resetear los slots aquí para mantener los cambios del usuario
          }
        }
      } catch (error) {
        console.error("Error fetching collection data:", error);
        if (isMounted) {
          setStoredData(prev => ({...prev, error, isLoading: false}));
          toast.error("Error al cargar datos de recolección: " + (error.message || "Error desconocido"));
        }
      }
    };
    
    // Execute data fetching
    fetchData();
    
    // Cleanup function to prevent state updates if component unmounts
    return () => {
      isMounted = false;
    };
  }, [selectedClient, selectedAddress, initialData, formMode, clienteExistente, asignedAddresses, principalTime, choferes, driverManuallyChanged, isLoadingInitialData, isLoadingAddressData, storedData.initialData, storedData.id_recoleccion, storedData.recoleccion, slots.length]);

  // Modificando este useEffect para no sobrescribir slots si ya existen
  const groupedByTime = useMemo(() => {
    if (slots.length === 0 && storedData.chofer && storedData.apoyo?.length > 0 && choferes.length > 0) {
      // Group support drivers by collection time
      return storedData.apoyo.reduce((acc, apoyo) => {
        // Skip drivers with TIPO_CHOFER = "APOYO"
        if (apoyo.TIPO_CHOFER === "APOYO") {
          return acc;
        }

        // Extract hours and minutes
        const timeStr = apoyo.HORA_RECOLECCION;
        const [hours, minutes] = timeStr.split(':');
        const formattedTime = `${hours}:${minutes}`;

        // Find matching chofer
        const chofer = choferes.find(c => c.COD_CHOFER === apoyo.COD_CHOFER);

        if (!acc[formattedTime]) {
          acc[formattedTime] = [];
        }

        if (chofer) {
          acc[formattedTime].push(chofer);
        }

        return acc;
      }, {});
    }
    return null;
  }, [storedData, choferes, slots.length]);

  // Separate useEffect to update slots based on groupedByTime
  useEffect(() => {
    if (groupedByTime && Object.keys(groupedByTime).length > 0 && slots.length === 0) {
      const newSlots = Object.entries(groupedByTime).map(([time, drivers], index) => ({
        id: index + 1,
        time: time,
        supports: drivers || []
      }));

      if (newSlots.length > 0) {
        setSlots(newSlots);
      }
    }
  }, [groupedByTime, slots.length]);

  // Reemplazo del useEffect de auto-selección para mantener solo la selección automática de direcciones
  useEffect(() => {
    // Solo autoseleccionar dirección en el caso inicial o cuando se cambia de cliente
    if (asignedAddresses.length > 0 && !selectedAddress && !clienteExistente) {
      setSelectedAddress(asignedAddresses[0]);
    }
  }, [asignedAddresses, selectedAddress, clienteExistente]); // Solo depender de asignedAddresses para evitar ciclos

  useEffect(() => {
    if (requestError.asignedVehicles) toast.warn(requestError.asignedVehicles)
    if (requestError.asignedAdresses) toast.warn(requestError.asignedAdresses)
  }, [requestError])

  /**
   * @function handleSubmit
   * @description Maneja el envío del formulario de retiro.
   * Realiza validaciones completas y procesa la creación o actualización
   * de retiros según el modo del formulario.
   * 
   * Características principales:
   * - Validación de campos requeridos
   * - Manejo de inserción y actualización
   * - Procesamiento de chofer principal y choferes de apoyo
   * - Gestión de nuevas direcciones para clientes existentes
   * 
   * @param {Event} e - Evento del formulario
   * @returns {Promise<void>}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Comprehensive validation
    if (!selectedClient) {
      toast.warn("Por favor seleccione un cliente.");
      return;
    }
    if (!selectedDriver) {
      toast.warn("Por favor seleccione un chofer.");
      return;
    }
    if (!selectedVehicle) {
      toast.warn("Por favor seleccione un vehículo para el chofer principal.");
      return;
    }
    if (asignedAddresses.length === 0) {
      toast.warn("Por favor seleccione al menos una dirección.");
      return;
    }
    if (!selectedAddress) {
      toast.warn("Por favor seleccione una dirección.");
      return;
    }

    // Determinar si debemos hacer insert aunque estemos en modo visual "update"
    const shouldForceInsert = formMode === 'update' && storedData.isNewAddress;

    if (formMode === 'update' && !shouldForceInsert) {
      try {
        // 1. Obtener recolección específica para esta dirección
        const recoleccionesCliente = await getRecoleccionesPendientesByCliente(
          null, 
          selectedClient.ID, 
          selectedAddress.DIRECCION
        );


        if (!recoleccionesCliente || recoleccionesCliente.length === 0) {
          
          // Cambiar a modo inserción y continuar el flujo
          const nuevaRecoleccion = await insertRecolectaPlanta({
            COD_CLIENTE: selectedClient.ID,
            DIRECCION: selectedAddress.DIRECCION
          });
          
          if (!nuevaRecoleccion || !nuevaRecoleccion.obj) {
            throw new Error("No se pudo crear una nueva recolección");
          }
          
          const idRecoleccion = nuevaRecoleccion.obj;
          
          // Insertar chofer principal
          const recChoferObj = {
            ID_RECOLECCION: idRecoleccion,
            COD_CHOFER: selectedDriver.COD_CHOFER,
            TIPO_CHOFER: "PRINCIPAL",
            PATENTE_VEHICULO: selectedVehicle.PATENTE,
            FH_RECOLECCION: formatearHoraATimestamp(principalTime)
          };
          
          const insertedChofer = await insertRecolectaPlantaChofer(recChoferObj);
          
          await insertRecoleccionPCE({
            ID_RECOLECCION: idRecoleccion,
            ID_RECOLECCION_CHOFER: insertedChofer.obj,
            EJECUTO_RECOLECCION: "PENDIENTE",
          });
          
          // Insertar choferes de apoyo si existen
          if (slots && slots.length > 0) {
            
            for (const slot of slots) {
              if (!slot.supports || slot.supports.length === 0) continue;
              
              const support = slot.supports[0];
              const horaNueva = formatTimeWithRules(String(slot.time).substring(0, 5));
              
              // Obtener vehículo del chofer
              const vehiculos = await getVehiculosService(true, support.COD_CHOFER, null, null);
              if (!vehiculos || vehiculos.length === 0) {
                console.error(`No se encontró vehículo para chofer ${support.NOMBRE}`);
                toast.error(`El chofer de apoyo ${support.NOMBRE} no tiene un vehículo asignado`);
                continue;
              }
              
              const recChoferApoyoObj = {
                ID_RECOLECCION: idRecoleccion,
                COD_CHOFER: support.COD_CHOFER,
                TIPO_CHOFER: "APOYO",
                PATENTE_VEHICULO: vehiculos[0].PATENTE,
                FH_RECOLECCION: formatearHoraATimestamp(horaNueva)
              };
              
              const insertedChoferApoyo = await insertRecolectaPlantaChofer(recChoferApoyoObj);
              
              // Agregar el registro en la tabla de ejecuciones para el nuevo chofer de apoyo
              await insertRecoleccionPCE({
                ID_RECOLECCION: idRecoleccion,
                ID_RECOLECCION_CHOFER: insertedChoferApoyo.obj,
                EJECUTO_RECOLECCION: "PENDIENTE",
              });
            }
          }
          
          toast.success("Nueva recolección creada correctamente");
          if (modalHandler && modalHandler.setIsOpen) {
            modalHandler.setIsOpen(false);
          }
          if (refetchData) {
            refetchData();
          }
          return;
        }
        
        const recoleccionActual = recoleccionesCliente[0];

        // 2. Obtener choferes específicos para esta recolección
        const { data: todosChoferes } = await getChoferesRecoleccion(
          null, 
          null, 
          null, 
          recoleccionActual.ID, // ID específico de la recolección
          selectedClient.ID,
          selectedAddress.DIRECCION // Asegurarse de pasar la dirección
        );

        
        // Filtrar solo los choferes que corresponden a esta recolección específica
        const choferesExistentes = todosChoferes.filter(d => 
          d.ID_RECOLECCION === recoleccionActual.ID_PICKUP
        );
        
        if (!choferesExistentes || choferesExistentes.length === 0) {
          console.error("No se encontraron choferes para esta recolección");
          toast.error("No se encontraron choferes para esta recolección");
          return;
        }



        

        // 3. Separar el chofer principal existente
        const choferPrincipalExistente = choferesExistentes.find(d => d.TIPO_CHOFER === "PRINCIPAL");

        // 4. Separar los choferes de apoyo existentes
        const choferesApoyoExistentes = choferesExistentes.filter(d => d.TIPO_CHOFER === "APOYO");

        // 5. ANÁLISIS DEL CHOFER PRINCIPAL
        if (choferPrincipalExistente) {
          // Verificar si cambió el chofer principal
          const cambioConductorPrincipal = selectedDriver.COD_CHOFER !== choferPrincipalExistente.COD_CHOFER;

          // Verificar si cambió el vehículo del chofer principal
          const cambioVehiculo = selectedVehicle.PATENTE !== choferPrincipalExistente.PATENTE_VEHICULO;

          // Verificar si solo cambió la hora del chofer principal
          const cambioHoraPrincipal = formatTimeWithRules(String(principalTime).substring(0, 5)) !==
            formatTimeWithRules(String(choferPrincipalExistente.HORA_RECOLECCION).substring(0, 5));

          // 6. ACTUALIZACIÓN DEL CHOFER PRINCIPAL
          if (cambioConductorPrincipal || cambioVehiculo) {
            // Si cambió el conductor o el vehículo, usamos updateChoferRecoleccion con tipo "chofer"
            // Código para actualizar:
            await updateChoferRecoleccion("chofer", {
              ID_RECOLECCION_CHOFER: choferPrincipalExistente.ID_RECOLECCION_CHOFER,
              COD_CHOFER: selectedDriver.COD_CHOFER,
              TIPO_CHOFER: "PRINCIPAL",
              PATENTE_VEHICULO: selectedVehicle.PATENTE
            });
          }
          else if (cambioHoraPrincipal) {
            // Si solo cambió la hora, usamos updateChoferRecoleccion con tipo "fh"
            // Código para actualizar:
            await updateChoferRecoleccion("fh", {
              ID_RECOLECCION_CHOFER: choferPrincipalExistente.ID_RECOLECCION_CHOFER,
              FH_RECOLECCION: formatearHoraATimestamp(formatTimeWithRules(String(principalTime).substring(0, 5)))
            });
          }
        } else {
          // Si no hay chofer principal existente, insertar uno nuevo
          
          const recChoferObj = {
            ID_RECOLECCION: recoleccionActual.ID,
            COD_CHOFER: selectedDriver.COD_CHOFER,
            TIPO_CHOFER: "PRINCIPAL",
            PATENTE_VEHICULO: selectedVehicle.PATENTE,
            FH_RECOLECCION: formatearHoraATimestamp(principalTime)
          };
          
          const insertedChofer = await insertRecolectaPlantaChofer(recChoferObj);
          
          await insertRecoleccionPCE({
            ID_RECOLECCION: recoleccionActual.ID,
            ID_RECOLECCION_CHOFER: insertedChofer.obj,
            EJECUTO_RECOLECCION: "PENDIENTE",
          });
        }

        // 7. ANÁLISIS DE CHOFERES DE APOYO

        // Mapeo de choferes de apoyo existentes por ID para facilitar la búsqueda
        const mapChofApoyoExistentes = new Map();
        choferesApoyoExistentes.forEach(chofer => {
          mapChofApoyoExistentes.set(chofer.COD_CHOFER, chofer);
        });

        // Conjunto para rastrear los choferes de apoyo que ya procesamos
        const choferesApoyoProcesados = new Set();

        // 8. PROCESAMIENTO DE CADA SLOT ACTUAL
        for (const slot of slots) {
          // Ignorar slots vacíos
          if (!slot.supports || slot.supports.length === 0) continue;
          let patente = null;


          const support = slot.supports[0]; // Chofer de apoyo en este slot
          const horaNueva = formatTimeWithRules(String(slot.time).substring(0, 5));

          // Verificar si este chofer ya existía
          if (mapChofApoyoExistentes.has(support.COD_CHOFER)) {
            // Este chofer de apoyo ya existía - posible actualización de hora
            const choferExistente = mapChofApoyoExistentes.get(support.COD_CHOFER);
            const horaExistente = formatTimeWithRules(
              String(choferExistente.HORA_APOYO || choferExistente.HORA_RECOLECCION).substring(0, 5)
            );
            // Verificar si la hora cambió
            if (horaNueva !== horaExistente) {

              // Código para actualizar la hora:
              await updateChoferRecoleccion("fh", {
                ID_RECOLECCION_CHOFER: choferExistente.ID_RECOLECCION_CHOFER,
                FH_RECOLECCION: formatearHoraATimestamp(horaNueva)
              });
            } 

            // Marcar como procesado
            choferesApoyoProcesados.add(support.COD_CHOFER);
          } else {
            // Este es un chofer de apoyo nuevo - Verificar si es un reemplazo en el mismo horario
            // o si es un nuevo chofer con nueva hora

            // Buscar si hay un chofer existente en la misma hora
            const choferExistenteEnMismaHora = choferesApoyoExistentes.find(chofer => {
              const horaChofer = formatTimeWithRules(
                String(chofer.HORA_APOYO || chofer.HORA_RECOLECCION).substring(0, 5)
              );
              return horaChofer === horaNueva;
            });

            if (choferExistenteEnMismaHora) {
              // Caso: Reemplazo de chofer en la misma hora (se mantiene la hora pero cambia el chofer)

              // Obtener vehículo del nuevo chofer
              patente = await getVehiculosService(true, support.COD_CHOFER, null, null);
              // Actualizar solo el chofer
              await updateChoferRecoleccion("chofer", {
                ID_RECOLECCION_CHOFER: choferExistenteEnMismaHora.ID_RECOLECCION_CHOFER,
                COD_CHOFER: support.COD_CHOFER,
                TIPO_CHOFER: "APOYO",
                PATENTE_VEHICULO: patente[0].PATENTE
              });

              // Marcar ambos como procesados
              choferesApoyoProcesados.add(choferExistenteEnMismaHora.COD_CHOFER);
              choferesApoyoProcesados.add(support.COD_CHOFER);
            } else {
              // Buscar si hay un chofer existente en el mismo índice de slot pero con hora diferente
              const indexActual = slots.findIndex(s => s.id === slot.id);
              const choferExistenteEnMismoSlot = choferesApoyoExistentes[indexActual];

              if (choferExistenteEnMismoSlot) {
                // Obtener vehículo del nuevo chofer
                patente = await getVehiculosService(true, support.COD_CHOFER, null, null);
                
                // Usar el tipo "both" para actualizar tanto chofer como hora
                await updateChoferRecoleccion("both", {
                  ID_RECOLECCION_CHOFER: choferExistenteEnMismoSlot.ID_RECOLECCION_CHOFER,
                  COD_CHOFER: support.COD_CHOFER,
                  TIPO_CHOFER: "APOYO",
                  PATENTE_VEHICULO: patente[0].PATENTE,
                  FH_RECOLECCION: formatearHoraATimestamp(horaNueva)
                });

                // Marcar ambos como procesados
                choferesApoyoProcesados.add(choferExistenteEnMismoSlot.COD_CHOFER);
                choferesApoyoProcesados.add(support.COD_CHOFER);
              } else {
                // Este es un chofer completamente nuevo - insertar

                // Obtener vehículo del chofer
                const vehiculo = await getVehiculoAsignados(false, support.COD_CHOFER, null, null);
                if (!vehiculo?.length) {
                  console.error(`No se encontró vehículo para chofer ${support.NOMBRE}`);
                  toast.error(`El chofer de apoyo ${support.NOMBRE} no tiene un vehículo asignado`);
                  continue;
                }

                patente = await getVehiculosService(true, support.COD_CHOFER, null, null);

                const insertedChofer = await insertRecolectaPlantaChofer({
                  ID_RECOLECCION: recoleccionActual.ID,
                  COD_CHOFER: support.COD_CHOFER,
                  TIPO_CHOFER: "APOYO",
                  PATENTE_VEHICULO: patente[0].PATENTE,
                  FH_RECOLECCION: formatearHoraATimestamp(horaNueva)
                });

                // Agregar el registro en la tabla de ejecuciones para el nuevo chofer de apoyo
                await insertRecoleccionPCE({
                  ID_RECOLECCION: recoleccionActual.ID,
                  ID_RECOLECCION_CHOFER: insertedChofer.obj,
                  EJECUTO_RECOLECCION: "PENDIENTE",
                });
              }
            }
          }
        }

        // 9. IDENTIFICAR Y ELIMINAR CHOFERES DE APOYO QUE YA NO ESTÁN EN LOS SLOTS
        for (const choferExistente of choferesApoyoExistentes) {
          if (!choferesApoyoProcesados.has(choferExistente.COD_CHOFER)) {

            // Código para eliminar chofer de apoyo:
            await deleteRecoleccionPCEChofer(
              choferExistente.HORA_APOYO || choferExistente.HORA_RECOLECCION,
              choferExistente.COD_CHOFER,
              selectedClient.ID
            );
          }
        }

       
        if (modalHandler && modalHandler.setIsOpen) {
           toast.success("Recolección actualizada correctamente");
          modalHandler.setIsOpen(false);
        }
        if (refetchData) {
          refetchData();
        }

      } catch (error) {
        console.error("Error en el proceso de actualización:", error);
        toast.error(`Error: ${error.message || "Ocurrió un error al actualizar la solicitud"}`);
      }
    } else {
      // Modo inserción (incluyendo el caso de dirección nueva en cliente existente)
      try {


        // 1. Verificar si ya existe una recolección para esta dirección específica
        const recoleccionesExistentes = await getRecoleccionesPendientesByCliente(
          null, 
          selectedClient.ID, 
          selectedAddress.DIRECCION
        );
        
        let idRecoleccion;
        
        // 2. Si no existe recolección para esta dirección, crear una nueva
        if (!recoleccionesExistentes || recoleccionesExistentes.length === 0) {
          
          const nuevaRecoleccion = await insertRecolectaPlanta({
            COD_CLIENTE: selectedClient.ID,
            DIRECCION: selectedAddress.DIRECCION
          });
          
          idRecoleccion = nuevaRecoleccion.obj;
        } else {
          // Si ya existe, usar la existente
          idRecoleccion = recoleccionesExistentes[0].ID;
        }
        
        if (!idRecoleccion) {
          throw new Error("No se pudo obtener o crear el ID de recolección");
        }
        
        // 3. Verificar si ya existe un chofer principal para esta recolección
        const { data: choferesExistentes } = await getChoferesRecoleccion(
          null, 
          idRecoleccion, // Usar el ID de recolección específico
          null, 
          null, 
          selectedClient.ID,
          selectedAddress.DIRECCION // Asegurarse de pasar la dirección
        );
        
        const choferPrincipalExistente = choferesExistentes.find(
          c => c.ID_RECOLECCION === idRecoleccion && c.TIPO_CHOFER === "PRINCIPAL"
        );

        
        // 4. Si no hay chofer principal, insertar el seleccionado como principal
        if (!choferPrincipalExistente) {
          
          const recChoferObj = {
            ID_RECOLECCION: idRecoleccion,
            COD_CHOFER: selectedDriver.COD_CHOFER,
            TIPO_CHOFER: "PRINCIPAL",
            PATENTE_VEHICULO: selectedVehicle.PATENTE,
            FH_RECOLECCION: formatearHoraATimestamp(principalTime)
          };
          
           

          const insertedChofer = await insertRecolectaPlantaChofer(recChoferObj);
          
          await insertRecoleccionPCE({
            ID_RECOLECCION: idRecoleccion,
            ID_RECOLECCION_CHOFER: insertedChofer.obj,
            EJECUTO_RECOLECCION: "PENDIENTE",
          });
        } else {
          // Si ya existe un chofer principal, mostrar error
          toast.error("Ya existe un chofer principal asignado a esta recolección");
          return;
        }
        
        // 5. Insertar choferes de apoyo si existen
        if (slots && slots.length > 0) {
          
          for (const slot of slots) {
            if (!slot.supports || slot.supports.length === 0) continue;
            
            const support = slot.supports[0];
            const horaNueva = formatTimeWithRules(String(slot.time).substring(0, 5));
            
            // Obtener vehículo del chofer
            const vehiculos = await getVehiculosService(true, support.COD_CHOFER, null, null);
            if (!vehiculos || vehiculos.length === 0) {
              console.error(`No se encontró vehículo para chofer ${support.NOMBRE}`);
              toast.error(`El chofer de apoyo ${support.NOMBRE} no tiene un vehículo asignado`);
              continue;
            }
            
            const recChoferApoyoObj = {
              ID_RECOLECCION: idRecoleccion,
              COD_CHOFER: support.COD_CHOFER,
              TIPO_CHOFER: "APOYO",
              PATENTE_VEHICULO: vehiculos[0].PATENTE,
              FH_RECOLECCION: formatearHoraATimestamp(horaNueva)
            };
            
            const insertedChoferApoyo = await insertRecolectaPlantaChofer(recChoferApoyoObj);
            
            // Agregar el registro en la tabla de ejecuciones para el nuevo chofer de apoyo
            await insertRecoleccionPCE({
              ID_RECOLECCION: idRecoleccion,
              ID_RECOLECCION_CHOFER: insertedChoferApoyo.obj,
              EJECUTO_RECOLECCION: "PENDIENTE",
            });
          }
        }
        
        // Después de una inserción exitosa en una nueva dirección de cliente existente
        if (shouldForceInsert) {
          // Mantener el modo update visual pero actualizar el estado
          setStoredData(prev => ({ ...prev, isNewAddress: false }));
          setClienteExistente(true);
          // No resetear el formulario
        }

        toast.success(shouldForceInsert ? 
          "Nueva recolección creada para dirección adicional" : 
          "Recolección creada correctamente"
        );
        
        if (modalHandler && modalHandler.setIsOpen) {
          modalHandler.setIsOpen(false);
        }
        if (refetchData) {
          refetchData();
        }
        
      } catch (error) {
        console.error("Error en el proceso de inserción:", error);
        toast.error(`Error: ${error.message || "Ocurrió un error al crear la solicitud"}`);
      }
    }
  };

  /**
   * @function handleRemoveSlot
   * @description Elimina un slot de tiempo específico y su chofer de apoyo asociado.
   * Realiza la eliminación tanto en la interfaz como en la base de datos.
   * 
   * @param {Object} slotId - Objeto con información del slot a eliminar
   * @param {number} slotId.id - Identificador único del slot
   * @param {string} slotId.time - Hora del slot
   * @param {Array} slotId.supports - Choferes de apoyo asociados al slot
   * @returns {Promise<void>}
   */
  const handleRemoveSlot = async (slotId) => {

    try {
      for (const slot of slots) {
        if (slot.id === slotId.id) {
          await deleteRecoleccionPCEChofer(slotId.time, slotId.supports[0].COD_CHOFER, selectedClient.ID);
          setSlots(prev => prev.filter(s => s.id !== slotId.id));
        }
      }

      // Volver a cargar los datos después de la eliminación
      setSlots(prev => prev.filter(s => s.id !== slotId.id));

    } catch (error) {
      console.error("Error al eliminar slot:", error);
      toast.error("Error al eliminar el chofer de apoyo");
    }
  };



  /**
   * @function handleClientChange
   * @description Maneja el cambio de cliente seleccionado.
   * Limpia los datos relacionados cuando se cambia de cliente.
   * 
   * @param {Object} newClient - Nuevo cliente seleccionado
   */
  const handleClientChange = (newClient) => {
    // Limpiar datos si cambia el cliente o se deselecciona
    if (selectedClient !== newClient) {
      // Ya no limpiamos el vehículo aquí
      setSelectedAddress(null);
      setAsignedVehicles([]);
    }

    // Actualizar el cliente seleccionado
    setSelectedClient(newClient);
  };

  // 2. Agregar la función para marcar como completado antes de handleSubmit
  const handleMarkAsComplete = async (slot) => {
    
    try {
      if (!slot.supports || slot.supports.length === 0 || !selectedClient) {
        toast.warn("No se puede completar: información insuficiente");
        return;
      }
      
      // Obtener los datos necesarios
      let idRecoleccionChofer;
      let idRecoleccion;
      
      // Buscar la información en storedData.recoleccion y storedData.apoyo
      if (storedData.recoleccion && storedData.recoleccion.length > 0) {
        // Obtener ID_RECOLECCION
        idRecoleccion = storedData.recoleccion[0].ID;
        
        // Si tenemos datos de apoyo, buscar el chofer correspondiente
        if (storedData.apoyo && Array.isArray(storedData.apoyo)) {
          // Buscar coincidencia por COD_CHOFER y hora similar
          const choferApoyo = storedData.apoyo.find(apoyo => 
            apoyo.COD_CHOFER === slot.supports[0].COD_CHOFER && 
            formatTimeWithRules(String(apoyo.HORA_RECOLECCION).substring(0, 5)) === formatTimeWithRules(slot.time)
          );
          
          if (choferApoyo) {
            idRecoleccionChofer = choferApoyo.ID_RECOLECCION_CHOFER;
          }
        }
        
        // Si no encontramos el ID en apoyo, intentar obtenerlo directamente
        if (!idRecoleccionChofer) {
          // Buscar en la data completa de recolección
          const recoleccion = await getChoferesRecoleccion(null, null, null, null, selectedClient.ID, selectedAddress.DIRECCION);
          const choferData = recoleccion.data.find(d => 
            d.COD_CHOFER === slot.supports[0].COD_CHOFER && 
            formatTimeWithRules(String(d.HORA_RECOLECCION).substring(0, 5)) === formatTimeWithRules(slot.time)
          );
          
          if (choferData) {
            idRecoleccionChofer = choferData.ID_RECOLECCION_CHOFER;
          }
        }
      }
      
      // Validar que tenemos los datos necesarios
      if (!idRecoleccion || !idRecoleccionChofer) {
        console.error("No se encontraron los IDs necesarios", {
          idRecoleccion,
          idRecoleccionChofer
        });
        toast.error("No se pudieron obtener los datos necesarios para completar");
        return;
      }

      try {
        const checkResult = await getRecoleccionesPCEjecutadas(idRecoleccion, idRecoleccionChofer)
        if (checkResult.length > 0) {
          // Si existe, actualizamos el registro existente
          const ejecucionId = checkResult[checkResult.length - 1].ID;
          const updateResult = await updateRecoleccionEjecucionStatus(ejecucionId,idRecoleccion, idRecoleccionChofer, "COMPLETADO")
          if (updateResult.data) {
            toast.success(`Pick up marcado como completado correctamente`);
            handleUpdateUI(slot);
          } else {
            toast.error(`Error al actualizar: ${updateResult.message || "Error desconocido"}`);
          }
        } else {
          const insertResult = await insertRecoleccionPCE({
            ID_RECOLECCION: idRecoleccion,
            ID_RECOLECCION_CHOFER: idRecoleccionChofer,
            EJECUTO_RECOLECCION: "COMPLETADO"
          })
          
          if (insertResult.data) {
            toast.success(`Pick up marcado como completado correctamente`);
            handleUpdateUI(slot);
          } else {
            toast.error(`Error al insertar: ${insertResult.message || "Error desconocido"}`);
          }
        }
      } catch (error) {
        console.error("Error al verificar estado actual:", error);
      }
    } catch (error) {
      console.error("Error al marcar como completado:", error);
      toast.error(`Error al marcar el pick up como completado: ${error.message || "Error desconocido"}`);
    }
    
    /**
     * @function handleUpdateUI
     * @description Actualiza la interfaz después de marcar un retiro como completado.
     * Proporciona feedback visual y elimina el slot después de un breve retraso.
     * 
     * @param {Object} slot - Slot de tiempo que se marcó como completado
     */
    function handleUpdateUI(slot) {
      // Refrescar los datos si es necesario
      if (refetchData) {
        refetchData();
      }
      
      
      // Primero, marcar el slot como completado para feedback visual
      setSlots(prev => prev.map(s => 
        s.id === slot.id ? { ...s, isCompleted: true } : s
      ));
      
      // Después de un breve retraso, eliminar el slot de la lista
      setTimeout(() => {
        setSlots(prev => prev.filter(s => s.id !== slot.id));
      }, 1500); // 1.5 segundos de retraso para que el usuario vea la confirmación
    }
  };

  /**
   * @function handleMarkAsCompletePrincipal
   * @description Marca el retiro del chofer principal como completado.
   * Gestiona la actualización del estado en la base de datos y la interfaz.
   * 
   * Características principales:
   * - Verifica la existencia de registros de ejecución
   * - Maneja la creación o actualización del estado
   * - Actualiza la interfaz tras completar el retiro
   * - Manejo específico para el chofer principal
   * 
   * @returns {Promise<void>}
   */
  const handleMarkAsCompletePrincipal = async () => {
    
    try {
      if (!selectedDriver || !selectedClient) {
        toast.warn("No se puede completar: información insuficiente");
        return;
      }
      
      // Obtener los datos necesarios
      let idRecoleccionChofer;
      let idRecoleccion;
      
      // Buscar la información en storedData.recoleccion y storedData.chofer
      if (storedData.recoleccion && storedData.recoleccion.length > 0) {
        // Obtener ID_RECOLECCION
        idRecoleccion = storedData.recoleccion[0].ID;

        // Si tenemos datos del chofer principal
        if (storedData.chofer) {
          idRecoleccionChofer = storedData.chofer.ID_RECOLECCION_CHOFER;
        }
        
        // Si no encontramos el ID en storedData.chofer, intentar obtenerlo directamente
        if (!idRecoleccionChofer) {
          // Buscar en la data completa de recolección
          const recoleccion = await getChoferesRecoleccion(null, null, null, null, selectedClient.ID, selectedAddress.DIRECCION);
          const choferData = recoleccion.data.find(d => 
            d.COD_CHOFER === selectedDriver.COD_CHOFER && 
            d.TIPO_CHOFER === "PRINCIPAL"
          );
          
          if (choferData) {
            idRecoleccionChofer = choferData.ID_RECOLECCION_CHOFER;
          }
        }
      }
      
      // Validar que tenemos los datos necesarios
      if (!idRecoleccion || !idRecoleccionChofer) {
        console.error("No se encontraron los IDs necesarios para el chofer principal", {
          idRecoleccion,
          idRecoleccionChofer
        });
        toast.error("No se pudieron obtener los datos necesarios para completar");
        return;
      }
      
      // Primero consultamos si existe un registro de ejecución para este retiro
      try {
        
        const checkResult = await getRecoleccionesPCEjecutadas(idRecoleccion, idRecoleccionChofer)
        
        if (checkResult.length > 0) {
          // Si existe, actualizamos el registro existente
          const ejecucionId = checkResult[0].ID;
          
          const updateResult = await updateRecoleccionEjecucionStatus(ejecucionId,idRecoleccion, idRecoleccionChofer, "COMPLETADO")
          if (updateResult.data) {
            toast.success(`Pick up del chofer principal marcado como completado correctamente`);
            handleUpdateUIPrincipal();
          } else {
            toast.error(`Error al actualizar: ${updateResult.message || "Error desconocido"}`);
          }
        } else {
          // Si no existe, lo creamos - creamos una inserción nueva
          const insertResult = await insertRecoleccionPCE({
            ID_RECOLECCION: idRecoleccion,
            ID_RECOLECCION_CHOFER: idRecoleccionChofer,
            EJECUTO_RECOLECCION: "COMPLETADO"
          })
          if (insertResult.data) {
            toast.success(`Pick up del chofer principal marcado como completado correctamente`);
            handleUpdateUIPrincipal();
          } else {
            toast.error(`Error al insertar: ${insertResult.message || "Error desconocido"}`);
          }
        }
      } catch (error) {
        console.error("Error al verificar estado actual del chofer principal:", error);
      }
    } catch (error) {
      console.error("Error al marcar como completado al chofer principal:", error);
      toast.error(`Error al marcar el pick up como completado: ${error.message || "Error desconocido"}`);
    }
    
    /**
     * @function handleUpdateUIPrincipal
     * @description Actualiza la interfaz después de marcar el retiro del chofer principal como completado.
     * Refresca los datos y proporciona feedback visual.
     */
    function handleUpdateUIPrincipal() {
      // Refrescar los datos si es necesario
      if (refetchData) {
        refetchData();
      }
      
      // Cerrar el modal si existe
      if (modalHandler && modalHandler.setIsOpen) {
        modalHandler.setIsOpen(false);
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginBottom: 2 }}>
      <Box elevation={3} sx={{ 
        p: 2, 
        mt: 2,
      }}>
        <Typography variant="h5" component="h1" gutterBottom sx={{ mb: 2, fontWeight: 'bold', color: colors.blue }}>
          Pick up 's pendientes a retiro
        </Typography>

        {/* Mode Indicator */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            p: 0.75,
            borderRadius: 1,
            backgroundColor: formMode === 'update' ? `${colors.blue}10` : `${colors.green}10`,
            border: `1px solid ${formMode === 'update' ? colors.blue : colors.green}`,
          }}
        >
          {formMode === 'update' ? (
            <EditIcon sx={{ color: colors.blue, mr: 1, fontSize: '1.2rem' }} />
          ) : (
            <AddIcon sx={{ color: colors.green, mr: 1, fontSize: '1.2rem' }} />
          )}
          <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 'medium',
              color: formMode === 'update' ? colors.blue : colors.green
            }}
          >
            {formMode === 'update'
              ? selectedClient
                ? `Actualizando pick up para ${selectedClient.NOMBRE}${selectedAddress ? ` - ${selectedAddress.DIRECCION}` : ''}`
                : initialData?.client
                  ? `Actualizando pick up para ${initialData.client}${initialData.direccion ? ` - ${initialData.direccion}` : ''}`
                  : 'Actualizando pick up'
              : 'Registrando nueva pick up pendiente'
            }
          </Typography>
            
            {/* Add detailed info for edit mode */}
            {formMode === 'update' && initialData && (
              <Typography 
                variant="caption" 
                sx={{ 
                  display: 'block', 
                  color: 'text.secondary',
                  mt: 0.5
                }}
              >
                {initialData.driver && `Conductor: ${initialData.driver}`}
                {initialData.vehicle && ` • Vehículo: ${initialData.vehicle}`}
                {initialData.hora && ` • Hora: ${initialData.hora}`}
                {initialData.id_recoleccion && ` • ID Pick Up: ${initialData.id_recoleccion}`}
                {initialData.id_recoleccion_chofer && ` • ID Chofer: ${initialData.id_recoleccion_chofer}`}
              </Typography>
            )}
        </Box>
          
          {/* Display loading indicator when needed */}
          {formMode === 'update' && initialData && (!selectedClient || !selectedAddress || !selectedDriver || !selectedVehicle) && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={16} sx={{ mr: 1 }} />
              <Typography variant="caption" color="text.secondary">
                Cargando datos...
              </Typography>
            </Box>
          )}
        </Box>


        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Left Column - Client Information */}
            <Grid item xs={12} md={6}>
              <Box sx={{
                p: 1.5,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                height: '100%'
              }}>
                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: colors.blue }}>
                  <BusinessIcon sx={{ color: colors.blue, fontSize: '1.2rem' }} />
                  Información cliente
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <ClientesAutocompleter
                    clientes={clientes}
                    selectedClient={selectedClient}
                    setSelectedClient={handleClientChange}
                    setAsignedAddresses={setAsignedAddresses}
                    retirosAgregados={retirosAgregados}
                    setClienteExistente={setClienteExistente}
                  />
                  <AddressAutoCompleter
                    asignedAddresses={asignedAddresses}
                    setSelectedAddress={setSelectedAddress}
                    storedData={storedData}
                    value={selectedAddress}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Right Column - Driver Information */}
            <Grid item xs={12} md={6}>
              <Box sx={{
                p: 1.5,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                height: '100%'
              }}>
                <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: colors.blue }}>
                  <PersonIcon sx={{ color: colors.blue, fontSize: '1.2rem' }} />
                  Información conductor
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <ConductorAutocompelter
                    choferes={choferes}
                    setAsignedVehicles={setAsignedVehicles}
                    setSelectedDriver={(driver) => {
                      setDriverManuallyChanged(true);
                      setSelectedDriver(driver);
                    }}
                    storedData={storedData}
                    value={selectedDriver}
                    setSelectedVehicle={setSelectedVehicle}
                    selectedClient={selectedClient}
                  />

                  <AsignedVehicleAutocompleter
                    asignedVehicles={asignedVehicles}
                    setSelectedVehicle={setSelectedVehicle}
                    value={selectedVehicle}
                    selectedDriver={selectedDriver}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          {/* Time Slots Section */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider',
              p: 1.5,
            }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1.5
              }}>
                <Typography variant="subtitle1" component="h2" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  color: colors.blue
                }}>
                  <MoreTimeIcon sx={{ color: colors.blue, fontSize: '1.2rem' }} />
                  Asignación de horas para retirar
                </Typography>

                
              </Box>

              {/* Principal Driver Time Slot */}
              {selectedDriver && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                    mb: 1.5,
                    p: 1.5,
                    backgroundColor: 'rgba(4, 21, 98, 0.05)',
                    borderRadius: 1,
                    position: 'relative',
                  }}
                >
                  <AlarmIcon sx={{ color: colors.blue, fontSize: '1.2rem' }} />
                  <MobileTimePicker
                    value={dayjs(principalTime, "HH:mm")}
                    ampm={false}
                    onChange={(newValue) => {
                      const formattedTime = formatTimeWithRules(newValue.format("HH:mm"));
                      setPrincipalTime(formattedTime);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ flex: 1 }}
                        label="Hora chofer principal"
                        size="small"
                      />
                    )}
                  />
                  <Chip
                    label={selectedDriver.NOMBRE}
                    color="primary"
                    size="small"
                    sx={{
                      backgroundColor: colors.blue,
                      color: 'white',
                      '& .MuiChip-label': {
                        fontWeight: 500,
                      }
                    }}
                  />
                  
                  <IconButton
                    onClick={() => handleMarkAsCompletePrincipal()}
                    sx={{
                      position: 'absolute',
                      right: -8,
                      top: -8,
                      backgroundColor: colors.green,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: `${colors.green}dd`,
                      },
                      width: 20,
                      height: 20,
                      '& .MuiSvgIcon-root': {
                        fontSize: '0.75rem',
                      },
                    }}
                    title="Marcar como completado"
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </Box>
              )}

              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gap: 1.5,
                }}
              >
                {/* Support Drivers Time Slots */}
                {slots.map((slot, idx) => (
                  <Box
                    key={slot.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 1.5,
                      p: 1.5,
                      backgroundColor: slot.isCompleted ? '#e8f5e9' : 'background.paper',
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: slot.isCompleted ? colors.green : 'divider',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ flex: 1, display: 'flex', gap: 1.5 }}>
                      <AlarmIcon sx={{ color: colors.blue, fontSize: '1.2rem' }} />
                      <MobileTimePicker
                        value={dayjs(slot.time, "HH:mm")}
                        ampm={false}
                        onChange={(newValue) => handleTimeChange(slot.id, newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ flex: 1 }}
                            label="Hora chofer de apoyo"
                            size="small"
                          />
                        )}
                      />

                      {selectedDriver && (
                        <Autocomplete
                          fullWidth
                          size="small"
                          value={slot.supports.length > 0 ? slot.supports[0] : null}
                          options={choferes.filter(c => {
                            // Remove the filtering logic that excludes principal driver and already assigned drivers
                            return true;
                          })}
                          getOptionLabel={(option) =>
                            option.RUT ? `${option.NOMBRE} / ${option.RUT}` : `${option.NOMBRE}`
                          }
                          onChange={(_, newValue) => {
                            setSlots((prev) => {
                              const updatedSlots = prev.map((elm) => {
                                if (elm.id === slot.id) {
                                  return {
                                    ...elm,
                                    supports: newValue ? [newValue] : [],
                                  };
                                }
                                return elm;
                              });
                              return updatedSlots;
                            });
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PersonIcon sx={{ color: colors.blue, mr: 1, fontSize: '1rem' }} />
                                <Box>
                                  <Typography variant="body2">{option.NOMBRE}</Typography>
                                  {option.RUT && (
                                    <Typography variant="caption" color="text.secondary">
                                      {option.RUT}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField 
                              {...params} 
                              label="Chofer de apoyo" 
                              variant="outlined"
                              error={slot.supports.length === 0}
                              helperText={slot.supports.length === 0 ? "Por favor seleccione un chofer" : ""}
                            />
                          )}
                        />
                      )}
                    </Box>
                    
                    <Box sx={{ position: 'absolute', right: -8, top: -8, display: 'flex', gap: 1 }}>
                      
                      <IconButton
                        onClick={() => handleMarkAsComplete(slot)}
                        sx={{
                          backgroundColor: colors.green,
                          color: 'white',
                          '&:hover': {
                            backgroundColor: `${colors.green}dd`,
                          },
                          width: 20,
                          height: 20,
                          '& .MuiSvgIcon-root': {
                            fontSize: '0.75rem',
                          },
                        }}
                        title="Marcar como completado"
                      >
                        <CheckCircleIcon />
                      </IconButton>
                      
                      <IconButton
                        onClick={() => handleRemoveSlot(slot)}
                        sx={{
                          backgroundColor: colors.red,
                          color: 'white',
                          '&:hover': {
                            backgroundColor: `${colors.red}dd`,
                          },
                          width: 20,
                          height: 20,
                          '& .MuiSvgIcon-root': {
                            fontSize: '0.75rem',
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    
                    {slot.isCompleted && (
                      <Chip 
                        size="small"
                        label="Completado"
                        sx={{ 
                          position: 'absolute',
                          right: 8,
                          bottom: 8,
                          height: 16, 
                          fontSize: '0.6rem',
                          backgroundColor: colors.green,
                          color: 'white'
                        }}
                      />
                    )}
                  </Box>
                ))}
              </Box>

              <Button
                endIcon={<AddIcon />}
                onClick={() => setSlots(prev => [...prev, {
                  id: prev.length + 1,
                  time: dayjs().format("HH:mm"),
                  supports: []
                }])}
                variant="outlined"
                size="small"
                sx={{
                  mt: 1.5,
                  color: colors.blue,
                  borderColor: colors.blue,
                  "&:hover": {
                    borderColor: colors.blue[50],
                    backgroundColor: colors.blue,
                    color: "white"
                  },
                }}
              >
                Agregar chofer de apoyo
              </Button>
            </Box>
          </LocalizationProvider>

          <Divider sx={{ my: 2 }} />

          {selectedClient && selectedDriver && selectedAddress && selectedVehicle && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {formMode === 'update' && (
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    modalHandler.setIsOpen(false);
                  }}
                  sx={{
                    borderColor: colors.red,
                    color: colors.red,
                    "&:hover": {
                      backgroundColor: `${colors.red}10`,
                      borderColor: colors.red
                    },
                  }}
                >
                  Cancelar
                </Button>
              )}

              <Button
                type="submit"
                variant="contained"
                size="medium"
                sx={{
                  backgroundColor: formMode === 'update' ? colors.blue : colors.green,
                  color: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: formMode === 'update' ? colors.blue : colors.green,
                    borderColor: formMode === 'update' ? colors.blue : colors.green,
                  },
                }}
                startIcon={formMode === 'update' ? <EditIcon /> : <SaveIcon />}
              >
                {formMode === 'update' ? "Actualizar" : "Guardar"}
              </Button>
            </Box>
          )}
        </form>
      </Box>
    </Container>
  );
};


export default RetiroForm
