import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Grid, TextField, Typography, CircularProgress, Paper, Autocomplete, Box } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import useSound from "use-sound";
import sonido1 from "../../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/AlertaMa.mp3";
import {
  insertPickingTemp,
  deletePickingTemp,
  buscarDatosPickingTempManifiestoEntradaAgente,
  buscarManifiestosEntAgentesManifiestoEntradaAgenteRef,
  downloadManifestPdfGlobal,
  obtenerManifiestoOrden,
  createManifiestoAndPicking,
} from "../../../../services/ManifiestosServices";
import { getOrdenIdReferencia } from "../../../../services/ManifiestosServices";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import Mail_Gestor from "../../ComponentesManifiestoEntradaAgente/Mail";
import TablaManifiestosAgentes from "../TablaManifiestosAgentes";
import DeleteIcon from "@mui/icons-material/Delete";

function EntradaAgenteCB() {
  const inputRef = useRef(null);

  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const iataUser = userInfo ? userInfo.iata_agentes : null;
  const ciudadUser = userInfo ? userInfo.nombre_ciudad_agentes : null;
  const nombreUser = userInfo ? userInfo.name : null;
  const correoUser = userInfo ? userInfo.email : null;
  const agenteAsignado = userInfo ? userInfo.AGENTE_ASIGNADO : null;
  const [proceso, setProceso] = useState("");
  const [fechaActual, setfechaActual] = useState("");
  const [nota, setNota] = useState("");
  const [idChofer, setIdChofer] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [totalOrdenes, setTotalOrdenes] = useState(0);
  const [ordenesDataTablaM, setOrdenesDataTablaM] = useState([]);
  const [ordenesPendientes, setOrdenesPendientes] = useState();
  const [loading, setLoading] = React.useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(false);
  const [loadingEliminar, setLoadingEliminar] = React.useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("NOVOFARMA SERVICE S.A.");
  const [idCliente, setIdCliente] = useState("");

  const actualizarPendientes = useCallback(
    async (id) => {
      const actualizar = async () => {
        try {
          const dataManif = await buscarManifiestosEntAgentesManifiestoEntradaAgenteRef(nombreUser, id);
          setLoading(false);
          setIsbtnEnviar(false);
          setOrdenesDataTablaM(dataManif);
          setOrdenesPendientes(dataManif.length);
          timeoutRef.current = setTimeout(actualizar, 1 * 60 * 1000);
        } catch (error) {
          console.error("Error al actualizar los datos pendientes de salida:", error);
        }
      };
      actualizar();
    },
    [nombreUser],
  );

  useEffect(() => {
    const fetchData = async () => {
      setProceso("ENTRADAAGENTE");

      try {
        const fechaActual = moment().format("YYYY-MM-DD");
        setfechaActual(fechaActual);
        const proceso = "ENTRADAAGENTE";

        const clientesData = [{ label: "NOVOFARMA SERVICE S.A.", value: 632 }];
        setSelectedCliente(clientesData[0].label);
        setIdCliente(clientesData[0].value);
        setClientes(clientesData);
        actualizarPendientes(clientesData[0].value);

        const datos = {
          COD_USUARIO: id_user,
          PROCESO: proceso,
          COMUNA: iataUser,
          COD_CLIENTE: 632,
        };

        console.log("datos", datos);

        const dataPickingTemp = await buscarDatosPickingTempManifiestoEntradaAgente(datos);

        let totalBultosTemp = 0;
        if (dataPickingTemp && dataPickingTemp.results) {
          dataPickingTemp.results.forEach((orden) => {
            if (orden.TIPO_ORDEN !== "RETIRO") {
              totalBultosTemp += orden.BULTOS;
            }
          });
          setTotalOrdenes(dataPickingTemp.results.length);
        }

        setTotalBultos(totalBultosTemp);

        if (dataPickingTemp && dataPickingTemp.results) {
          setOrdenesDataTabla(dataPickingTemp.results);
        } else {
          setOrdenesDataTabla([]);
        }
      } catch {}
    };
    fetchData();
    // eslint-disable-next-line no-use-before-define
  }, [actualizarPendientes, ciudadUser, iataUser, idCliente, id_user, userInfo]);

  const timeoutRef = useRef();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("es-ES", options);
  };

  const columns = [
    { field: "COD_BARRA", headerName: "COD. BARRA", width: 100 },
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 250 },
    { field: "BULTOS", headerName: "Bultos", width: 70 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 100 },
    { field: "NOMBRE_DESTINO", headerName: "Comuna", width: 100 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleDelete(params.row.OD)} />
      ),
    },
  ];

  const columns2 = [
    {
      field: "FH_CREACION",
      headerName: "FECHA",
      width: 130,
      valueGetter: (params) => formatDate(params.row.FH_CREACION),
    },
    { field: "COD_BARRA", headerName: "COD. BARRA", width: 130 },
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "CLIENTE", width: 250 },
    { field: "BULTOS", headerName: "BULTOS", width: 100 },
    { field: "DIRECCION", headerName: "DIRECCION", width: 200 },
    { field: "TIPO_ORDEN", headerName: "TIPO", width: 100 },
    { field: "TIPO_RUTA", headerName: "VIA", width: 100 },
  ];
  /* borrar ordenes de la tabla y también de la base de datos */
  const handleDelete = async (orden) => {
    setLoadingEliminar(true);
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deletePickingTemp({
          OD: orden,
          COD_USUARIO: id_user,
          PROCESO: proceso,
        });

        if (response.message === "PickingTemp Eliminado con éxito") {
          const datos = {
            COD_USUARIO: id_user,
            PROCESO: proceso,
            COMUNA: iataUser,
            COD_CLIENTE: idCliente,
          };
          try {
            const resultados = await buscarDatosPickingTempManifiestoEntradaAgente(datos);

            let totalBultosTemp = 0;
            if (resultados && resultados.results) {
              resultados.results.forEach((orden) => {
                if (orden.TIPO_ORDEN !== "RETIRO") {
                  totalBultosTemp += orden.BULTOS;
                }
              });
              setTotalOrdenes(resultados.results.length);
            }

            setTotalBultos(totalBultosTemp);

            if (resultados && Array.isArray(resultados.results)) {
              setOrdenesDataTabla(resultados.results);
            } else {
              setOrdenesDataTabla([]);
            }
          } catch (error) {
            console.error("Error al buscar datos de picking temp:", error);
            setOrdenesDataTabla([]);
          }

          Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
        } else {
          Swal.fire("No se pudo eliminar", response.message, "error");
        }
      } catch (error) {
        console.error("Error al eliminar el picking temp:", error);
        Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
      } finally {
        setLoadingEliminar(false);
      }
    }
    setLoadingEliminar(false);
  };

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      const options = {
        timeZone: "America/Santiago",
        hour12: false,
      };

      const fecha = fechaActual.toLocaleDateString("es-CL", options);

      const [dia, mes, año] = fecha.split("-");

      const nuevaFecha = `${año}-${mes}-${dia}`;

      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options,
        hour12: false,
      });

      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null;
    }
  };

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
    playsound();
    inputRef.current.value = "";
  };

  const resetState = () => {
    setNota("");
    inputRef.current.value = "";
    setOrdenesDataTabla([]);
    setTotalBultos(0);
    setIdChofer("");
    const fechaActual = moment().format("YYYY-MM-DD");
    setfechaActual(fechaActual);
    setTotalOrdenes(0);
    setLoading(false);
    setIsbtnEnviar(false);
  };

  const handleChangeClientes = async (event, newValue) => {
    try {
      setSelectedCliente(newValue);
      console.log("Hola", newValue);
      setIdCliente(newValue.value);
      actualizarPendientes(newValue.value);
    } catch {}
  };

  const handleChangeNota = (event) => {
    setNota(event.target.value);
  };

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {
        try {
          await insertPickingTemp({
            OD: dataOrden[0].OD,
            COD_USUARIO: id_user,
            COD_CHOFER: id_user,
            COMUNA: iataUser,
            PROCESO: proceso,
            AGENTE: agenteAsignado,
            COD_CLIENTE: idCliente,
          });

          setOrdenesDataTabla([...ordenesDataTabla, dataOrden[0]]);
          if (dataOrden.TIPO_ORDEN !== "RETIRO") {
            setTotalBultos(totalBultos + dataOrden[0].BULTOS);
          }

          setTotalOrdenes(totalOrdenes + 1);
        } catch (error) {
          console.error("Error:", error);
        }
        inputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [playsound] = useSound(sonido1);

  const ordenIngresada = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();

      try {
        const datos = {
          COD_CLIENTE: idCliente,
          COD_BARRA: ordenValue,
        };
        const dataOrden = await getOrdenIdReferencia(datos);
        console.log("dataOrden", dataOrden);

        // Si la orden no existe, mostrar error y salir
        if (!dataOrden || dataOrden.length === 0) {
          showError("No se encontró orden con el código entregado para el cliente seleccionado!!!");
          playsound();
          inputRef.current.value = "";
          return;
        }

        if ((dataOrden[0].AGENTE_ASIGNADO || dataOrden[0].AGENTE_ASIGNADO_INICIAL) !== agenteAsignado) {
          showError("Orden No pertenece a su agencia.");
          return;
        }

        // Si la orden ya está en la tabla, mostrar error y salir
        const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);
        if (ordenExistente) {
          showError("Orden ya ingresada al manifiesto!!!");
          playsound();
          inputRef.current.value = "";
          return;
        }

        // Verificar si la orden ya está manifestada hoy
        const data = {
          od: dataOrden[0].OD,
          destino: iataUser,
        };
        const responseManifiesto = await obtenerManifiestoOrden(data);

        if (responseManifiesto && responseManifiesto.length > 0) {
          playsound();
          const { isConfirmed } = await Swal.fire({
            title: "Orden ya manifestada hoy",
            text: "La orden ya está en un manifiesto del mismo proceso. ¿Desea ingresarla de todas formas?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí, ingresar",
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
          });

          if (!isConfirmed) {
            inputRef.current.value = "";
            return;
          }
        }

        ingresarOrdenTabla(dataOrden);
        inputRef.current.value = "";
      } catch (error) {
        console.error("Error en ordenIngresada:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idCliente, ordenesDataTabla, iataUser, playsound],
  );

  const handleActualizar = async (event, newValue) => {
    actualizarPendientes(idCliente);
  };

  const handleIngresarOrden = async (event) => {
    await ordenIngresada(event);
  };

  const crearManifeisto = async () => {
    try {
      const fecha = await getFecha();
      const data = {
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: proceso,
        ID_CLIENTE: idCliente,
        COD_CHOFER: idChofer.value,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: id_user,
        COD_CHOFER_ENTRADA: id_user,
        NOMBRE_CHOFER_ENTRADA: nombreUser,
        SELLOS: "",
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: "TERRESTRE",
        TIPO_MANIFIESTO: proceso,
        ORIGEN: iataUser,
        DESTINO: iataUser,
        NOTA: nota,
        FH_MAIL: "",
        RUTA: iataUser,
        AGENTE_ASIGNADO: agenteAsignado,
      };

      const response = await createManifiestoAndPicking(data);

      return response;
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    setIsbtnEnviar(true);
    try {
      const dataManif = await buscarManifiestosEntAgentesManifiestoEntradaAgenteRef(nombreUser, idCliente);
      setLoading(false);
      setIsbtnEnviar(false);
      setOrdenesDataTablaM(dataManif);
      setOrdenesPendientes(dataManif.length);
      if (ordenesDataTabla.length === 0) {
        showError("Manifiesto debe contener alguna orden!!!");
        setLoading(true);
        playsound();
        return;
      } else if (dataManif.length !== 0) {
        Swal.fire({
          title: "Seguro de Crear Manifiesto?",
          text: "Aun quedan ordenes pendientes!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const numeroManifiesto = await crearManifeisto();
            await Mail_Gestor({ proceso, correoUser, numeroManifiesto });
            resetState();
            Swal.fire({
              title: "Manifiesto Creado",
              text: "N°: " + numeroManifiesto,
              icon: "success",
            });
            try {
              await downloadManifestPdfGlobal(numeroManifiesto, proceso, ciudadUser, "", true);
            } catch (error) {
              showError("Error al descargar el PDF del manifiesto");
            }
          }
          setLoading(true);
        });
      } else {
        Swal.fire({
          title: "Seguro de Crear Manifiesto?",
          text: "Esta a punto de crear un nuevo Manifiesto!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            const numeroManifiesto = await crearManifeisto();
            await Mail_Gestor({ proceso, correoUser, numeroManifiesto });
            resetState();
            Swal.fire({
              title: "Manifiesto Creado",
              text: "N°: " + numeroManifiesto,
              icon: "success",
            });
            try {
              await downloadManifestPdfGlobal(numeroManifiesto, proceso, ciudadUser, "", true);
            } catch (error) {
              showError("Error al descargar el PDF del manifiesto");
            }
          }
        });
      }
    } catch {}
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Autocomplete
                disabled
                disableClearable
                value={selectedCliente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={clientes}
                onChange={handleChangeClientes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CLIENTE" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="CIUDAD"
                value={ciudadUser}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                name="FH_ACTUAL"
                label="Fecha"
                sx={{ width: "100%" }}
                value={fechaActual}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="Nota"
                multiline
                rows={1}
                name="message"
                value={nota}
                onChange={handleChangeNota}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "90%",
                  backgroundColor: "#041562",
                }}
                onClick={handleCrearManifiesto}
                disabled={isbtnEnviar}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              {loading && <CircularProgress />}

              {""}
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper style={{ padding: "10px", marginTop: "10px" }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Ingrese CODIGO BARRA"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    ordenIngresada(e);
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} lg={1} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleIngresarOrden}
              >
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                ORDENES: {totalOrdenes}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                BULTOS: {totalBultos}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#DA251C",
                }}
                onClick={handleActualizar}
              >
                ACTUALIZAR PENDIENTES
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <TablaManifiestosAgentes rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Box
                border={2}
                padding={0}
                borderRadius={1}
                boxShadow={1}
                sx={{
                  color: "#DA251C",
                  marginBottom: "5px",
                  height: "40px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Arial, sans-serif",
                    color: "#DA251C",
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                    marginBottom: "15px",
                    textAlign: "center",
                  }}
                >
                  PENDIENTES: {ordenesPendientes}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <TablaManifiestosAgentes rows={ordenesDataTablaM} columns={columns2} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
}
export default EntradaAgenteCB;
