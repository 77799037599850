import React, { useState } from "react";
import { Typography, Autocomplete, TextField, Box } from "@mui/material";
import InhouseCB from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorCB/InhouseCB";
import EntradaAndenCB from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorCB/EntradaAndenCB";
import SalidaAndenCB from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorCB/SalidaAndenCB";
import EntradaAgenteCB from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorCB/EntradaAgenteCB";
import SalidaRutaCB from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorCB/SalidaRutaCB";
import SalidaAgenteCB from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorCB/SalidaAgenteCB";
import IngresoSantiagoCB from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorCB/IngresoSantiagoCB";
import RetornoClientesCB from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorCB/RetornoClientesCB";
import { useSelector } from "react-redux";

const opcionesManifiestos = [
  { label: "1. Inhouse", value: "InhouseCB" },
  { label: "2. Entrada Anden", value: "EntradaAndenCB" },
  { label: "3. Salida Anden", value: "SalidaAndenCB" },
  { label: "4. Entrada Agente", value: "EntradaAgenteCB" },
  { label: "5. Salida Ruta", value: "SalidaRutaCB" },
  { label: "6. Salida Agente", value: "SalidaAgenteCB" },
  { label: "7. Ingreso Santiago", value: "IngresoSantiagoCB" },
  { label: "8. Retorno Clientes", value: "RetornoClientesCB" },
];

const SeleccionManifiestoCB = () => {
  const [selectedModule, setSelectedModule] = useState(null);
  const userInfo = useSelector((state) => state.user.userInfo);

  const usuarioAgente = ["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo?.tipo_usuario);
  const usuarioOperaciones = ["OPERACIONES", "SUPERVISOR"].includes(userInfo?.tipo_usuario);

  const opcionesFiltradas = usuarioAgente
    ? opcionesManifiestos.filter(({ value }) =>
        ["EntradaAgenteCB", "SalidaRutaCB", "SalidaAgenteCB"].includes(value),
      )
    : usuarioOperaciones
      ? opcionesManifiestos.filter(
          ({ value }) => !["EntradaAgenteCB", "ManifiestosRutaCB", "SalidaAgenteCB"].includes(value),
        )
      : opcionesManifiestos;

  const renderModule = () => {
    switch (selectedModule) {
      case "InhouseCB":
        return <InhouseCB />;
      case "EntradaAndenCB":
        return <EntradaAndenCB />;
      case "SalidaAndenCB":
        return <SalidaAndenCB />;
      case "EntradaAgenteCB":
        return <EntradaAgenteCB />;
      case "SalidaRutaCB":
        return <SalidaRutaCB />;
      case "SalidaAgenteCB":
        return <SalidaAgenteCB />;
      case "IngresoSantiagoCB":
        return <IngresoSantiagoCB />;
      case "RetornoClientesCB":
        return <RetornoClientesCB />;

      default:
        return <Typography variant="h6">Seleccione Manifiesto a Realizar de Novofarma</Typography>;
    }
  };

  return (
    <Box>
      <Autocomplete
        options={opcionesFiltradas}
        getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => setSelectedModule(newValue ? newValue.value : "")}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Seleccionar Manifiesto"
            variant="outlined"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
      <Box mt={2}>{renderModule()}</Box>
    </Box>
  );
};

export default SeleccionManifiestoCB;
