import { CircularProgress, Button, Paper, Grid, Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  getCiudades,
  insertManifiesto,
  downloadManifestPdfGlobal,
  getAgentes,
} from "../../services/ManifiestosServices";
import { getOrdenesManifiestosRutaAgente } from "../../services/ManifiestoRutaAgenteServices";
import moment from "moment";
import { insertPickingManifiestoMasivo } from "../../services/ManifiestoComunaServices";
import TablaOrdenesAgentes from "../../components/OperacionesComponentes/componentesManifiestoRutaPorOd/TablaOrdenesAgentes";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ManifiestoRutaAgentes = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const [selectedCiudad, setSelectedCiudad] = useState(null);
  const [ciudadesOption, setCiudadesOption] = useState([]);
  const [selectedAgente, setSelectedAgente] = useState(null);
  const [agentesOption, setAgentesOption] = useState([]);
  const [fechaActual, setfechaActual] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingEliminar] = React.useState(false);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE_GESTION", headerName: "Destino", width: 300 },
    { field: "DIRECCION_GESTION", headerName: "Direccion", width: 300 },
    { field: "NOMBRE_COMUNA", headerName: "Comuna", width: 100 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 100 },
    { field: "FH_DIGITACION", headerName: "Fecha Orden", width: 200 },
    { field: "NOMBRE_CLIENTE", headerName: "Cliente", width: 200 },
  ];

  const formatearFecha = (fecha) => {
    const date = new Date(fecha);
    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0");
    const año = date.getFullYear();
    return `${dia}-${mes}-${año}`;
  };

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      const options = {
        timeZone: "America/Santiago",
        hour12: false,
      };

      const fecha = fechaActual.toLocaleDateString("es-CL", options);

      const [dia, mes, año] = fecha.split("-");

      const nuevaFecha = `${año}-${mes}-${dia}`;

      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options,
        hour12: false,
      });

      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null;
    }
  };

  useEffect(() => {
    const obtenerCiudades = async () => {
      const dataCiudades = await getCiudades();
      const ciudadesOption = dataCiudades.map((ciudades) => ({
        value: ciudades.IATA,
        label: ciudades.NOMBRE,
      }));
      setCiudadesOption(ciudadesOption);
    };
    obtenerCiudades();
  }, [userInfo]);

  useEffect(() => {
    const obtenerFechaActual = async () => {
      const fechaActual = moment().format("YYYY-MM-DD");
      setfechaActual(fechaActual);
    };
    obtenerFechaActual();
  });

  const handleChangeCiudades = async (event, newValue) => {
    setSelectedAgente(null);
    setOrdenesDataTabla([]);
    setSelectedCiudad(newValue);
    const dataAgentes = await getAgentes(newValue.value);
    const agenteOption = dataAgentes.map((agentes) => ({
      value: agentes.id,
      label: agentes.AGENTE_ASIGNADO,
    }));
    setAgentesOption(agenteOption);
  };

  const handleChangeAgentes = async (event, newValue) => {
    setSelectedAgente(newValue);
    setOrdenesDataTabla([]);
  };

  const handleCBuscarOrdenes = async () => {
    try {
      setLoading(true);
      const datos = {
        AGENTE_ASIGNADO: selectedAgente.label,
        COMUNA: selectedCiudad.value,
      };
      const OrdenesData = await getOrdenesManifiestosRutaAgente(datos);

      const formattedOrdenesData = OrdenesData.map((item) => ({
        ...item,
        FH_DIGITACION: formatearFecha(item.FH_DIGITACION),
      }));

      setOrdenesDataTabla(formattedOrdenesData);
      setLoading(false);
    } catch {}
  };

  const crearManifiesto = async () => {
    setLoading(true);

    if (ordenesDataTabla) {
      const ods = ordenesDataTabla.map((orden) => orden.OD);

      try {
        const fecha = getFecha();

        const data = {
          FH_CREACION: fecha,
          FH_CIERRE: fecha,
          COD_USUARIO: id_user,
          PROCESO: "SALIDARUTA",
          ID_CLIENTE: 0,
          COD_CHOFER: 0,
          ESTADO: "CERRADO",
          COD_OPERADOR_ENTRADA: 0,
          COD_CHOFER_ENTRADA: 0,
          NOMBRE_CHOFER_ENTRADA: userInfo.name,
          SELLOS: "",
          CANTIDAD_VALIJAS: 0,
          TOTAL_BULTOS: 1,
          TIPO_RUTA: "TERRESTRE",
          TIPO_MANIFIESTO: "SALIDARUTA",
          ORIGEN: userInfo.iata_agentes,
          DESTINO: selectedCiudad.value,
          NOTA: "LIBERACION RUTA DESDE SANTIAGO",
          FH_MAIL: "",
          RUTA: selectedCiudad.value,
          COD_PEONETA: 0,
          AGENTE_ASIGNADO: selectedAgente.label,
        };

        const response = await insertManifiesto(data);

        for (const od of ods) {
          const datosParaEnviar = {
            OD: od,
            ID_MANIFIESTO: response,
          };

          try {
            await insertPickingManifiestoMasivo(datosParaEnviar);
          } catch (error) {
            console.log("Fallo el envio");
          }
        }

        Swal.fire({
          title: "Manifiesto Creado",
          text: "N°: " + response,
          icon: "success",
        });
        try {
          setLoading(false);
          setSelectedAgente(null);
          setSelectedCiudad(null);
          setOrdenesDataTabla([]);
          await downloadManifestPdfGlobal(response, "SALIDARUTA", selectedCiudad.label, "", false);
        } catch (error) {
          toast.error("Error al descargar PDF!!!");
          return;
        }

        return response;
      } catch (error) {
        console.error("Error al crear el manifiesto:", error);
      }
    } else {
      console.log("No se encontraron órdenes.");
      Swal.fire({
        title: "Manifiesto No Creado",
        text: "ComuTabla sin ordenes",
        icon: "error",
      });
      setLoading(false);
    }
  };

  const handleCrearManifiesto = async () => {
    try {
      if (!selectedCiudad) {
        toast.error("Debe seleccionar un Destino.");
        return;
      } else if (!selectedAgente) {
        toast.error("Debe seleccionar un Agente.");
        return;
      } else if (ordenesDataTabla.length === 0) {
        toast.error("No hay ordenes para crear manifiesto.");
        return;
      }

      Swal.fire({
        title: "Seguro de Crear Manifiesto?",
        text: "Esta a punto de crear un nuevo Manifiesto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Crear!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          crearManifiesto();
        }
      });
      setLoading(false);
    } catch {}
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disableClearable
                value={selectedCiudad}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={ciudadesOption}
                onChange={handleChangeCiudades}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CIUDAD" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disableClearable
                value={selectedAgente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={agentesOption}
                onChange={handleChangeAgentes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="AGENTE" />}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                name="FH_ACTUAL"
                label="Fecha"
                sx={{ width: "100%" }}
                value={fechaActual}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleCBuscarOrdenes}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Buscar Ordenes"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleCrearManifiesto}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <Grid style={{ marginTop: "10px" }}>
              <TablaOrdenesAgentes rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
export default ManifiestoRutaAgentes;
