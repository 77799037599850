import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import {
  obtenerMarcasVehiculo,
  obtenerModelosVehiculo,
  obtenerCiudades,
} from "../../../services/PortalVehiculosService";
import Autocomplete from "@mui/material/Autocomplete";

const emptyFormData = {
  PATENTE: "",
  ID_MARCA: "",
  ID_MODELO: "",
  ANIO_FABRICACION: "",
  IATA: "",
  CAPACIDAD_CARGA: "",
  KILOMETRAJE: "",
  ESTADO: "",
  OBSERVACIONES: "",
  CLIMATIZACION: "",
};

const CreateModalVehiculos = ({ open, onClose, onSave }) => {
  const [marcas, setMarcas] = useState([]);
  const [marcaSeleccionada, setMarcaSeleccionada] = useState(null);
  const [modelos, setModelos] = useState([]);
  const [modeloSeleccionado, setModeloSeleccionado] = useState(null);
  const [ciudades, setCiudades] = useState([]);
  const [ciudadSeleccionada, setCiudadSeleccionada] = useState(null);

  const [formData, setFormData] = useState(emptyFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  /* Función para resetear el formulario */
  const resetForm = () => {
    setFormData(emptyFormData);
    setMarcaSeleccionada(null);
    setModeloSeleccionado(null);
    setModelos([]);
    setCiudadSeleccionada(null);
  };

  /*Carga de marcas */
  useEffect(() => {
    const cargarMarcas = async () => {
      try {
        const marcasObtenidas = await obtenerMarcasVehiculo();
        setMarcas(marcasObtenidas);
      } catch (error) {
        console.error("Error al obtener marcas:", error);
      }
    };
    cargarMarcas();
  }, []);

  /* Cargar modelos cuando cambia la marca seleccionada */
  useEffect(() => {
    const cargarModelos = async () => {
      if (marcaSeleccionada) {
        try {
          const modelosObtenidos = await obtenerModelosVehiculo(marcaSeleccionada.ID_MARCA);
          setModelos(modelosObtenidos);
        } catch (error) {
          console.error("Error al obtener modelos:", error);
        }
      } else {
        setModelos([]);
      }
    };
    cargarModelos();
  }, [marcaSeleccionada]);

  // Cargar Ciudades
  useEffect(() => {
    const cargarCiudades = async () => {
      try {
        const ciudadesObtenidas = await obtenerCiudades();
        setCiudades(ciudadesObtenidas);
      } catch (error) {
        console.error("Error al obtener ciudades:", error);
      }
    };
    cargarCiudades();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Crear Nuevo Vehiculo</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Patente"
          type="text"
          fullWidth
          variant="outlined"
          name="PATENTE"
          value={formData.PATENTE || ""}
          onChange={(e) => {
            const regex = /^[a-zA-Z0-9]*$/;
            if (regex.test(e.target.value)) {
              handleChange(e);
            }
          }}
        />
        <Autocomplete
          id="combo-box-marca"
          options={marcas}
          getOptionLabel={(option) => (option ? `${option.NOMBRE_MARCA} (${option.ID_MARCA})` : "")}
          isOptionEqualToValue={(option, value) => option.ID_MARCA === value.ID_MARCA}
          value={marcaSeleccionada}
          onChange={(event, newValue) => {
            setMarcaSeleccionada(newValue);
            setFormData((prev) => ({
              ...prev,
              ID_MARCA: newValue ? newValue.ID_MARCA : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione una marca" variant="outlined" fullWidth margin="dense" />
          )}
        />
        <Autocomplete
          id="combo-box-modelo"
          options={modelos}
          getOptionLabel={(option) => (option ? option.NOMBRE_MODELO : "")}
          isOptionEqualToValue={(option, value) => option.ID_MODELO === value.ID_MODELO}
          value={modeloSeleccionado}
          onChange={(event, newValue) => {
            setModeloSeleccionado(newValue);
            setFormData((prev) => ({
              ...prev,
              ID_MODELO: newValue ? newValue.ID_MODELO : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione un modelo" variant="outlined" fullWidth margin="dense" />
          )}
        />
        <Autocomplete
          id="combo-box-ciudad"
          options={ciudades}
          getOptionLabel={(option) => option.NOMBRE}
          isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
          value={ciudadSeleccionada}
          onChange={(event, newValue) => {
            setCiudadSeleccionada(newValue);
            setFormData((prev) => ({
              ...prev,
              IATA: newValue ? newValue.IATA : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione Ciudad" variant="outlined" fullWidth margin="dense" />
          )}
        />
        <TextField
          margin="dense"
          label="Año Fabricacion"
          type="email"
          fullWidth
          variant="outlined"
          name="ANIO_FABRICACION"
          value={formData.ANIO_FABRICACION || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Capacidad Carga"
          type="email"
          fullWidth
          variant="outlined"
          name="CAPACIDAD_CARGA"
          value={formData.CAPACIDAD_CARGA || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Kilometraje"
          type="text"
          fullWidth
          variant="outlined"
          name="KILOMETRAJE"
          value={formData.KILOMETRAJE || ""}
          onChange={handleChange}
        />
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Seleccione un estado</InputLabel>
          <Select
            margin="dense"
            label="Seleccione un estado"
            fullWidth
            variant="outlined"
            name="ESTADO"
            value={formData.ESTADO}
            onChange={handleChange}
          >
            <MenuItem value="OPERATIVO">OPERATIVO</MenuItem>
            <MenuItem value="MANTENIMIENTO">MANTENIMIENTO</MenuItem>
            <MenuItem value="INACTIVO">INACTIVO</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Observaciones"
          type="text"
          fullWidth
          variant="outlined"
          name="OBSERVACIONES"
          value={formData.OBSERVACIONES || ""}
          onChange={handleChange}
        />
        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Climatización?</InputLabel>
          <Select
            margin="dense"
            label="Climatizacion?"
            fullWidth
            variant="outlined"
            name="CLIMATIZACION"
            value={formData.CLIMATIZACION}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            resetForm();
            onSave(formData);
            
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModalVehiculos;
