const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;
//const BASE_API_URL = "http://localhost:10000/";
export const getVehiculosService = async (
  isFiltered = false, 
  codChofer = null, 
  setVehiculos = null, 
  setRequestError = null
) => {
  try {
    let url = `${BASE_API_URL}vehiculos/obtenerHistorialVehiculo`;
    
    // Si isFiltered es true y hay un codChofer, agregar parámetros de filtrado
    if (isFiltered && codChofer) {
      url += `?cod_chofer=${codChofer}`;
    }

    const response = await fetch(url, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    });
    const data = await response.json();

    // Si se proporciona una función setState, actualizar el estado
    if (setVehiculos) {
      setVehiculos(data);
    }

    return data;
  } catch (error) {
    console.error('Error al obtener vehículos:', error);
    if (setRequestError) {
      setRequestError(prev => ({
        ...prev,
        asignedVehicles: "Error al obtener los vehículos asignados"
      }));
    }
    return [];
  }
};

export const getChoferes = async (setRetiros, setRetirosError) => {
  try {
    const r = await fetch(`${BASE_API_URL}operaciones/getChoferes`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante la obtencion de choferes.")
    setRetiros(rr)
  } catch(e){
    console.log(e)
  }
}

// Modificar la función para que pueda recibir IDs o un setter
export const getRecoleccionesPCEjecutadas = async (ID_RECOLECCION, ID_REC_CHOFER = null) => {
  try {
    let url = `${BASE_API_URL}operaciones/getRecoleccionesPCEjecucion`;

    // Si ID_RECOLECCION_CHOFER está definido, usamos ese parámetro
    if (ID_REC_CHOFER !== null) {
      url += `?ID_REC_CHOFER=${ID_REC_CHOFER}`;
      
      // Agregar ID_RECOLECCION si también está definido
      if (ID_RECOLECCION !== null) {
        url += `&ID_RECOLECCION=${ID_RECOLECCION}`;
      }
    }
    // Si solo ID_RECOLECCION está definido
    else if (ID_RECOLECCION !== null && typeof ID_RECOLECCION !== 'function') {
      url += `?ID_RECOLECCION=${ID_RECOLECCION}`;
    }
    
    const r = await fetch(url);
    if(!r.ok) throw new Error("Algo fue mal durante la obtención de recolecciones.");
    const rr = await r.json();
    
    // Si ID_RECOLECCION es una función, asumimos que es setRecolecciones
    if (typeof ID_RECOLECCION === 'function') {
      ID_RECOLECCION(rr);
      return;
    }

    // De lo contrario, devolvemos los datos
    return rr;
  } catch(e) {
    console.error("Error en getRecoleccionesPCEjecutadas:", e);
    
    // Si ID_RECOLECCION es una función, pasamos el error
    if (typeof ID_RECOLECCION === 'function') {
      ID_RECOLECCION(e.message);
      return;
    }
    
    // De lo contrario, lanzamos el error o devolvemos un array vacío
    return [];
  }
}

export const insertRecolectaPlanta = async (recPlantaCliente) => {
  try{
    const r = await fetch(`${BASE_API_URL}operaciones/insertNotificacionRecoleccion`, {
      method:"POST",
      body:JSON.stringify(recPlantaCliente),
      headers:{
        "Content-Type":"application/json"
      }
    })
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante la insercion de la recolecta de planta.")
    return rr
  } catch(e){
    console.log(e)
  }
}

export const insertRecolectaPlantaChofer = async (recPlantaClienteChofer) => {
  try{
    const r = await fetch(`${BASE_API_URL}operaciones/insertNotificacionRecoleccionChofer`, {
      method:"POST",
      body:JSON.stringify(recPlantaClienteChofer),
      headers:{
        "Content-Type":"application/json"
      }
    })
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante la insercion de la recolecta de planta chofer.")
    return rr
  } catch(e){
    console.log(e)
  }
}

export const getRecoleccionesPendientesByCliente = async (id=null,cod_cliente, direccion) => {
  try{
    let r = null
    if(!id && cod_cliente) r = await fetch(`${BASE_API_URL}operaciones/getNotificacionesRecoleccion?COD_CLIENTE=${cod_cliente}${direccion ? `&DIRECCION=${direccion}` : "" }`)
    else  r = await fetch(`${BASE_API_URL}operaciones/getNotificacionesRecoleccion?ID=${id}`)
    const rr = await r.json()
    return rr
  } catch(e){
    console.log(e)
  }
}

export const getVehiculoAsignados = async(type=null, cod_chofer, setAsignedV, setAsignedVError) => {
  try {
    const r = await fetch(`${BASE_API_URL}vehiculos/obtenerHistorialVehiculo?cod_chofer=${cod_chofer}`, {
      headers:{
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })
    const rr = await r.json()
    if(!r.ok) throw new Error(`Error al obtener vehículo: ${r.statusText}`)
    
    if (!type) {
      return rr;
    } else {
      setAsignedV(rr);
    }
  } catch(e) {
    const errorMessage = `Error al obtener vehículo asignado: ${e.message}`;
    if (!type) {
      throw new Error(errorMessage);
    } else {
      setAsignedVError(prev => ({...prev, asignedVehicles: errorMessage}));
    }
  }
}

export const getDireccionesCliente = async (cod_cliente, setDirecciones, setDireccionesError, _type = null) => {
  try {
    const r = await fetch(`${BASE_API_URL}clientes/obtenerDireccionesCliente/${cod_cliente}`)
    const rr = await r.json()
    if (!r.ok) throw new Error("Algo fue mal durante la obtencion de las direcciones")
    
    // Process the response to handle DIRECCION_CLIENTE
    if (Array.isArray(rr)) {
      // Store the DIRECCION_CLIENTE value if it exists
      const direccionCliente = rr.find(dir => dir.DIRECCION_CLIENTE)?.DIRECCION_CLIENTE;
      
      // Only process if DIRECCION_CLIENTE exists
      if (direccionCliente) {
        // Check if DIRECCION_CLIENTE is already in the DIRECCION array
        const direccionClienteExists = rr.some(dir => 
          dir.DIRECCION && 
          (dir.DIRECCION === direccionCliente || 
           dir.DIRECCION.includes(direccionCliente) || 
           direccionCliente.includes(dir.DIRECCION))
        );
        
        // If DIRECCION_CLIENTE is not already in the array, add it
        if (!direccionClienteExists) {
          rr.push({
            DIRECCION: direccionCliente,
            CIUDAD: rr.find(dir => dir.CIUDAD)?.CIUDAD || '',
            ID_DIRECCION: 'principal',
            ES_DIRECCION_PRINCIPAL: true
          });
        }
      }
    }
    
    if (!_type) setDirecciones(rr)
    else return rr
  } catch (e) {
    if (!_type) setDireccionesError((prev) => ({...prev, asignedAdresses: e.message}))
    else return e.message
  }
}

export const getChoferesRecoleccion = async (ID, ID_REF, COD_CHOFER, TIPO_CHOFER, COD_CLIENTE, DIRECCION = null) => {
  try {
    // If type is null/false, only search by ID
    if (ID !== null) {
      const r = await fetch(`${BASE_API_URL}operaciones/getChoferesAsignadosARecoleccion?ID=${ID}`)
      const rr = await r.json()
      if (!r.ok) throw new Error("Algo fue mal durante la obtencion de los choferes")
      return rr
    }
    if (COD_CLIENTE){
      const r = await fetch(`${BASE_API_URL}operaciones/getChoferesAsignadosARecoleccion?COD_CLIENTE=${COD_CLIENTE}${DIRECCION ? `&DIRECCION=${DIRECCION}` : ""}`)
      const rr = await r.json()
      if (!r.ok) throw new Error("Algo fue mal durante la obtencion de los choferes")
      return rr
    }

    const r = await fetch(`${BASE_API_URL}operaciones/getChoferesAsignadosARecoleccion?ID_RECOLECCION=${ID_REF}&COD_CHOFER=${COD_CHOFER}&TIPO_CHOFER=${TIPO_CHOFER}`)
    const rr = await r.json()
    if (!r.ok) throw new Error("Algo fue mal durante la obtencion de los choferes")
    return rr
  } catch (e) {
    console.log(e)
  }
}

export const insertRecoleccionPCE = async (recoleccionPCE) => {
  try{
    const r = await fetch(`${BASE_API_URL}operaciones/insertRecoleccionEnEjecucion`, {
      method:"POST",
      body:JSON.stringify(recoleccionPCE),
      headers:{
        "Content-Type":"application/json"
      }
    })
    const rr = await r.json()
    return rr
  } catch(e){ 
    console.log(e)
  }
}

export const deleteRecoleccionPCEChofer = async (FH_RECOLECCION, COD_CHOFER, COD_CLIENTE) => {
  try{
    const r = await fetch(`${BASE_API_URL}operaciones/deleteRecoleccionPCEChofer?FH_RECOLECCION=${FH_RECOLECCION}&COD_CHOFER=${COD_CHOFER}&COD_CLIENTE=${COD_CLIENTE}`, {
      method:"DELETE",
      headers:{
        "Content-Type":"application/json"
      }
    })
    const rr = await r.json()
    return rr
  } catch(e){
    console.log(e)
  }
}



export const updateChoferRecoleccion = async (_type,{ID_RECOLECCION_CHOFER,COD_CHOFER,TIPO_CHOFER,PATENTE_VEHICULO,FH_RECOLECCION}) => {
  try {
    if (_type === "fh" && FH_RECOLECCION) {
      const r = await fetch(`${BASE_API_URL}operaciones/updateRecoleccionPCEChofer?reqType=update_${_type}`, {
        method: "PUT",
        body: JSON.stringify({ID_RECOLECCION_CHOFER,FH_RECOLECCION}),
        headers: {
          "Content-Type": "application/json"
        }
      })
      const rr = await r.json()
      return rr
    } else if (_type === "chofer" && COD_CHOFER && TIPO_CHOFER && PATENTE_VEHICULO) {
      const r = await fetch(`${BASE_API_URL}operaciones/updateRecoleccionPCEChofer?reqType=update_${_type}`, {
        method: "PUT",
        body: JSON.stringify({ID_RECOLECCION_CHOFER,COD_CHOFER,TIPO_CHOFER,PATENTE_VEHICULO}),
        headers: {
          "Content-Type": "application/json"
        }
      })
      const rr = await r.json()
      return rr
    } else if(_type === "both" && FH_RECOLECCION && COD_CHOFER && TIPO_CHOFER && PATENTE_VEHICULO) {
      const r = await fetch(`${BASE_API_URL}operaciones/updateRecoleccionPCEChofer?reqType=update_${_type}`, {
        method: "PUT",
        body: JSON.stringify({ID_RECOLECCION_CHOFER,FH_RECOLECCION,COD_CHOFER,TIPO_CHOFER,PATENTE_VEHICULO}),
        headers: {
          "Content-Type": "application/json"
        }
      })
      const rr = await r.json()
      return rr
    }
  } catch (e) {
    console.log(e)
  }
}


export const updateRecoleccionEjecucionStatus = async (ejecucionId, ID_RECOLECCION, ID_RECOLECCION_CHOFER, EJECUTO_RECOLECCION) => {
  try{
    const r = await fetch(`${BASE_API_URL}operaciones/updateRecoleccionEjecucionStatus?ID=${ejecucionId}`, {
      method:"PUT",
      body:JSON.stringify({ID_RECOLECCION, ID_RECOLECCION_CHOFER, EJECUTO_RECOLECCION}),
      headers:{
        "Content-Type":"application/json"
      }
    })
    const rr = await r.json()
    return rr
  } catch(e){
    console.log(e)
  }
}

export const deleteRecoleccionPCESupport = async (ID_RECOLECCION, ID_RECOLECCION_CHOFER) => {
  try{
    const r = await fetch(`${BASE_API_URL}operaciones/deleteRecoleccionEjecucionSupport?ID_RECOLECCION=${ID_RECOLECCION}&ID_RECOLECCION_CHOFER=${ID_RECOLECCION_CHOFER}`, {
      method:"DELETE",
      headers:{
        "Content-Type":"application/json"
      }
    })
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante la eliminacion de la recoleccion")
    return rr
  }catch(e){
    console.log(e)
  }
}




export const deleteAllRecoleccionesPCE = async (_type, data) => { 
  try{
    if (["deleteAllPending", "deleteAllOK","deleteAll"].includes(_type)){
      const r = await fetch(`${BASE_API_URL}operaciones/deleteAllRecoleccionData?reqType=${_type}`, {
        method:"DELETE",
        headers:{
          "Content-Type":"application/json",
          "Accept":"application/json"
        },
        body:JSON.stringify({data})
      })
      const rr = await r.json()
      return rr
    }
  } catch(e){
    console.log(e)
  }
}


export const getClientesDisponiblesParaNotificacion = async () => {
  try{
    const r = await fetch(`${BASE_API_URL}operaciones/getAllRetiroClients`)
    const rr = await r.json()
    return rr
  } catch(e){
    console.log(e)
  }
}