import React, { useState, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  actualizarPasswordUsuario,
  obtenerCodigoChofer,
  obtenerAgentesNegocios,
} from "../../../services/PortalChoferesService";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
import { obtenerComunas } from "../../../services/EventosManualServices";
import { validate, clean, format } from "rut.js";

const emptyFormData = {
  NOMBRE: "",
  RUT: "",
  PASSWORD: "",
  PASSWORD_HASH: "",
  COD_CHOFER: "",
  CIUDAD: "",
  TELEFONO: "",
  MAIL: "",
  ESTADO: "",
  TRANSPORTISTA: "",
  CHOFER_ADMINISTRATIVO: "",
  AGENTE_ASIGNADO: "",
  PERMITE_APP: "",
  PERMITE_RETIROS: "",
  PERMITE_IMPRESORA: "",
  PERMITE_ENTREGAS: "",
  PERMITE_INSPECCION: "",
  PERMITE_RETORNOS: "",
};

const CreateModalChoferes = ({ open, onClose, onSave }) => {
  const [agentesNegocios, setAgentesNegocios] = useState([]);
  const [proximoCodChofer, setProximoCodChofer] = useState("");
  const [comunas, setComunas] = useState([]);
  const [comunaSeleccionada, setComunaSeleccionada] = useState(null);
  const [isValid, setIsValid] = useState(true);

  const [formData, setFormData] = useState({ ...emptyFormData });

  const handleRutChange = (event) => {
    const rutInput = event.target.value;
    const cleanedRut = clean(rutInput);
    const limitedRut = cleanedRut.slice(0, 9);

    if (limitedRut.length >= 7 && validate(limitedRut)) {
      const formattedRut = format(limitedRut, { dots: false });
      setFormData((prev) => ({ ...prev, RUT: formattedRut }));
      setIsValid(true);
    } else {
      setFormData((prev) => ({ ...prev, RUT: limitedRut }));
      setIsValid(false);
    }
  };

  useEffect(() => {
    if (!formData.COD_CHOFER) {
      setFormData((prev) => ({ ...prev, COD_CHOFER: proximoCodChofer }));
    }
  }, [formData.COD_CHOFER, proximoCodChofer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const resetForm = () => {
    setFormData({ ...emptyFormData });
    setComunaSeleccionada(null);
    setProximoCodChofer("");
  };

  const handleEditPasswordClick = async () => {
    const nuevaPassword = formData.PASSWORD;
    if (!nuevaPassword || nuevaPassword.length < 6) {
      toast.error("La contraseña debe tener al menos 6 caracteres");
      return;
    }
    try {
      const response = await actualizarPasswordUsuario(nuevaPassword);
      const { hash } = response;
      setFormData((prevFormData) => ({
        ...prevFormData,
        PASSWORD_HASH: hash,
      }));
      toast.success("Hash actualizado con éxito");
    } catch (error) {
      console.error("Error al actualizar el Hash:", error);
      toast.error("Error al actualizar el Hash");
    }
  };

  useEffect(() => {
    if (open) {
      const cargarCodigoChofer = async () => {
        try {
          const response = await obtenerCodigoChofer();
          setProximoCodChofer(response.proximoCod);
        } catch (error) {
          console.error("Error al obtener el código de chofer:", error);
          toast.error("Error al cargar el código de chofer.");
        }
      };
      cargarCodigoChofer();
    }
  }, [open]);

  useEffect(() => {
    const cargarAgentesNegocios = async () => {
      try {
        const listaAgentes = await obtenerAgentesNegocios();
        setAgentesNegocios(listaAgentes);
      } catch (error) {
        console.error("Error al obtener los agentes de negocios:", error);
        toast.error("Error al cargar la lista de agentes de negocios.");
      }
    };
    cargarAgentesNegocios();
  }, []);

  useEffect(() => {
    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };
    cargarComunas();
  }, []);

  const agentesFiltrados = useMemo(() => {
    if (!formData.CIUDAD) {
      return agentesNegocios;
    }
    return agentesNegocios.filter((agent) => agent.iata_agentes === formData.CIUDAD);
  }, [agentesNegocios, formData.CIUDAD]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Crear Nuevo Choferes</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nombre"
          type="text"
          fullWidth
          variant="outlined"
          name="NOMBRE"
          value={formData.NOMBRE || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Rut"
          type="text"
          fullWidth
          variant="outlined"
          name="RUT"
          value={formData.RUT || ""}
          onChange={handleRutChange}
        />
        {!isValid && (
          <Typography style={{ color: "red", textAlign: "center", marginBottom: 10 }}>
            Por favor, ingrese un RUT válido.
          </Typography>
        )}
        <TextField
          margin="dense"
          label="Password"
          type="text"
          fullWidth
          variant="outlined"
          name="PASSWORD"
          value={formData.PASSWORD || ""}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password edit" onClick={handleEditPasswordClick}>
                  <EditIcon style={{ color: "#041562" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="dense"
          label="Password Hash"
          type="text"
          fullWidth
          variant="outlined"
          name="PASSWORD_HASH"
          value={formData.PASSWORD_HASH || ""}
          onChange={handleChange}
          disabled
        />

        <Autocomplete
          id="combo-box-comuna"
          options={comunas}
          getOptionLabel={(option) => (option ? `${option.NOMBRE} (${option.IATA})` : "")}
          isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
          value={comunaSeleccionada}
          onChange={(event, newValue) => {
            setComunaSeleccionada(newValue);
            setFormData((prev) => ({
              ...prev,
              CIUDAD: newValue ? newValue.IATA : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione una comuna" variant="outlined" fullWidth margin="dense" />
          )}
        />

        <Autocomplete
          id="combo-box-agente"
          options={agentesFiltrados}
          getOptionLabel={(option) => (option ? option.name  : "")}
          value={
            agentesFiltrados.find((agent) => agent.name === formData.AGENTE_ASIGNADO) ||
            null
          }
          onChange={(event, newValue) => {
            setFormData({
              ...formData,
              AGENTE_ASIGNADO: newValue ? newValue.name : "",
            });
          }}
          renderInput={(params) => (
            <TextField style={{ marginTop: "10px" }} {...params} label="Agente Asignado" variant="outlined" fullWidth />
          )}
        />

        <TextField
          margin="dense"
          label="Correo"n
          type="email"
          fullWidth
          variant="outlined"
          name="MAIL"
          value={formData.MAIL || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Telefono"
          type="tel"
          fullWidth
          variant="outlined"
          name="TELEFONO"
          value={formData.TELEFONO || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Código de Chofer"
          type="text"
          fullWidth
          variant="outlined"
          name="COD_CHOFER"
          value={formData.COD_CHOFER || proximoCodChofer}
          onChange={handleChange}
          helperText={`El próximo código de chofer disponible es: ${proximoCodChofer}`}
        />

        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel>Seleccione un estado</InputLabel>
          <Select
            margin="dense"
            label="Seleccione un estado"
            fullWidth
            variant="outlined"
            name="ESTADO"
            value={formData.ESTADO}
            onChange={handleChange}
          >
            <MenuItem value="ACTIVO">ACTIVO</MenuItem>
            <MenuItem value="INACTIVO">INACTIVO</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel>Es Transportista?</InputLabel>
          <Select
            margin="dense"
            label="Es Transportista?"
            fullWidth
            variant="outlined"
            name="TRANSPORTISTA"
            value={formData.TRANSPORTISTA}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel>Chofer administrativo?</InputLabel>
          <Select
            margin="dense"
            label="Chofer Administrativo"
            fullWidth
            variant="outlined"
            name="CHOFER_ADMINISTRATIVO"
            value={formData.CHOFER_ADMINISTRATIVO}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel>Permite App?</InputLabel>
          <Select
            margin="dense"
            label="Permite App?"
            fullWidth
            variant="outlined"
            name="PERMITE_APP"
            value={formData.PERMITE_APP}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel>Permite Retiros?</InputLabel>
          <Select
            margin="dense"
            label="Permite Retiros?"
            fullWidth
            variant="outlined"
            name="PERMITE_RETIROS"
            value={formData.PERMITE_RETIROS}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel>Permite Impresora?</InputLabel>
          <Select
            margin="dense"
            label="Permite Impresora?"
            fullWidth
            variant="outlined"
            name="PERMITE_IMPRESORA"
            value={formData.PERMITE_IMPRESORA}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel>Permite Entregas?</InputLabel>
          <Select
            margin="dense"
            label="Permite Entregas?"
            fullWidth
            variant="outlined"
            name="PERMITE_ENTREGAS"
            value={formData.PERMITE_ENTREGAS}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel>Permite Inspeccion?</InputLabel>
          <Select
            margin="dense"
            label="Permite Inspeccion?"
            fullWidth
            variant="outlined"
            name="PERMITE_INSPECCION"
            value={formData.PERMITE_INSPECCION}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel>Permite Retornos?</InputLabel>
          <Select
            margin="dense"
            label="Permite Retornos?"
            fullWidth
            variant="outlined"
            name="PERMITE_RETORNOS"
            value={formData.PERMITE_RETORNOS}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            resetForm();
            onSave(formData);
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModalChoferes;
