import {
  buscarDatosPickingTempManifiestoRuta,
  //buscarComunasChoferManifiestoRuta,
  obtenerComunasCondicional
} from "../../../services/ManifiestosServices";

//Choferes
export const handleChangeChoferes = async (
  event,
  newValue,
  setSelectedChofer,
  setIdChofer,
  setTotalOrdenes,
  setTotalBultos,
  setOrdenesDataTabla,
  setComunas,
  id_user,
  proceso,
  codigoCliente
) => {
  try {
    setSelectedChofer(newValue);
    const codi = newValue.value;
    if (codi !== null) {
      setIdChofer(newValue.value);

      const datos = {
        COD_USUARIO: id_user,
        COD_CHOFER: codi,
        PROCESO: proceso,
        COD_CLIENTE: codigoCliente
      };
      const dataPickingTemp = await buscarDatosPickingTempManifiestoRuta(datos);

      let totalBultosTemp = 0;
      if (dataPickingTemp && dataPickingTemp.results) {
        dataPickingTemp.results.forEach((orden) => {
          totalBultosTemp += orden.BULTOS;
        });
        setTotalOrdenes(dataPickingTemp.results.length);
      }

      setTotalBultos(totalBultosTemp);

      if (dataPickingTemp && dataPickingTemp.results) {
        setOrdenesDataTabla(dataPickingTemp.results);
      } else {
        setOrdenesDataTabla([]);
      }
    } else {
      alert("Seleccione un chofer");
    }

    const dataComunas = await obtenerComunasCondicional(codi);
    setComunas(dataComunas);
  } catch (error) {
    console.error("Error al obtener datos del backend:", error);
  }
};

//Peonetas
export const handleChangePeoneta = (event, newValue, setSelectedPeoneta, setIdPeoneta) => {
  if (newValue === null) {
    setSelectedPeoneta(null);
    setIdPeoneta("");
  } else {
    setSelectedPeoneta(newValue);
    setIdPeoneta(newValue.value);
  }
};

//Patentes
export const handleChangePatente = (event, newValue, setSelectedPatente) => {
  if (newValue === null) {
    setSelectedPatente(null);
  } else {
    setSelectedPatente(newValue);
    console.log(newValue); 
  }
};

//Nota
export const handleChangeNota = (event, setNota) => {
  setNota(event.target.value);
};
