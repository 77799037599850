import React, { useState, memo, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Paper,
  MenuItem,
  Typography,
  CircularProgress,
  Menu,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomDate from "../../utils/CustomDate";
import CustomAutocomplete from "../../utils/CustomAutocomplete";
import {
  obtenerClientesControlSac,
  obtenerComunas,
  descargarArchivo,
  iniciarDescargaUnica,
  obtenerAgentesSLA,
} from "../../services/ConsultaKpisServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const formatFechaToYYYYMMDD = (date) => date.toISOString().split("T")[0];

const Consultakpis = () => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [comunaOrigen, setComunaOrigen] = useState({ IATA: "todas", nombre: "Todas" });
  const [comunaDestino, setComunaDestino] = useState({ IATA: "todas", nombre: "Todas" });
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [tipoOrden, setTipoOrden] = useState("todas");
  const [loading, setLoading] = useState({ tipoReporte: null, tipoArchivo: null });
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const [agentesSLA, setAgentesSLA] = useState([]);
  const [selectedAgente, setSelectedAgente] = useState({
    IATA_AGENTES: "todos",
    NOMBRE: "todos",
  });

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await obtenerClientesControlSac();
        setClientes(response);
      } catch (error) {
        console.error("Error al obtener la lista de clientes:", error);
      }
    };

    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };

    fetchClientes();
    cargarComunas();
  }, []);

  useEffect(() => {
    obtenerAgentesSLA()
      .then((res) => setAgentesSLA(res))
      .catch((err) => console.error(err));
  }, []);

  /* Validar campos */
  const validarCampos = () => {
    if (!selectedClient) {
      toast.error("Debes seleccionar un cliente.");
      return false;
    }
    if (!comunaOrigen) {
      toast.error("Debes seleccionar una comuna de origen.");
      return false;
    }
    if (!comunaDestino) {
      toast.error("Debes seleccionar una comuna de destino.");
      return false;
    }
    if (!fechaDesde) {
      toast.error("Debes seleccionar la fecha desde.");
      return false;
    }
    if (!fechaHasta) {
      toast.error("Debes seleccionar la fecha hasta.");
      return false;
    }
    if (fechaDesde > fechaHasta) {
      toast.error("La fecha 'Desde' no puede ser posterior a la fecha 'Hasta'.");
      return false;
    }
    const diffTime = Math.abs(fechaHasta - fechaDesde);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 93) {
      toast.error("El rango entre las fechas debe ser de máximo 93 días.");
      return false;
    }
    return true;
  };

  /* Descargar reporte (única función) */
  const descargarReporte = async (tipoArchivo, tipoReporte) => {
    if (!validarCampos()) return;

    setLoading({ tipoReporte, tipoArchivo });

    const data = {
      clienteId: selectedClient?.id,
      nombreCliente: selectedClient?.id === "todos" ? "todos" : selectedClient?.nombre.replace(/ \(ID: \d+\)$/, ""),
      tipoOrden,
      comunaOrigen: comunaOrigen?.IATA,
      comunaDestino: comunaDestino?.IATA,
      fechaDesde: fechaDesde ? formatFechaToYYYYMMDD(fechaDesde) : null,
      fechaHasta: fechaHasta ? formatFechaToYYYYMMDD(fechaHasta) : null,
      tipoReporte,
      tipoArchivo,
      agenteIATA: selectedAgente?.NOMBRE,
    };

    try {
      const { url } = await iniciarDescargaUnica(data);

      if (url) {
        const fileUrl = descargarArchivo(url, `Reporte-${tipoArchivo}`);
        toast.info(
          <div>
            <p>
              Haz clic{" "}
              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                aquí
              </a>{" "}
              para descargar el archivo.
            </p>
          </div>,
          { autoClose: false }
        );
      } else {
        toast.error(`Error al obtener la URL del archivo ${tipoArchivo.toUpperCase()}`);
      }
    } catch (error) {
      console.error(`Error al descargar ${tipoArchivo}:`, error);
      toast.error(`Error al descargar ${tipoArchivo.toUpperCase()}`);
    } finally {
      setLoading({ tipoReporte: null, tipoArchivo: null });
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: "Reporte Interno (CSV)",
      tipoArchivo: "csv",
      tipoReporte: "interno",
      icon: <FileDownloadIcon fontSize="small" style={{ color: "#2c3e50" }} />,
      color: "#2c3e50",
    },
    {
      label: "Reporte Interno (PDF)",
      tipoArchivo: "pdf",
      tipoReporte: "interno",
      icon: <PictureAsPdfIcon fontSize="small" style={{ color: "#2c3e50" }} />,
      color: "#2c3e50",
    },
    {
      label: "Reporte Cliente (CSV)",
      tipoArchivo: "csv",
      tipoReporte: "cliente",
      icon: <FileDownloadIcon fontSize="small" style={{ color: "#3498db" }} />,
      color: "#3498db",
    },
    {
      label: "Reporte Cliente (PDF)",
      tipoArchivo: "pdf",
      tipoReporte: "cliente",
      icon: <PictureAsPdfIcon fontSize="small" style={{ color: "#3498db" }} />,
      color: "#3498db",
    },
    {
      label: "Reporte Agentes (CSV)",
      tipoArchivo: "csv",
      tipoReporte: "agentesSLA",
      icon: <FileDownloadIcon fontSize="small" style={{ color: "#e74c3c" }} />,
      color: "#e74c3c",
    },
    {
      label: "Reporte Agentes (PDF)",
      tipoArchivo: "pdf",
      tipoReporte: "agentesSLA",
      icon: <PictureAsPdfIcon fontSize="small" style={{ color: "#e74c3c" }} />,
      color: "#e74c3c",
    },
  ];

  const handleMenuItemClick = (tipoArchivo, tipoReporte) => {
    if (
      tipoReporte === "cliente" &&
      (tipoArchivo === "pdf" || tipoArchivo === "csv") &&
      selectedClient?.id === "todos"
    ) {
      toast.error("Debes seleccionar un cliente individual para el reporte de cliente.");
      handleCloseMenu();
      return;
    }
    handleCloseMenu();
    descargarReporte(tipoArchivo, tipoReporte);
  };

  return (
    <Container maxWidth="xl">
      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          {/* CLIENTES */}
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              label="Clientes"
              options={[
                { id: "todos", nombre: "Todos" },
                ...clientes.map((cliente) => ({
                  id: cliente.ID,
                  nombre: `${cliente.NOMBRE} (ID: ${cliente.ID})`,
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={selectedClient}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => setSelectedClient(newValue)}
            />
          </Grid>

          {/* COMUNA ORIGEN */}
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              label="Ciudad Origen"
              options={[
                { IATA: "todas", nombre: "Todas" },
                ...comunas.map((c) => ({
                  IATA: c.IATA,
                  nombre: `${c.NOMBRE} (${c.IATA})`,
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={comunaOrigen}
              onChange={(event, newValue) => setComunaOrigen(newValue)}
            />
          </Grid>

          {/* COMUNA DESTINO */}
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              label="Ciudad Destino"
              options={[
                { IATA: "todas", nombre: "Todas" },
                ...comunas.map((c) => ({
                  IATA: c.IATA,
                  nombre: `${c.NOMBRE} (${c.IATA})`,
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={comunaDestino}
              onChange={(event, newValue) => setComunaDestino(newValue)}
            />
          </Grid>

          {/* TIPO DE ORDEN */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              label="Tipo de Orden"
              value={tipoOrden}
              onChange={(e) => setTipoOrden(e.target.value)}
              fullWidth
              margin="dense"
              variant="outlined"
              size="small"
            >
              <MenuItem value="ENTREGA">Entregas</MenuItem>
              <MenuItem value="RETIRO">Retiros</MenuItem>
              <MenuItem value="todas">Todas</MenuItem>
            </TextField>
          </Grid>

          {/* FECHA DESDE */}
          <Grid item xs={12} sm={6} md={4}>
            <CustomDate
              label="Fecha Desde"
              value={fechaDesde}
              onChange={(newValue) => setFechaDesde(newValue)}
            />
          </Grid>

          {/* FECHA HASTA */}
          <Grid item xs={12} sm={6} md={4}>
            <CustomDate
              label="Fecha Hasta"
              value={fechaHasta}
              onChange={(newValue) => setFechaHasta(newValue)}
            />
          </Grid>

          {/* AGENTE */}
          <Grid item xs={12} sm={6} md={4}>
            <CustomAutocomplete
              label="Agente"
              options={[
                { IATA_AGENTES: "todos", NOMBRE: "todos" },
                ...agentesSLA
              ]}
              getOptionLabel={(option) => option.NOMBRE === "todos" ? "Todos" : option.NOMBRE}
              value={selectedAgente}
              isOptionEqualToValue={(option, value) =>
                option.IATA_AGENTES === value.IATA_AGENTES
              }
              onChange={(event, newValue) => setSelectedAgente(newValue)}
            />
          </Grid>

          {/* BOTÓN PARA DESCARGAR */}
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="contained"
              onClick={handleMenuClick}
              fullWidth
              endIcon={
                loading.tipoReporte ? (
                  <CircularProgress size={16} sx={{ color: "inherit" }} />
                ) : (
                  <FileDownloadIcon />
                )
              }
              disabled={!!loading.tipoReporte}
              sx={{
                backgroundColor: loading.tipoReporte ? "grey.500" : "primary.main",
                "&:hover": {
                  backgroundColor: loading.tipoReporte ? "grey.500" : "primary.dark",
                },
              }}
            >
              {loading.tipoReporte ? "Generando reporte..." : "Descargar Reporte"}
            </Button>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
              {menuItems.map((item, idx) => {
                // Deshabilitar si es reporte "cliente" y "todos" seleccionado
                const isDisabled =
                  item.tipoReporte === "cliente" &&
                  (item.tipoArchivo === "pdf" || item.tipoArchivo === "csv") &&
                  selectedClient?.id === "todos";

                return (
                  <MenuItem
                    key={idx}
                    onClick={() => handleMenuItemClick(item.tipoArchivo, item.tipoReporte)}
                    disabled={isDisabled}
                  >
                    {item.icon} &nbsp; {item.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>
        </Grid>
      </Paper>

      {/* Mensaje si está generando */}
      {loading.tipoReporte && (
        <Typography variant="body2" color="red" align="center" sx={{ marginTop: 2 }}>
          Generando reporte, espera por favor...
        </Typography>
      )}
    </Container>
  );
};

export default memo(Consultakpis);
