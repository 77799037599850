import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Paper, Typography, Avatar, IconButton, Link } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UserProfileModal from "../../utils/UserProfileModal";
import YoutubeVideo from "../../components/YoutubeVideo/YoutubeVideo";

const InicioInformatica = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 1, minHeight: 100, position: "relative" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
            <Typography
              variant="poster"
              sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1.5rem", flexGrow: 1, textAlign: "center" }}
              gutterBottom
            >
              BIENVENIDO {userInfo.name}
            </Typography>
            <IconButton onClick={handleOpenModal} sx={{ flexShrink: 0 }}>
              <Avatar>
                <AccountCircleIcon style={{ color: "#041562" }} />
              </Avatar>
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 1 }}>
            <Typography sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1rem", textAlign: "center" }} gutterBottom>
              Si necesitas algún requerimiento de informática, puedes acceder a este{" "}
              <Link href="https://app.cargoex.cl/requerimientos/" underline="hover" target="_blank" rel="noopener">
                enlace
              </Link>{" "}
              y generar un ticket.
            </Typography>
          </Box>
        </Paper>
        <YoutubeVideo />
        <UserProfileModal open={openModal} handleClose={handleCloseModal} userInfo={userInfo} />
      </Grid>
    </Grid>
  );
};

export default InicioInformatica;
