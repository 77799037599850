import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const getOrdenesManifiestosRutaAgente = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getOrdenesManifiestosRutaAgente`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error Insert Picking:", error);
    throw error;
  }
};
