import React, { useState, memo, useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import { TextField, Paper, MenuItem, Typography } from "@mui/material";
import { obtenerClientesControlSac, obtenerComunas } from "../../services/ReportesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import CustomDate from "../../utils/CustomDate";
import CustomAutocomplete from "../../utils/CustomAutocomplete";
import {
  iniciarDescarga,
  verificarEstadoDescarga,
  descargarArchivo,
  obtenerCabecerasPersonalizadas,
} from "../../services/ReportesServices";
import HeaderSelector from "../../components/ReportesComponentes/componentesConsultas/HeadersReporteMasivo";
import defaultHeaders from "../../utils/defaultHeaders";
import { CentroCostoClientes } from "../../services/RetirosService";
import { obtenerAgentesSLA } from "../../services/ConsultaKpisServices";

const ConsultaPage = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [isLoadingConsulta, setIsLoadingConsulta] = useState(false);
  const [tipoOrden, setTipoOrden] = useState("todas");
  const [estadoEntrega, setEstadoEntrega] = useState("todas");
  const [comunas, setComunas] = useState([]);
  const [comunaOrigen, setComunaOrigen] = useState({ IATA: "todas", nombre: "Todas" });
  const [comunaDestino, setComunaDestino] = useState({ IATA: "todas", nombre: "Todas" });
  const [userHeaders, setUserHeaders] = useState([]);
  const [centrosCosto, setCentrosCosto] = useState([]);
  const [selectedCentroCosto, setSelectedCentroCosto] = useState(null);
  const [isLoadingCentrosCosto, setIsLoadingCentrosCosto] = useState(false);
  const [agentesSLA, setAgentesSLA] = useState([]);
  const [selectedAgente, setSelectedAgente] = useState({
    IATA_AGENTES: "todos",
    NOMBRE: "todos",
  });

  const fetchCentrosCosto = async (clientId) => {
    setIsLoadingCentrosCosto(true);
    try {
      const centros = await CentroCostoClientes(clientId);
      const centrosFormateados = centros
        .filter((centro) => centro.CENTRO_COSTO.trim())
        .map((centro, index) => ({
          id: index,
          nombre: centro.CENTRO_COSTO.trim(),
        }));
      setCentrosCosto(centrosFormateados);
    } catch (error) {
      console.error("Error al obtener los centros de costo:", error);
      toast.error("Error al obtener los centros de costo del cliente.");
    } finally {
      setIsLoadingCentrosCosto(false);
    }
  };

  const formatFechaToYYYYMMDD = (date) => {
    return date.toISOString().split("T")[0];
  };

  const validarCampos = () => {
    if (!selectedClient) {
      toast.error("Debes seleccionar un cliente.");
      return false;
    }
    if (!comunaOrigen) {
      toast.error("Debes seleccionar una comuna de origen.");
      return false;
    }
    if (!comunaDestino) {
      toast.error("Debes seleccionar una comuna de destino.");
      return false;
    }
    if (!fechaDesde) {
      toast.error("Debes seleccionar la fecha desde.");
      return false;
    }
    if (!fechaHasta) {
      toast.error("Debes seleccionar la fecha hasta.");
      return false;
    }
    if (fechaDesde && fechaHasta && fechaDesde > fechaHasta) {
      toast.error("La fecha 'Desde' no puede ser posterior a la fecha 'Hasta'.");
      return false;
    }

    const diffTime = Math.abs(fechaHasta - fechaDesde);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 31) {
      /* en verdad son 31 días para que puedan descargar la data de 1 mes completo */
      toast.error("El rango entre las fechas debe ser de máximo 31 días.");
      return false;
    }

    return true;
  };

  const DescargarPeriodo = async () => {
    const centroCosto =
      selectedCentroCosto && selectedCentroCosto.nombre !== "todos" ? selectedCentroCosto.nombre : null;

    if (!validarCampos()) return;
    setIsLoadingConsulta(true);

    const data = {
      clienteId: selectedClient?.id,
      tipoOrden: tipoOrden,
      estadoEntrega: estadoEntrega,
      comunaOrigen: comunaOrigen?.IATA,
      comunaDestino: comunaDestino?.IATA,
      fechaDesde: fechaDesde ? formatFechaToYYYYMMDD(fechaDesde) : null,
      fechaHasta: fechaHasta ? formatFechaToYYYYMMDD(fechaHasta) : null,
      headersCabeceras: userHeaders,
      centroCosto: centroCosto,
      agenteNombre: selectedAgente && selectedAgente.NOMBRE !== "todos" ? selectedAgente.NOMBRE : "todos",
    };

    try {
      const { downloadId } = await iniciarDescarga(data);
      if (downloadId) {
        await verificarDescarga(downloadId);
      } else {
        console.error("No se recibió un downloadId válido");
        toast.error("Error al iniciar la descarga");
      }
    } catch (error) {
      console.error("Error durante la descarga:", error);
      toast.error("Error al descargar los datos");
    } finally {
      setIsLoadingConsulta(false);
    }
  };

  const verificarDescarga = async (downloadId) => {
    try {
      let estado = await verificarEstadoDescarga(downloadId);
      while (estado.estado !== "completado") {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        estado = await verificarEstadoDescarga(downloadId);
      }
      descargarArchivo(downloadId);
    } catch (error) {
      console.error("Error al verificar el estado de la descarga:", error);
      toast.error("Error al verificar el estado de la descarga.");
    }
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await obtenerClientesControlSac();
        setClientes(response);
      } catch (error) {
        console.error("Error al obtener la lista de clientes:", error);
      }
    };
    fetchClientes();
  }, []);

  useEffect(() => {
    if (selectedClient?.id && selectedClient.id !== "todos") {
      fetchCentrosCosto(selectedClient.id);
    } else {
      setCentrosCosto([]);
      setSelectedCentroCosto(null);
    }
  }, [selectedClient]);

  useEffect(() => {
    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };
    cargarComunas();
  }, []);

  useEffect(() => {
    const cargarAgentesSLA = async () => {
      try {
        const agentesObtenidos = await obtenerAgentesSLA();
        setAgentesSLA(agentesObtenidos);
      } catch (error) {
        console.error("Error al obtener agentes SLA:", error);
      }
    };
    cargarAgentesSLA();
  }, []);

  useEffect(() => {
    if (id_usuario) {
      obtenerCabecerasPersonalizadas(id_usuario)
        .then((cabeceras) => {
          setUserHeaders(cabeceras);
        })
        .catch((error) => {
          console.error("Error al cargar cabeceras del usuario:", error);
          setUserHeaders(defaultHeaders);
        });
    }
  }, [id_usuario]);

  const handleUpdateHeaders = (newHeaders) => {
    setUserHeaders(newHeaders);
  };

  return (
    <Container maxWidth="lg">
      {/* Filters Section */}
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid item xs={12} md={3}>
          <HeaderSelector userId={id_usuario} userHeaders={userHeaders} onUpdateHeaders={handleUpdateHeaders} />
        </Grid>
      </Paper>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              label="Clientes"
              options={[
                { id: "todos", nombre: "Todos" },
                ...clientes.map((cliente) => ({
                  id: cliente.ID,
                  nombre: cliente.NOMBRE + " (ID: " + cliente.ID + ")",
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={selectedClient}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                setSelectedClient(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              label="Comuna Origen"
              options={[
                { IATA: "todas", nombre: "Todas" },
                ...comunas.map((comuna) => ({
                  IATA: comuna.IATA,
                  nombre: comuna.NOMBRE + " (" + comuna.IATA + ")",
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={comunaOrigen}
              onChange={(event, newValue) => setComunaOrigen(newValue)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              label="Comuna Destino"
              options={[
                { IATA: "todas", nombre: "Todas" },
                ...comunas.map((comuna) => ({
                  IATA: comuna.IATA,
                  nombre: comuna.NOMBRE + " (" + comuna.IATA + ")",
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={comunaDestino}
              onChange={(event, newValue) => setComunaDestino(newValue)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              label="Tipo de orden"
              value={tipoOrden}
              onChange={(e) => setTipoOrden(e.target.value)}
              fullWidth
              margin="dense"
              variant="outlined"
              size="small"
            >
              <MenuItem value="ENTREGA">Entregas</MenuItem>
              <MenuItem value="RETIRO">Retiros</MenuItem>
              <MenuItem value="todas">Todas</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              label="Estado actual"
              value={estadoEntrega}
              onChange={(e) => setEstadoEntrega(e.target.value)}
              fullWidth
              margin="dense"
              variant="outlined"
              size="small"
            >
              <MenuItem value="ENTREGADAS">Entregadas</MenuItem>
              <MenuItem value="NO ENTREGADAS">No Entregadas</MenuItem>
              <MenuItem value="todas">Todas</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              label="Canal"
              options={centrosCosto}
              getOptionLabel={(option) => option.nombre}
              value={selectedCentroCosto}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => setSelectedCentroCosto(newValue)}
              isLoading={isLoadingCentrosCosto}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              label="Agente"
              options={[{ IATA_AGENTES: "todos", NOMBRE: "todos" }, ...agentesSLA]}
              getOptionLabel={(option) => (option.NOMBRE === "todos" ? "Todos" : option.NOMBRE)}
              value={selectedAgente}
              isOptionEqualToValue={(option, value) => option.IATA_AGENTES === value.IATA_AGENTES}
              onChange={(event, newValue) => setSelectedAgente(newValue)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomDate
              label="Fecha Desde"
              value={fechaDesde}
              onChange={(newValue) => {
                setFechaDesde(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomDate
              label="Fecha Hasta"
              value={fechaHasta}
              onChange={(newValue) => {
                setFechaHasta(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              sx={{
                backgroundColor: "#041562",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#031042",
                },
                marginTop: 1,
              }}
              onClick={() => DescargarPeriodo(true)}
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoadingConsulta}
            >
              {isLoadingConsulta ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Descargar"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {/* Mensaje de espera durante la descarga */}
      {isLoadingConsulta && (
        <Typography variant="body2" color="red" align="center" sx={{ marginTop: 2 }}>
          La descarga puede tardar bastante dependiendo del rango de fechas seleccionado. Por ejemplo, para un mes con
          aproximadamente 50,000 registros, el proceso puede tomar cerca de 2 minutos. Por favor, espera...
        </Typography>
      )}
    </Container>
  );
};

export default memo(ConsultaPage);
