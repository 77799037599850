import React from "react";
import { useNavigate } from "react-router-dom";
import {Button, Paper} from "@mui/material";
import AccessoDenegado from "../../src/components/assets/img/imagenes/AccesoDenegado.jpg";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/"); // o la ruta principal que quieras
  };

  return (
    <div style={styles.container}>
    <h1 style={styles.heading}>403 - Acceso Denegado</h1>
    <Paper style={styles.paper}>
      <img 
        src={AccessoDenegado} 
        alt="Acceso Denegado" 
        style={styles.image} 
      />
    </Paper>
    <p>No cuentas con los permisos necesarios para ver esta página.</p>
    <Button onClick={goHome}>Volver al Inicio</Button>
  </div>
  );
};
const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
    // Ajusta márgenes o paddings según necesites
  },
  heading: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  paper: {
    display: 'inline-block', 
    padding: '20px', 
    // textAlign: 'center' si lo deseas
  },
  image: {
    maxWidth: '300px',    // Ajusta el ancho máximo
    width: '100%',        // Se adaptará a su contenedor, sin pasarse de 300px
    height: 'auto',       // Mantiene la proporción
    display: 'block',     // Para poder centrar con 'margin: 0 auto'
    margin: '0 auto',     
  },
};

export default Unauthorized;
