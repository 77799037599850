import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { useSelector } from 'react-redux';
import {
  Box, Typography, Collapse, Tooltip, Button, Divider, Dialog,
  DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import {
  Business as BusinessIcon,
  Person as PersonIcon,
  AccessTime as AccessTimeIcon,
  LocationOn as LocationOnIcon,
  CheckCircle as CheckCircleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { Chip } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Search as SearchIcon } from '@mui/icons-material';

import { getClients } from '../../../services/ReporteAutomaticoService';

import RetiroForm from './FormularioRetiro';
import {BeautifulModal} from './UtilitiesComponents';
import { deleteAllRecoleccionesPCE, deleteRecoleccionPCESupport, getChoferes, getChoferesRecoleccion, getClientesDisponiblesParaNotificacion, getRecoleccionesPCEjecutadas, getRecoleccionesPendientesByCliente} from '../../../services/OperacionesNotificadorService';

const colors = {red:"#DA251C", blue:"#041562",green:"#046A38",orange:"#FF7700" }

// Primero, definir la lista de usuarios autorizados fuera del componente
const AUTHORIZED_EMAILS = [
  "matias.urrutia@cargoex.cl"
];

// Clave para almacenar la preferencia de muteo en localStorage
const MUTE_NOTIFICATIONS_KEY = 'pickupNotificationsMuted';

/**
 * @component RetiroListCompletados
 * @description Componente que muestra una lista de retiros completados agrupados por cliente.
 * Permite visualizar los retiros que ya han sido marcados como completados y ofrece la opción
 * de desmarcarlos si es necesario.
 * 
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.retiros - Objeto que contiene los retiros agregados
 * @param {Object} props.modalHandler - Manejador de modales para la interfaz
 * @param {Object} props.requiredData - Datos requeridos para el funcionamiento del componente
 * @param {Function} props.refetchData - Función para recargar los datos
 */
const RetiroListCompletados = ({ retiros, modalHandler, requiredData, refetchData }) => {
  const { retirosAgregados } = retiros;
  // Estado para manejar la expansión/colapso de secciones de clientes
  const [expandedClients, setExpandedClients] = useState({});
  // Estado para almacenar los retiros completados procesados
  const [retirosCompletados, setRetirosCompletados] = useState({});
  
  /**
   * Alterna la expansión/colapso de la sección de un cliente
   * @param {string} clientName - Nombre del cliente a alternar
   */
  const toggleClient = (clientName) => {
    setExpandedClients(prev => ({
      ...prev,
      [clientName]: !prev[clientName]
    }));
  };

  // Efecto para procesar y actualizar los retiros completados
  useEffect(() => {
    // Crear un nuevo objeto para los retiros completados
    const completados = {};
    
    // Procesar los retiros y extraer los completados
    Object.entries(retirosAgregados).forEach(([clientName, data]) => {
      // Verificar existencia de retiros completados
      if (data.completadas && Array.isArray(data.completadas) && data.completadas.length > 0) {
        completados[clientName] = {
          completadas: data.completadas,
          clienteInfo: data.clienteInfo || {
            nombre: data.completadas[0].nombre,
            direccion: data.completadas[0].direccion,
            ID: data.completadas[0].COD_CLIENTE
          }
        };
      }
    });
    setRetirosCompletados(completados);
  }, [retirosAgregados]);

  return (
    <Box sx={{ p: 2 }}>
      {Object.entries(retirosCompletados).map(([clientName, data]) => {
        const isExpanded = expandedClients[clientName];

        return (
          <Box
            key={clientName}
            sx={{
              mb: 2,
              backgroundColor: 'white',
              borderRadius: 1,
              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              overflow: 'hidden',
            }}
          >
            {/* Header Colapsable */}
            <Box
              onClick={() => toggleClient(clientName)}
              sx={{
                p: 1.5,
                backgroundColor: colors.green,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: `${colors.green}dd`,
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <BusinessIcon sx={{ fontSize: '1.2rem' }} />
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {clientName.split(' - ')[0]}
                  </Typography>
                  <Typography variant="caption" sx={{ display: 'block', fontSize: '0.7rem', opacity: 0.9 }}>
                    {clientName.split(' - ')[1] || 'Sin dirección'}
                  </Typography>
                </Box>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Chip
                  size="small"
                  icon={<CheckCircleIcon sx={{ fontSize: '0.9rem !important', color: `${colors.green} !important` }} />}
                  label={`Completados (${data.completadas.length})`}
                  sx={{
                    backgroundColor: 'white',
                    color: colors.green,
                    fontWeight: 'bold'
                  }}
                />
                <KeyboardArrowDownIcon
                  sx={{
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </Box>
            </Box>

            {/* Contenido Colapsable */}
            <Collapse in={isExpanded}>
              <Box sx={{ p: 2 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: colors.green,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <CheckCircleIcon sx={{ fontSize: '1rem' }} /> 
                    Pick up 's Completados ({data.completadas.length})
                  </Typography>

                  <Box 
                    sx={{ 
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      overflowX: 'auto',
                      pb: 1,
                      '&::-webkit-scrollbar': {
                        height: '6px',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f5f5f5',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#bdbdbd',
                        borderRadius: '4px',
                        '&:hover': {
                          backgroundColor: '#9e9e9e'
                        }
                      }
                    }}
                  >
                    {data.completadas.map((retiro, idx) => (
                      <Box 
                        key={`completado-${idx}`}
                        sx={{ 
                          minWidth: '250px',
                          maxWidth: '300px',
                          flex: '0 0 auto'
                        }}
                      >
                        <RetiroCard 
                          retiro={retiro} 
                          type="completado"
                          showTime={true}
                          refetchData={refetchData}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Collapse>
          </Box>
        );
      })}

      {/* Mensaje cuando no hay retiros completados */}
      {Object.keys(retirosCompletados).length === 0 && (
        <Box sx={{ 
          py: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          color: 'text.secondary'
        }}>
          <CheckCircleIcon sx={{ fontSize: '3rem', color: colors.green, mb: 2 }} />
          <Typography variant="h6">
            No hay pick up 's completados
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Los pick up 's completados aparecerán aquí
          </Typography>
        </Box>
      )}
    </Box>
  );
};

/**
 * @component RetiroList
 * @description Componente principal para la gestión de retiros pendientes.
 * Muestra una lista de retiros agrupados por cliente, separados en retrasados y a tiempo.
 * Permite eliminar retiros individuales o por grupos, y editar retiros existentes.
 * 
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.retiros - Objeto que contiene los retiros agregados
 * @param {Object} props.modalHandler - Manejador de modales para la interfaz
 * @param {Object} props.requiredData - Datos requeridos para el funcionamiento
 * @param {Function} props.refetchData - Función para recargar los datos
 */
const RetiroList = ({ retiros, modalHandler, requiredData, refetchData }) => {
  // Extraer retirosAgregados del objeto retiros
  const { retirosAgregados } = retiros;

  // Estados para manejar la expansión de clientes y el diálogo de eliminación
  const [expandedClients, setExpandedClients] = useState({});
  const [deleteDialog, setDeleteDialog] = useState({ 
    open: false, 
    clientData: null,
    step: 'select', // Paso actual del diálogo: 'select' para selección, 'confirm' para confirmación
    selectedType: null // Tipo de retiros a eliminar: 'retrasados', 'aTiempo', o 'todos'
  });
  
  /**
   * Alterna la expansión/colapso de la sección de un cliente
   * @param {string} clientName - Nombre del cliente a alternar
   */
  const toggleClient = (clientName) => {
    setExpandedClients(prev => ({
      ...prev,
      [clientName]: !prev[clientName]
    }));
  };

  /**
   * Maneja el click en el botón de eliminar
   * @param {Event} e - Evento del click
   * @param {Object} clientData - Datos del cliente a eliminar
   */
  const handleDeleteClick = (e, clientData) => {
    e.stopPropagation();
    
    // Extraer retiros pendientes del cliente
    const pendingWithdrawals = {
      retrasados: clientData.retrasados || {},
      aTiempo: clientData.aTiempo || {}
    };

    // Calcular totales de retiros pendientes
    const totalRetrasados = Object.values(pendingWithdrawals.retrasados).reduce((sum, arr) => sum + arr.length, 0);
    const totalATiempo = Object.values(pendingWithdrawals.aTiempo).reduce((sum, arr) => sum + arr.length, 0);

    // Extraer información del cliente
    let codCliente = null;
    let nombreCliente = clientData[0].split(' - ')[0];
    let direccionCliente = clientData[0].split(' - ')[1] || '';
    
    // Buscar el código de cliente en retiros retrasados
    for (const hour in pendingWithdrawals.retrasados) {
      const retiros = pendingWithdrawals.retrasados[hour];
      if (retiros && retiros.length > 0) {
        codCliente = retiros[0].COD_CLIENTE;
        break;
      }
    }
    
    // Si no se encuentra en retrasados, buscar en retiros a tiempo
    if (!codCliente) {
      for (const hour in pendingWithdrawals.aTiempo) {
        const retiros = pendingWithdrawals.aTiempo[hour];
        if (retiros && retiros.length > 0) {
          codCliente = retiros[0].COD_CLIENTE;
          break;
        }
      }
    }
    
    // Configurar y abrir el diálogo de eliminación
    setDeleteDialog({ 
      open: true,
      step: 'select',
      selectedType: null,
      clientData: {
        nombre: nombreCliente,
        direccion: direccionCliente,
        pendientes: pendingWithdrawals,
        totalPendientes: {
          retrasados: totalRetrasados,
          aTiempo: totalATiempo,
          total: totalRetrasados + totalATiempo
        },
        codCliente: codCliente
      }
    });
  };

  /**
   * Maneja la selección del tipo de retiros a eliminar
   * @param {string} type - Tipo de retiros ('retrasados', 'aTiempo', 'todos')
   */
  const handleTypeSelect = (type) => {
    setDeleteDialog(prev => ({
      ...prev,
      step: 'confirm',
      selectedType: type
    }));
  };

  /**
   * Confirma y ejecuta la eliminación de retiros
   */
  const handleDeleteConfirm = async () => {
    try {
      // Ejecutar la eliminación según el tipo seleccionado
      if(deleteDialog.selectedType === 'retrasados'){
        await deleteAllRecoleccionesPCE("deleteAllPending", deleteDialog.clientData.pendientes.retrasados);
      } else if (deleteDialog.selectedType === 'aTiempo'){
        await deleteAllRecoleccionesPCE("deleteAllOK", deleteDialog.clientData.pendientes.aTiempo);
      } else if(deleteDialog.selectedType === 'todos'){
        await deleteAllRecoleccionesPCE("deleteAll", deleteDialog.clientData);
      }

      // Esperar para asegurar que la eliminación se completó
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Recargar datos
      await refetchData();
      
      // Resetear el diálogo
      setDeleteDialog({ 
        open: false, 
        clientData: null, 
        step: 'select', 
        selectedType: null 
      });
    } catch (error) {
      console.error('Error al eliminar pick up:', error);
    }
  };

  /**
   * Cierra el diálogo de eliminación
   */
  const handleDialogClose = () => {
    setDeleteDialog({ 
      open: false, 
      clientData: null, 
      step: 'select', 
      selectedType: null 
    });
  };

  /**
   * Maneja la edición de un retiro
   * @param {Object} retiro - Datos del retiro a editar
   */
  const handleEditRetiro = (retiro) => {
    // Preparar datos iniciales para el formulario
    const initialData = {
      client: retiro.nombre,
      clientId: retiro.COD_CLIENTE,
      direccion: retiro.direccion,
      ciudad: retiro.ciudad || '',
      driver: retiro.chofer.NOMBRE,
      vehicle: retiro.chofer.PATENTE_VEHICULO,
      hora: retiro.HORA_RECOLECCION.substring(0, 5),
      tipo_chofer: retiro.chofer.TIPO_CHOFER,
      chofer_id: retiro.chofer.COD_CHOFER,
      id_recoleccion: retiro.ID_RECOLECCION,
      id_recoleccion_chofer: retiro.ID_RECOLECCION_CHOFER
    };
    
    // Abrir formulario con los datos iniciales
    if (modalHandler.onShowForm) {
      modalHandler.onShowForm(initialData);
    }
  };

  /**
   * Calcula el total de retiros retrasados y a tiempo
   * @param {Object} data - Datos de retiros del cliente
   * @returns {Object} Objeto con conteos de retiros
   */
  const getTotalRetiros = (data) => {
    const retrasadosCount = Object.values(data.retrasados)
      .reduce((sum, arr) => sum + arr.length, 0);
    const aTiempoCount = Object.values(data.aTiempo)
      .reduce((sum, arr) => sum + arr.length, 0);
    return { retrasadosCount, aTiempoCount };
  };


  // Filtrar clientes que tienen retiros pendientes
  const clientesConPendientes = Object.entries(retirosAgregados).filter(
    ([_, data]) => 
      Object.keys(data.retrasados).length > 0 || 
      Object.keys(data.aTiempo).length > 0
  );

  return (
    <Box sx={{ p: 2 }}>
      {clientesConPendientes.map(([clientName, data]) => {
        const { retrasadosCount, aTiempoCount } = getTotalRetiros(data);
        const isExpanded = expandedClients[clientName];
        return (
          <Box
            key={clientName}
            sx={{
              mb: 2,
              backgroundColor: 'white',
              borderRadius: 1,
              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              overflow: 'hidden',
            }}
          >
            {/* Header Colapsable */}
            <Box
              onClick={() => toggleClient(clientName)}
              sx={{
                p: 1.5,
                backgroundColor: colors.blue,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: `${colors.blue}dd`,
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <BusinessIcon sx={{ fontSize: '1.2rem' }} />
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {clientName.split(' - ')[0]}
                  </Typography>
                  <Typography variant="caption" sx={{ display: 'block', fontSize: '0.7rem', opacity: 0.9 }}>
                    {clientName.split(' - ')[1] || 'Sin dirección'}
                  </Typography>
                </Box>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {retrasadosCount > 0 && (
                  <Chip
                    size="small"
                    icon={<TimerOffIcon sx={{ fontSize: '0.9rem !important', color: `${colors.red} !important` }} />}
                    label={`${retrasadosCount} retrasados`}
                    sx={{
                      backgroundColor: 'white',
                      color: colors.red,
                      fontWeight: 'bold',
                      '& .MuiChip-icon': { color: colors.red }
                    }}
                  />
                )}
                {aTiempoCount > 0 && (
                  <Chip
                    size="small"
                    icon={<CheckCircleIcon sx={{ fontSize: '0.9rem !important', color: `${colors.green} !important` }} />}
                    label={`${aTiempoCount} a tiempo`}
                    sx={{
                      backgroundColor: 'white',
                      color: colors.green,
                      fontWeight: 'bold',
                    }}
                  />
                )}
                <Chip
                  size="small"
                  icon={<TimerOffIcon sx={{ fontSize: '0.9rem !important', color: `${colors.orange} !important` }} />}
                  label="Pendiente"
                  sx={{
                    backgroundColor: 'white',
                    color: colors.orange,
                    fontWeight: 'bold',
                  }}
                />
                <Tooltip title="Eliminar cliente">
                  <DeleteIcon
                    onClick={(e) => handleDeleteClick(e, {
                      0: clientName,
                      retrasados: data.retrasados,
                      aTiempo: data.aTiempo
                    })}
                    sx={{
                      fontSize: '1.2rem',
                      cursor: 'pointer',
                      color: 'white',
                      '&:hover': {
                        color: '#ffcdd2'
                      }
                    }}
                  />
                </Tooltip>
                <KeyboardArrowDownIcon
                  sx={{
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </Box>
            </Box>

            {/* Contenido Colapsable */}
            <Collapse in={isExpanded}>
              <Box sx={{ p: 2 }}>
                {/* Sección Retrasados */}
                {Object.keys(data.retrasados).length > 0 && (
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: colors.red,
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 1,
                      }}
                    >
                      <TimerOffIcon sx={{ fontSize: '1rem' }} /> Pick up 's Retrasados
                    </Typography>

                    <Box 
                      sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        overflowX: 'auto',
                        pb: 1,
                        '&::-webkit-scrollbar': {
                          height: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: '#f5f5f5',
                          borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#bdbdbd',
                          borderRadius: '4px',
                          '&:hover': {
                            backgroundColor: '#9e9e9e'
                          }
                        }
                      }}
                    >
                      {Object.entries(data.retrasados).flatMap(([hour, retiros]) => 
                        retiros.map((retiro, idx) => (
                          <Box 
                            key={`retrasado-${hour}-${idx}`}
                            sx={{ 
                              minWidth: '250px',
                              maxWidth: '300px',
                              flex: '0 0 auto'
                            }}
                          >
                            <RetiroCard 
                              retiro={retiro} 
                              type="retrasado"
                              showTime={true}
                              onEdit={handleEditRetiro}
                              refetchData={refetchData}
                            />
                          </Box>
                        ))
                      )}
                    </Box>
                  </Box>
                )}

                {/* Sección A Tiempo */}
                {Object.keys(data.aTiempo).length > 0 && (
                  <Box>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: colors.green,
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 1,
                      }}
                    >
                      <CheckCircleIcon sx={{ fontSize: '1rem' }} /> Pick up 's A Tiempo
                    </Typography>

                    <Box 
                      sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        overflowX: 'auto',
                        pb: 1,
                        '&::-webkit-scrollbar': {
                          height: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: '#f5f5f5',
                          borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#bdbdbd',
                          borderRadius: '4px',
                          '&:hover': {
                            backgroundColor: '#9e9e9e'
                          }
                        }
                      }}
                    >
                      {Object.entries(data.aTiempo).flatMap(([hour, retiros]) => 
                        retiros.map((retiro, idx) => (
                          <Box 
                            key={`aTiempo-${hour}-${idx}`}
                            sx={{ 
                              minWidth: '250px',
                              maxWidth: '300px',
                              flex: '0 0 auto'
                            }}
                          >
                            <RetiroCard 
                              retiro={retiro} 
                              type="aTiempo" 
                              showTime={true}
                              onEdit={handleEditRetiro}
                              refetchData={refetchData}
                            />
                          </Box>
                        ))
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Collapse>
          </Box>
        );
      })}

      {/* Mensaje cuando no hay retiros pendientes */}
      {clientesConPendientes.length === 0 && (
        <Box sx={{ 
          py: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          color: 'text.secondary'
        }}>
          <CheckCircleIcon sx={{ fontSize: '3rem', color: colors.green, mb: 2 }} />
          <Typography variant="h6">
            No hay pick up 's pendientes
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Todos los pick up 's han sido completados
          </Typography>
        </Box>
      )}

      {/* Diálogo de confirmación */}
      <Dialog
        open={deleteDialog.open}
        onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ 
          backgroundColor: colors.blue, 
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
            {deleteDialog.clientData?.nombre}
          </Typography>
          {deleteDialog.clientData?.direccion && (
            <Typography variant="caption" component="div" sx={{ opacity: 0.9 }}>
              {deleteDialog.clientData.direccion}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {deleteDialog.step === 'select' ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <Typography>
                Seleccione qué tipo de pick up 's desea eliminar para el cliente "{deleteDialog.clientData?.nombre}":
          </Typography>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleTypeSelect('retrasados')}
                disabled={!Object.keys(deleteDialog.clientData?.pendientes.retrasados || {}).length}
                startIcon={<TimerOffIcon />}
                sx={{ justifyContent: 'flex-start' }}
              >
                Retrasados ({Object.values(deleteDialog.clientData?.pendientes.retrasados || {}).flat().length})
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => handleTypeSelect('aTiempo')}
                disabled={!Object.keys(deleteDialog.clientData?.pendientes.aTiempo || {}).length}
                startIcon={<CheckCircleIcon />}
                sx={{ justifyContent: 'flex-start' }}
              >
                A tiempo ({Object.values(deleteDialog.clientData?.pendientes.aTiempo || {}).flat().length})
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleTypeSelect('todos')}
                startIcon={<DeleteIcon />}
                sx={{ justifyContent: 'flex-start' }}
              >
                Todos los pick up 's ({deleteDialog.clientData?.totalPendientes.total})
              </Button>
            </Box>
          ) : (
            <>
              <Typography>
                ¿Está seguro que desea eliminar {
                  deleteDialog.selectedType === 'todos' 
                    ? 'todos los pick up' 
                    : `los pick up ${deleteDialog.selectedType === 'retrasados' ? 'retrasados' : 'a tiempo'}`
                } del cliente "{deleteDialog.clientData?.nombre}"?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  Se eliminarán:
                </Typography>
                <Box sx={{ pl: 2, mt: 1 }}>
                  {(deleteDialog.selectedType === 'retrasados' || deleteDialog.selectedType === 'todos') && 
                    deleteDialog.clientData?.pendientes.retrasados && 
                    Object.keys(deleteDialog.clientData.pendientes.retrasados).length > 0 && (
                    <Typography variant="body2" color="error">
                      • Retrasados: {Object.values(deleteDialog.clientData.pendientes.retrasados).flat().length}
                    </Typography>
                  )}
                  {(deleteDialog.selectedType === 'aTiempo' || deleteDialog.selectedType === 'todos') && 
                    deleteDialog.clientData?.pendientes.aTiempo && 
                    Object.keys(deleteDialog.clientData.pendientes.aTiempo).length > 0 && (
                    <Typography variant="body2" color="success.main">
                      • A tiempo: {Object.values(deleteDialog.clientData.pendientes.aTiempo).flat().length}
                    </Typography>
                  )}
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleDialogClose}
            color="primary"
          >
            Cancelar
          </Button>
          {deleteDialog.step === 'confirm' && (
          <Button 
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Eliminar
          </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Componente de tarjeta separado para mejor organización
const RetiroCard = ({ retiro, type, showTime = true, onEdit, refetchData }) => {
  // Estado para el diálogo de confirmación
  const [confirmDialog, setConfirmDialog] = useState({ open: false });
  const [isUpdating, setIsUpdating] = useState(false);

  // Función para abrir el diálogo de confirmación
  const handleUpdateStatusClick = (e) => {
    e.stopPropagation(); // Prevenir que se active el onClick del card
    setConfirmDialog({ open: true });
  };

  const handleUpdateStatus = async () => {
    try {
      setIsUpdating(true);
      
      // Primero eliminamos la recolección completada
      await deleteRecoleccionPCESupport(retiro.ID_PICKUP, retiro.ID_PICKUP_CHOFER);
      

      // Cerrar el diálogo
      setConfirmDialog({ open: false });
      
      // Mostrar mensaje de éxito
      toast.success("Recolección desmarcada como completada exitosamente");
      
      // Recargar datos si existe la función
      if (refetchData) {
        await refetchData();
      }
    } catch (error) {
      console.error("Error al desmarcar la recolección:", error);
      toast.error("Error al desmarcar la recolección como completada");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box
      sx={{
        p: 1.5,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        backgroundColor: type === 'retrasado' 
          ? '#fff5f5' 
          : type === 'completado'
            ? '#e8f5e9'
            : '#f5fff5',
        '&:hover': {
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          transform: onEdit ? 'translateY(-1px)' : 'none',
          transition: 'all 0.2s ease',
          cursor: onEdit ? 'pointer' : 'default'
        }
      }}
      onClick={() => onEdit && onEdit(retiro)}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        {showTime ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <AccessTimeIcon 
              sx={{ 
                fontSize: '0.9rem',
                color: type === 'retrasado' 
                  ? colors.red 
                  : type === 'completado'
                    ? colors.green
                    : colors.green 
              }} 
            />
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {retiro.HORA_RECOLECCION.substring(0, 5)}
            </Typography>
              {type === 'completado' && (
                <div 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdateStatusClick(e);
                  }}
                  style={{ 
                    cursor: 'pointer',
                    zIndex: 10
                  }}
                >
                  <Chip 
                    size="small"
                    label="Completado"
                    sx={{ 
                      ml: 1,
                      height: 20, 
                      fontSize: '0.6rem',
                      backgroundColor: colors.green,
                      color: 'white',
                      '&:hover': {
                        backgroundColor: `${colors.green}cc`,
                        transform: 'scale(1.05)'
                      },
                      transition: 'all 0.2s ease'
                    }}
                  />
                </div>
              )}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <PersonIcon sx={{ fontSize: '0.9rem', color: colors.blue }} />
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {`Chofer ${retiro.chofer.COD_CHOFER}`}
            </Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
        <PersonIcon sx={{ fontSize: '0.9rem', color: colors.blue }} />
        <Typography variant="body2" noWrap>{retiro.chofer.NOMBRE}</Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <LocalShippingIcon sx={{ fontSize: '0.9rem', color: colors.blue }} />
        <Typography variant="body2">{retiro.chofer.PATENTE_VEHICULO}</Typography>
      </Box>

      <Tooltip title={retiro.direccion} arrow>
        <Typography
          variant="body2"
          sx={{
            mt: 0.5,
            color: 'text.secondary',
            fontSize: '0.75rem',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <LocationOnIcon sx={{ fontSize: '0.9rem' }} />
          {retiro.direccion}
        </Typography>
      </Tooltip>
      {type === 'completado' && (
        retiro.EJECUTO_PICKUP === "COMPLETADO" ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 1 }}>
            <Typography variant="body2" sx={{ color: colors.green, fontWeight: 'bold' }}>
              Bultos: {retiro.BULTOS_INFORMADOS_PICKUP}
            </Typography>
          </Box>
        ) : retiro.EJECUTO_PICKUP === "SIN RETIROS" ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 1 }}>
            <Typography variant="body2" sx={{ color: colors.red, fontWeight: 'bold' }}>
              SIN RETIROS
            </Typography>
          </Box>
        ) : null
      )}

      {/* Diálogo de confirmación */}
      <Dialog
        open={confirmDialog.open}
        onClose={() => !isUpdating && setConfirmDialog({ open: false })}
        onClick={(e) => e.stopPropagation()}
        sx={{ zIndex: 9999 }}
      >
        <DialogTitle>Confirmar acción</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Está seguro que desea desmarcar como completado el pick up de {retiro.chofer.NOMBRE} a las {retiro.HORA_RECOLECCION.substring(0, 5)}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={(e) => {
              e.stopPropagation();
              setConfirmDialog({ open: false });
            }}
            color="primary"
            disabled={isUpdating}
          >
            Cancelar
          </Button>
          <LoadingButton 
            onClick={(e) => {
              e.stopPropagation();
              handleUpdateStatus();
            }}
            color="error"
            variant="contained"
            loading={isUpdating}
          >
            Desmarcar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

/**
 * @component RetiroListClientesDisponibles
 * @description Componente que muestra una lista de clientes disponibles para notificación.
 * Permite visualizar y seleccionar clientes para crear nuevos retiros.
 * 
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.modalHandler - Manejador de modales para la interfaz
 * @param {Object} props.requiredData - Datos requeridos para el funcionamiento
 * @param {Function} props.refetchData - Función para recargar los datos
 */
const RetiroListClientesDisponibles = ({ modalHandler, requiredData, refetchData, retiros }) => {
  const [clientesDisponibles, setClientesDisponibles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedClients, setExpandedClients] = useState({});
  const [estadisticas, setEstadisticas] = useState({
    conRecoleccion: 0,
    sinRecoleccion: 0,
    direccionesConRecoleccion: 0,
    direccionesSinRecoleccion: 0
  });
  const [filtros, setFiltros] = useState({
    clientesConRecoleccion: false,
    clientesSinRecoleccion: false,
    direccionesConRecoleccion: false,
    direccionesSinRecoleccion: false
  });
  const [searchText, setSearchText] = useState('');
  
  // Extraer retirosAgregados si existe
  const retirosAgregados = useMemo(() => {
    return retiros?.retirosAgregados || {};
  }, [retiros?.retirosAgregados]);

  // Función para alternar filtros
  const toggleFiltro = (filtro) => {
    setFiltros(prev => ({
      ...Object.fromEntries(Object.keys(prev).map(key => [key, false])), // Resetear todos los filtros
      [filtro]: !prev[filtro] // Alternar el filtro seleccionado
    }));
  };

  // Filtrar clientes según los filtros activos y texto de búsqueda
  const clientesFiltrados = useMemo(() => {
    let filtered = clientesDisponibles;

    // Aplicar filtro de texto si existe
    if (searchText.trim()) {
      const searchLower = searchText.toLowerCase().trim();
      filtered = filtered.filter(cliente => {
        const matchNombre = cliente.NOMBRE.toLowerCase().includes(searchLower);
        const matchDirecciones = cliente.direcciones.some(dir => 
          dir.direccion.toLowerCase().includes(searchLower)
        );
        const matchRut = cliente.RUT.toLowerCase().includes(searchLower);
        return matchNombre || matchDirecciones || matchRut;
      });
    }

    // Si no hay filtros activos, retornar resultados de búsqueda
    if (!Object.values(filtros).some(Boolean)) {
      return filtered;
    }

    // Aplicar filtros de estado
    return filtered.filter(cliente => {
      if (filtros.clientesConRecoleccion && cliente.tieneRecoleccion) return true;
      if (filtros.clientesSinRecoleccion && !cliente.tieneRecoleccion) return true;
      
      // Filtrar por direcciones
      const tieneDireccionConRecoleccion = cliente.direcciones.some(dir => dir.tieneRecoleccion);
      const tieneDireccionSinRecoleccion = cliente.direcciones.some(dir => !dir.tieneRecoleccion);
      
      if (filtros.direccionesConRecoleccion && tieneDireccionConRecoleccion) return true;
      if (filtros.direccionesSinRecoleccion && tieneDireccionSinRecoleccion) return true;
      
      return false;
    });
  }, [clientesDisponibles, filtros, searchText]);
  
  // Efecto para cargar los clientes disponibles
  useEffect(() => {
    const fetchClientesDisponibles = async () => {
      setLoading(true);
      try {
        const result = await getClientesDisponiblesParaNotificacion();
        if (result && result.success && Array.isArray(result.data)) {
          // Procesar clientes disponibles
          const clientesProcesados = result.data.map(cliente => {
            // Obtener las direcciones únicas del cliente
            let direccionesUnicas = [];
            let direccionClientePrincipal = cliente.DIRECCION_CLIENTE || null;
            
            // Normalizar y limpiar direcciones
            const normalizeDir = (dir) => dir?.trim().toLowerCase() || '';
            
            // Verificar si una dirección ya existe en el array (similar o idéntica)
            const isDuplicateDir = (dir, array) => {
              const normalizedDir = normalizeDir(dir);
              return array.some(item => {
                const itemNorm = normalizeDir(item.direccion);
                return itemNorm === normalizedDir || 
                       itemNorm.includes(normalizedDir) || 
                       normalizedDir.includes(itemNorm);
              });
            };
            
            // Si hay una dirección principal del cliente, agregarla primero
            if (direccionClientePrincipal) {
              direccionesUnicas.push({
                id: 'principal',
                direccion: direccionClientePrincipal.trim(),
                canal: '',
                esPrincipal: true,
                tieneRecoleccion: verificarRecoleccionDireccion(cliente.NOMBRE, direccionClientePrincipal.trim(), cliente.COD_CLIENTE)
              });
            }
            
            if (cliente.DIRECCIONES && Array.isArray(cliente.DIRECCIONES)) {
              // Usar un Map para evitar direcciones duplicadas (por ID y por texto)
              const direccionesMap = new Map();
              
              cliente.DIRECCIONES.forEach(dir => {
                if (!dir.DIRECCION) return;
                
                const direccionLimpia = dir.DIRECCION.trim();
                
                // Evitar duplicar la dirección principal
                if (direccionClientePrincipal && isDuplicateDir(direccionLimpia, [{ direccion: direccionClientePrincipal }])) {
                  // Actualizar la entrada principal si esta tiene más detalles
                  if (dir.CANAL && !direccionesUnicas[0].canal) {
                    direccionesUnicas[0].canal = dir.CANAL;
                    direccionesUnicas[0].id = dir.ID_DIRECCION;
                  }
                  return;
                }
                
                // Verificar si esta dirección tiene recolección asignada
                const tieneRecoleccion = verificarRecoleccionDireccion(cliente.NOMBRE, direccionLimpia, cliente.COD_CLIENTE);
                
                // Clave única para esta dirección
                const key = `${direccionLimpia}_${dir.CANAL || ''}`;
                
                if (!direccionesMap.has(key)) {
                  direccionesMap.set(key, {
                    id: dir.ID_DIRECCION,
                    direccion: direccionLimpia,
                    canal: dir.CANAL || '',
                    esPrincipal: dir.ES_DIRECCION_PRINCIPAL || false,
                    tieneRecoleccion
                  });
                }
              });
              
              // Convertir el Map a array y agregar a direccionesUnicas
              direccionesUnicas = [...direccionesUnicas, ...Array.from(direccionesMap.values())];
            } else if (cliente.DIRECCION && !direccionClientePrincipal) {
              // Fallback para clientes que no tienen array DIRECCIONES ni DIRECCION_CLIENTE
              const direccionLimpia = cliente.DIRECCION.trim();
              const tieneRecoleccion = verificarRecoleccionDireccion(cliente.NOMBRE, direccionLimpia, cliente.COD_CLIENTE);
              
              direccionesUnicas.push({
                id: null,
                direccion: direccionLimpia,
                canal: '',
                esPrincipal: true,
                tieneRecoleccion
              });
            }
            
            // Asegurarse de que no hay direcciones duplicadas
            const uniqueDirs = [];
            direccionesUnicas.forEach(dir => {
              if (!isDuplicateDir(dir.direccion, uniqueDirs)) {
                uniqueDirs.push(dir);
              }
            });
              
            // Ordenar direcciones: primero principal, luego por si tienen recolección, luego por canal y dirección
            uniqueDirs.sort((a, b) => {
              // Principal primero
              if (a.esPrincipal && !b.esPrincipal) return -1;
              if (!a.esPrincipal && b.esPrincipal) return 1;
              
              // Con recolección primero
              if (a.tieneRecoleccion && !b.tieneRecoleccion) return -1;
              if (!a.tieneRecoleccion && b.tieneRecoleccion) return 1;
              
              // Canal vacío primero
              if (!a.canal && b.canal) return -1;
              if (a.canal && !b.canal) return 1;
              
              // Si ambos tienen canal, por orden alfabético de canal
              if (a.canal && b.canal) {
                return a.canal.localeCompare(b.canal);
              }
              
              // Finalmente por dirección
              return a.direccion.localeCompare(b.direccion);
            });
            
            // Verificar si alguna dirección tiene recolección
            const algunaDireccionConRecoleccion = uniqueDirs.some(dir => dir.tieneRecoleccion);
            
            return {
              ID: cliente.COD_CLIENTE,
              NOMBRE: cliente.NOMBRE,
              RUT: cliente.RUT,
              direcciones: uniqueDirs,
              tieneRecoleccion: algunaDireccionConRecoleccion
            };
          });
          
          // Ordenar clientes: primero los que tienen recolección, luego por nombre
          clientesProcesados.sort((a, b) => {
            if (a.tieneRecoleccion && !b.tieneRecoleccion) return -1;
            if (!a.tieneRecoleccion && b.tieneRecoleccion) return 1;
            return a.NOMBRE.localeCompare(b.NOMBRE);
          });
          
          // Calcular estadísticas
          const conRecoleccion = clientesProcesados.filter(c => c.tieneRecoleccion).length;
          const sinRecoleccion = clientesProcesados.length - conRecoleccion;
          
          // Contar direcciones con/sin recolección
          let direccionesConRecoleccion = 0;
          let direccionesSinRecoleccion = 0;
          
          clientesProcesados.forEach(cliente => {
            cliente.direcciones.forEach(dir => {
              if (dir.tieneRecoleccion) {
                direccionesConRecoleccion++;
              } else {
                direccionesSinRecoleccion++;
              }
            });
          });
          
          setEstadisticas({
            conRecoleccion,
            sinRecoleccion,
            direccionesConRecoleccion,
            direccionesSinRecoleccion
          });
          
          setClientesDisponibles(clientesProcesados);
        } else {
          console.error("Estructura de datos inválida:", result);
          toast.error("No se pudieron cargar los clientes disponibles");
        }
      } catch (error) {
        console.error("Error al cargar clientes disponibles:", error);
        toast.error("Error al cargar clientes disponibles");
      } finally {
        setLoading(false);
      }
    };
    
    /**
     * Verifica si una dirección específica tiene recolección asignada
     * @param {string} nombreCliente - Nombre del cliente
     * @param {string} direccion - Dirección a verificar
     * @param {number} codCliente - Código del cliente (opcional)
     * @returns {boolean} - True si la dirección específica tiene recolección, false si no
     */
    const verificarRecoleccionDireccion = (nombreCliente, direccion, codCliente) => {
      // Si no hay retirosAgregados, retornar false
      if (!retirosAgregados || Object.keys(retirosAgregados).length === 0) {
        return false;
      }
      
      // Normalizar la dirección para comparación
      const direccionNormalizada = direccion.trim().toLowerCase();
      
      // Buscar en retirosAgregados si existe alguna entrada que coincida con esta dirección
      for (const key in retirosAgregados) {
        // La clave suele ser "nombreCliente - direccion"
        const partes = key.split(' - ');
        const nombre = partes[0];
        const dir = partes[1] || '';
        
        // Verificar que coincida el cliente
        const coincideCliente = 
          (nombre && nombre.trim().toLowerCase() === nombreCliente.trim().toLowerCase()) ||
          (retirosAgregados[key].clienteInfo && retirosAgregados[key].clienteInfo.ID === codCliente);
        
        if (!coincideCliente) continue;
        
        // Verificar la dirección - usando coincidencia flexible
        const direccionRecoleccion = dir.trim().toLowerCase();
        
        if (
          direccionRecoleccion === direccionNormalizada || 
          direccionRecoleccion.includes(direccionNormalizada) || 
          direccionNormalizada.includes(direccionRecoleccion)
        ) {
          return true;
        }
      }
      
      return false;
    };
    
    fetchClientesDisponibles();
  }, [retirosAgregados]);
  
  /**
   * Alterna la expansión/colapso de la sección de un cliente
   * @param {string} clientName - Nombre del cliente a alternar
   */
  const toggleClient = (clientName) => {
    setExpandedClients(prev => ({
      ...prev,
      [clientName]: !prev[clientName]
    }));
  };
  
  /**
   * Maneja la creación de un nuevo retiro para un cliente y dirección específicos
   * @param {Object} cliente - Datos del cliente
   * @param {string} direccion - Dirección seleccionada
   */
  const handleCreateRetiro = (cliente, direccionObj) => {
    const initialData = {
      client: cliente.NOMBRE,
      clientId: cliente.ID,
      direccion: direccionObj.direccion
    };
    
    if (modalHandler.onShowForm) {
      modalHandler.onShowForm(initialData);
    }
  };
  
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Clientes Disponibles para Notificación
        </Typography>
        
        {/* Campo de búsqueda */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Buscar por nombre de empresa, dirección o RUT..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.secondary' }} />
                </InputAdornment>
              ),
              endAdornment: searchText && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => setSearchText('')}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.blue
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.blue
                }
              }
            }}
          />
        </Box>
        
        {!loading && (
          <Box sx={{ display: 'flex', gap: 2, mt: 1, flexWrap: 'wrap' }}>
            <Box 
              onClick={() => toggleFiltro('clientesConRecoleccion')}
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                px: 2, 
                py: 0.5, 
                borderRadius: 1, 
                bgcolor: filtros.clientesConRecoleccion ? `${colors.green}20` : 'transparent',
                border: `1px solid ${colors.green}`,
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                boxShadow: filtros.clientesConRecoleccion ? '0 2px 4px rgba(4, 106, 56, 0.2)' : 'none',
                '&:hover': {
                  bgcolor: `${colors.green}30`,
                  transform: 'translateY(-1px)'
                }
              }}
            >
              <Box sx={{ 
                width: 10, 
                height: 10, 
                borderRadius: '50%', 
                bgcolor: colors.green,
                boxShadow: filtros.clientesConRecoleccion ? '0 0 0 2px rgba(4, 106, 56, 0.2)' : 'none'
              }} />
              <Typography variant="body2" sx={{ fontWeight: filtros.clientesConRecoleccion ? 600 : 400 }}>
                Empresas con Pick up ({estadisticas.conRecoleccion})
              </Typography>
            </Box>
            
            <Box 
              onClick={() => toggleFiltro('clientesSinRecoleccion')}
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                px: 2, 
                py: 0.5, 
                borderRadius: 1, 
                bgcolor: filtros.clientesSinRecoleccion ? `${colors.red}20` : 'transparent',
                border: `1px solid ${colors.red}`,
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                boxShadow: filtros.clientesSinRecoleccion ? '0 2px 4px rgba(218, 37, 28, 0.2)' : 'none',
                '&:hover': {
                  bgcolor: `${colors.red}30`,
                  transform: 'translateY(-1px)'
                }
              }}
            >
              <Box sx={{ 
                width: 10, 
                height: 10, 
                borderRadius: '50%', 
                bgcolor: colors.red,
                boxShadow: filtros.clientesSinRecoleccion ? '0 0 0 2px rgba(218, 37, 28, 0.2)' : 'none'
              }} />
              <Typography variant="body2" sx={{ fontWeight: filtros.clientesSinRecoleccion ? 600 : 400 }}>
                Empresas sin Pick up ({estadisticas.sinRecoleccion})
              </Typography>
            </Box>
            
            <Box 
              onClick={() => toggleFiltro('direccionesConRecoleccion')}
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                px: 2, 
                py: 0.5, 
                borderRadius: 1, 
                bgcolor: filtros.direccionesConRecoleccion ? `${colors.green}20` : 'transparent',
                border: `1px solid ${colors.green}`,
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                boxShadow: filtros.direccionesConRecoleccion ? '0 2px 4px rgba(4, 106, 56, 0.2)' : 'none',
                '&:hover': {
                  bgcolor: `${colors.green}30`,
                  transform: 'translateY(-1px)'
                }
              }}
            >
              <Box sx={{ 
                width: 10, 
                height: 10, 
                borderRadius: '50%', 
                bgcolor: colors.green,
                boxShadow: filtros.direccionesConRecoleccion ? '0 0 0 2px rgba(4, 106, 56, 0.2)' : 'none'
              }} />
              <Typography variant="body2" sx={{ fontWeight: filtros.direccionesConRecoleccion ? 600 : 400 }}>
                Sucursales con Pick up ({estadisticas.direccionesConRecoleccion})
              </Typography>
            </Box>
            
            <Box 
              onClick={() => toggleFiltro('direccionesSinRecoleccion')}
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                px: 2, 
                py: 0.5, 
                borderRadius: 1, 
                bgcolor: filtros.direccionesSinRecoleccion ? `${colors.orange}20` : 'transparent',
                border: `1px solid ${colors.orange}`,
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                boxShadow: filtros.direccionesSinRecoleccion ? '0 2px 4px rgba(255, 119, 0, 0.2)' : 'none',
                '&:hover': {
                  bgcolor: `${colors.orange}30`,
                  transform: 'translateY(-1px)'
                }
              }}
            >
              <Box sx={{ 
                width: 10, 
                height: 10, 
                borderRadius: '50%', 
                bgcolor: colors.orange,
                boxShadow: filtros.direccionesSinRecoleccion ? '0 0 0 2px rgba(255, 119, 0, 0.2)' : 'none'
              }} />
              <Typography variant="body2" sx={{ fontWeight: filtros.direccionesSinRecoleccion ? 600 : 400 }}>
                Sucursales sin Pick up ({estadisticas.direccionesSinRecoleccion})
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      
      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        clientesFiltrados.length === 0 ? (
          <Typography variant="body1" color="text.secondary" align="center" sx={{ p: 3 }}>
            {Object.values(filtros).some(Boolean) 
              ? "No hay clientes que coincidan con los filtros seleccionados."
              : "No hay clientes disponibles para notificación."}
          </Typography>
        ) : (
          <Box sx={{ mt: 2 }}>
            {clientesFiltrados.map((cliente, index) => (
              <Box 
                key={index}
                sx={{
                  mb: 2,
                  border: '1px solid',
                  borderColor: cliente.tieneRecoleccion ? colors.green : colors.red,
                  borderRadius: 1,
                  overflow: 'hidden'
                }}
              >
                {/* Encabezado del cliente (siempre visible) */}
                <Box
                  onClick={() => toggleClient(cliente.NOMBRE)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    backgroundColor: cliente.tieneRecoleccion ? `${colors.green}10` : `${colors.red}10`,
                    cursor: 'pointer',
                    '&:hover': { 
                      backgroundColor: cliente.tieneRecoleccion ? `${colors.green}20` : `${colors.red}20` 
                    }
                  }}
                >
                  <BusinessIcon sx={{ 
                    color: cliente.tieneRecoleccion ? colors.green : colors.red, 
                    mr: 1.5 
                  }} />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" sx={{ 
                      fontWeight: 'bold', 
                      color: cliente.tieneRecoleccion ? colors.green : colors.red
                    }}>
                      {cliente.NOMBRE}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                      <Typography variant="caption" color="text.secondary">
                        {cliente.RUT}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                        •
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {cliente.direcciones.length} {cliente.direcciones.length === 1 ? 'dirección' : 'direcciones'} disponible{cliente.direcciones.length !== 1 ? 's' : ''}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                        •
                      </Typography>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: cliente.tieneRecoleccion ? colors.green : colors.red,
                          fontWeight: 'medium'
                        }}
                      >
                        {cliente.tieneRecoleccion 
                          ? `${cliente.direcciones.filter(d => d.tieneRecoleccion).length} direcciones con recolección` 
                          : 'Sin recolección'}
                      </Typography>
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: expandedClients[cliente.NOMBRE] ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: '0.3s'
                    }}
                  />
                </Box>
                
                {/* Sección expandible con direcciones */}
                <Collapse in={expandedClients[cliente.NOMBRE] === true}>
                  <Box sx={{ p: 2, backgroundColor: 'white' }}>
                    {cliente.direcciones.map((direccionObj, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          p: 1,
                          mb: idx < cliente.direcciones.length - 1 ? 1 : 0,
                          borderBottom: idx < cliente.direcciones.length - 1 ? '1px dashed #e0e0e0' : 'none',
                          borderLeft: direccionObj.tieneRecoleccion ? `3px solid ${colors.green}` : 'none',
                          backgroundColor: direccionObj.tieneRecoleccion 
                            ? `${colors.green}05` 
                            : (direccionObj.canal ? '#f9f9f9' : 'transparent')
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, overflow: 'hidden' }}>
                          <LocationOnIcon sx={{ 
                            color: direccionObj.tieneRecoleccion ? colors.green : (direccionObj.esPrincipal ? colors.blue : colors.orange),
                            mr: 1, 
                            fontSize: '1.2rem', 
                            flexShrink: 0 
                          }} />
                          <Box sx={{ overflow: 'hidden' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Typography 
                                variant="body2" 
                                sx={{ 
                                  overflow: 'hidden', 
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '500px',
                                  fontWeight: direccionObj.tieneRecoleccion ? 'medium' : 'normal',
                                  color: direccionObj.tieneRecoleccion ? colors.green : 'text.primary'
                                }}
                              >
                                {direccionObj.direccion}
                              </Typography>
                              
                              {direccionObj.esPrincipal && (
                                <Chip 
                                  size="small" 
                                  label="Principal" 
                                  sx={{ 
                                    height: 18, 
                                    fontSize: '0.6rem',
                                    backgroundColor: colors.blue,
                                    color: 'white'
                                  }} 
                                />
                              )}
                              
                              {direccionObj.tieneRecoleccion && (
                                <Chip 
                                  size="small" 
                                  label="Con Recolección" 
                                  sx={{ 
                                    height: 18, 
                                    fontSize: '0.6rem',
                                    backgroundColor: colors.green,
                                    color: 'white'
                                  }} 
                                />
                              )}
                            </Box>
                            
                            {direccionObj.canal && (
                              <Typography variant="caption" color="text.secondary">
                                Canal: {direccionObj.canal}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<AddIcon />}
                          onClick={() => handleCreateRetiro(cliente, direccionObj)}
                          sx={{ 
                            color: direccionObj.tieneRecoleccion ? colors.blue : colors.green,
                            borderColor: direccionObj.tieneRecoleccion ? colors.blue : colors.green,
                            '&:hover': {
                              backgroundColor: direccionObj.tieneRecoleccion ? `${colors.blue}10` : `${colors.green}10`,
                              borderColor: direccionObj.tieneRecoleccion ? colors.blue : colors.green
                            },
                            flexShrink: 0,
                            ml: 1
                          }}
                        >
                          {direccionObj.tieneRecoleccion ? 'Agregar Retiro' : 'Crear Retiro'}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Collapse>
              </Box>
            ))}
          </Box>
        )
      )}
    </Box>
  );
};

// Componente con Tabs para alternar entre Formulario y Lista
const RetiroTabs = ({retiros, requiredData, modalHandler, refetchData, initialData}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [currentInitialData, setCurrentInitialData] = useState(initialData || null);
  const {retirosAgregados} = retiros;

  // Update currentInitialData when initialData prop changes
  useEffect(() => {
    if (initialData) {
      setCurrentInitialData(initialData);
      setTabIndex(0); // Switch to form tab
    }
  }, [initialData]);

  // Function to handle clearing form data and switching to list view
  const handleViewList = () => {
    setTabIndex(1);
    setCurrentInitialData(null);
  };

  // Function to handle creating a new retiro
  const handleNewRetiro = () => {
    setCurrentInitialData(null);
    setTabIndex(0);
  };

  // Función para manejar la solicitud de edición desde la lista
  const handleShowForm = (initialData) => {
    // Establecer los datos iniciales para el formulario
    setCurrentInitialData(initialData);
    // Cambiar a la pestaña del formulario
    setTabIndex(0);
  };

  // Agregar nuevo handler para la vista de completados
  const handleViewCompleted = () => {
    setTabIndex(2);
    setCurrentInitialData(null);
  };
  
  // Nuevo handler para la vista de clientes disponibles
  const handleViewClientesDisponibles = () => {
    setTabIndex(3);
    setCurrentInitialData(null);
  };

  // Extendemos el modalHandler para agregar nuestra función
  const extendedModalHandler = {
    ...modalHandler,
    onShowForm: handleShowForm,
    onViewList: handleViewList,
    onNewRetiro: handleNewRetiro
  };

  return (
    <Box sx={{ 
      p: 3, 
      borderRadius: 3, 
      maxWidth: '95%',  // Reducir el ancho máximo
      width: '1200px',  // Establecer un ancho fijo más pequeño
      mx: "auto", 
      mt: 2,
      backgroundColor: '#fff',
      height: 'auto',  // Ajustar altura automáticamente
      maxHeight: '85vh' // Limitar altura máxima
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.blue }}>
          {currentInitialData ? "Editar Pick up Pendiente" : "Registro de Pick up 's"}
        </Typography>
        
        {/* View switching buttons */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button 
            variant={tabIndex === 0 ? "contained" : "outlined"} 
            color="primary"
            size="small"
            onClick={() => handleNewRetiro()}
            startIcon={<AddIcon />}
          >
            Nuevo
          </Button>
          <Button 
            variant={tabIndex === 1 ? "contained" : "outlined"} 
            color="warning"
            size="small"
            onClick={() => handleViewList()}
          >
            Ver pendientes
          </Button>
          <Button 
            variant={tabIndex === 2 ? "contained" : "outlined"} 
            color="success"
            size="small"
            onClick={() => handleViewCompleted()}
          >
            Ver Completados
          </Button>
          <Button 
            variant={tabIndex === 3 ? "contained" : "outlined"} 
            color="info"
            size="small"
            onClick={() => handleViewClientesDisponibles()}
          >
            Clientes Disponibles
          </Button>
        </Box>
      </Box>
      
      <Divider sx={{ mb: 3 }} />

      <Box sx={{ 
        maxHeight: '65vh', // Reducir altura máxima del contenido
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',  // Scrollbar más delgada
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f5f5f5',
          borderRadius: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#bdbdbd',
          borderRadius: '3px',
          '&:hover': {
            backgroundColor: '#9e9e9e'
          }
        }
      }}>
        {tabIndex === 0 && 
          <Box sx={{ p: 2 }}>
            <RetiroForm 
              requiredData={requiredData} 
              modalHandler={extendedModalHandler} 
              retirosAgregados={retirosAgregados}
              initialData={currentInitialData}
              refetchData={refetchData}
            />
          </Box>
        }
        {tabIndex === 1 && 
          <Box sx={{ p: 2 }}>
           <RetiroList 
            retiros={retiros}
            requiredData={requiredData}
            modalHandler={extendedModalHandler}
            retirosAgregados={retirosAgregados}
            refetchData={refetchData}
           />
          </Box>
        }
        {tabIndex === 2 && 
          <Box sx={{ p: 2 }}>
           <RetiroListCompletados 
            retiros={retiros}
            requiredData={requiredData}
            modalHandler={extendedModalHandler}
            retirosAgregados={retirosAgregados}
            refetchData={refetchData}
           />
          </Box>
        }
        {tabIndex === 3 && 
          <Box sx={{ p: 2 }}>
           <RetiroListClientesDisponibles 
            requiredData={requiredData}
            modalHandler={extendedModalHandler}
            refetchData={refetchData}
            retiros={retiros}
           />
          </Box>
        }
      </Box>
    </Box>
  );
};

const RetiroModalComponent = ({
  isOpen,
  handleClose,
  retirosAgregados,
  choferes,
  clientes,
  refetchData,
  initialData
}) => {
  return (
    <BeautifulModal open={isOpen} handleClose={handleClose} title={initialData ? "Editar pick up pendiente" : ""}>
      <RetiroTabs 
        retiros={{ retirosAgregados }} 
        requiredData={{choferes, clientes}} 
        modalHandler={{setIsOpen: handleClose}}
        refetchData={refetchData}
        initialData={initialData}
      />
    </BeautifulModal>
  );
};

/**
 * @component RetirosAsignadosComponent
 * @description Componente principal para la gestión y monitoreo de retiros.
 * Proporciona una interfaz flotante y arrastrable que muestra el estado de los retiros
 * pendientes y permite acceder a la gestión completa de retiros. Incluye notificaciones
 * en tiempo real para retiros retrasados.
 * 
 * Características principales:
 * - Interfaz flotante y arrastrable
 * - Monitoreo en tiempo real de retiros pendientes
 * - Notificaciones automáticas de retiros retrasados
 * - Acceso rápido a la gestión completa de retiros
 * - Restricción de acceso por usuario
 */
function RetirosAsignadosComponent() {
  // Estados para la posición y comportamiento del botón flotante
  const [position, setPosition] = useState({ x: window.innerWidth - 100, y: window.innerHeight - 100 });
  const [dragging, setDragging] = useState(false);
  
  // Estados para el control de visualización y modal
  const [display, setDisplay] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  
  // Estado para el muteo de notificaciones
  const [notificationsMuted, setNotificationsMuted] = useState(() => {
    const savedPreference = localStorage.getItem(MUTE_NOTIFICATIONS_KEY);
    return savedPreference ? JSON.parse(savedPreference) : false;
  });
  
  // Estados para almacenar datos principales
  const [choferes, setChoferes] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [recoleccionesPCEjecutadas, setRecoleccionesPCEjecutadas] = useState([]);
  const [retirosAgregados, setRetirosAgregados] = useState({});
  
  // Referencias para el manejo del arrastre
  const dragOffset = useRef({ x: 0, y: 0 });
  const dragStartTime = useRef(0);
  const positionRef = useRef(position);
  const isClick = useRef(false);
  
  // Actualizar la referencia cuando cambia la posición
  useEffect(() => {
    positionRef.current = position;
  }, [position]);
  
  // Obtener información del usuario desde Redux
  const userInfo = useSelector((state) => state.user.userInfo);

  // Función para alternar el muteo de notificaciones
  const toggleNotificationsMute = useCallback(() => {
    setNotificationsMuted(prev => {
      const newValue = !prev;
      localStorage.setItem(MUTE_NOTIFICATIONS_KEY, JSON.stringify(newValue));
      
      // Mostrar toast de confirmación
      toast.info(
        `Notificaciones ${newValue ? 'silenciadas' : 'activadas'}`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
      
      return newValue;
    });
  }, []);

  const showDelayedNotifications = useCallback((retirosAgregados) => {
    // Si las notificaciones están muteadas, no mostrar nada
    if (notificationsMuted) return;
    
    // Si el documento no está visible, no mostrar notificaciones
    if (document.hidden) return;

    // Mantener un registro de las notificaciones activas
    const activeToastIds = [];
    
    // Procesar retiros retrasados por cliente
    const retrasadosArray = {};
    
    // Extraer retiros retrasados de cada cliente
    for(let clienteKey in retirosAgregados) {  
      const retrasados = retirosAgregados[clienteKey].retrasados;
      if (Object.keys(retrasados).length > 0) {
        const retrasadosCliente = [];
        
        // Procesar cada retiro retrasado
        Object.entries(retrasados).forEach(([hora, retirosPorHora]) => {
          retirosPorHora.forEach(retiro => {
            retrasadosCliente.push({
              hora: retiro.HORA_RECOLECCION.substring(0, 5),
              chofer: retiro.chofer.NOMBRE,
              choferCod: retiro.chofer.COD_CHOFER,
              direccion: retiro.direccion
            });
          });
        });

        if (retrasadosCliente.length > 0) {
          retrasadosArray[clienteKey] = retrasadosCliente;
        }
      }
    }


    // Mostrar notificaciones para cada cliente con retiros retrasados
    Object.entries(retrasadosArray).forEach(([clienteKey, retrasados]) => {
      const [nombreCliente, direccionCliente] = clienteKey.split(' - ');
      
      // Crear un ID único para la notificación basado en información específica
      // Usamos clienteId + choferes + horas para evitar duplicados
      const choferesIds = retrasados.map(r => r.choferCod).join('_');
      const horasStr = retrasados.map(r => r.hora.replace(':', '')).join('_');
      const toastId = `${clienteKey}_${choferesIds}_${horasStr}`;
      
      // Verificar si ya existe una notificación para este caso específico
      if (!toast.isActive(toastId)) {
        // Configurar y mostrar la notificación
        toast.warning(
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
            {/* Nombre del cliente con truncamiento */}
            <div style={{ 
              fontWeight: 'bold', 
              color: colors.blue,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '230px'
            }}>
              {nombreCliente}
            </div>
            
            {/* Dirección del cliente */}
            {direccionCliente && (
              <div style={{ 
                color: '#666',
                fontSize: '11px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '230px'
              }}>
                {direccionCliente}
              </div>
            )}
            
            {/* Contador de retrasados */}
            <div style={{ 
              color: colors.red, 
              fontSize: '12px', 
              backgroundColor: '#fff5f5', 
              padding: '2px 5px', 
              borderRadius: '3px',
              fontWeight: '500',
              display: 'inline-block',
              marginTop: '2px'
            }}>
              {retrasados.length} retrasado{retrasados.length > 1 ? 's' : ''}
            </div>
            
            {/* Lista compacta de retiros */}
            <div style={{ fontSize: '12px', marginTop: '4px' }}>
              {retrasados.map((retiro, index) => (
                <div key={index} style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: index < retrasados.length - 1 ? '4px' : 0
                }}>
                  <span style={{ 
                    fontWeight: 'bold', 
                    color: colors.red,
                    minWidth: '40px',
                    flexShrink: 0
                  }}>
                    {retiro.hora}
                  </span>
                  <span style={{ 
                    color: '#666',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {retiro.chofer}
                  </span>
                </div>
              ))}
            </div>

            {/* Agregar botón de muteo */}
            <div style={{ 
              marginTop: '8px', 
              display: 'flex', 
              justifyContent: 'flex-end'
            }}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleNotificationsMute();
                }}
                style={{
                  background: 'none',
                  border: 'none',
                  color: colors.blue,
                  cursor: 'pointer',
                  fontSize: '11px',
                  padding: '2px 5px',
                  textDecoration: 'underline',
                  opacity: 0.8
                }}
              >
                Silenciar notificaciones
              </button>
            </div>
          </div>,
          {
            position: "top-right",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: toastId
          }
        );
        
        // Guardar el ID de la notificación como activa
        activeToastIds.push(toastId);
      }
    });
    
    // Almacenar la última actualización de las notificaciones
    const now = new Date().getTime();
    localStorage.setItem('lastNotificationUpdate', now);
    
  }, [notificationsMuted, toggleNotificationsMute]);

  // Primer useEffect para la carga inicial
  useEffect(() => {
    if (userInfo !== null) {
      const isAuthorized = 
        AUTHORIZED_EMAILS.includes(String(userInfo.email).toLowerCase());
      if (isAuthorized || userInfo.tipo_usuario === 'INFORMATICA') {
        setDisplay(true);
        // Cargar datos iniciales
        getChoferes(setChoferes);
        getClients(setClientes);
        getRecoleccionesPCEjecutadas(setRecoleccionesPCEjecutadas);
      } else {
        setRecoleccionesPCEjecutadas([]);
        setDisplay(false);
      }
    } else {
      setRecoleccionesPCEjecutadas([]);
      setDisplay(false);
    }
  }, [userInfo]);
  

  // Segundo useEffect para actualizaciones periódicas
  useEffect(() => {
    let intervalId;

    if (display && userInfo !== null) {
      const isAuthorized = 
        AUTHORIZED_EMAILS.includes(String(userInfo.email).toLowerCase());

      if (isAuthorized || userInfo.tipo_usuario === 'INFORMATICA') {
        intervalId = setInterval(() => {
          getRecoleccionesPCEjecutadas(setRecoleccionesPCEjecutadas);
          
          if (Object.keys(retirosAgregados).length > 0) {
            showDelayedNotifications(retirosAgregados);
          }
        }, 60 * 1000); // Actualizar cada minuto
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [display, userInfo, retirosAgregados, showDelayedNotifications]);

  /**
   * Manejadores para el modal
   */
  const handleOpen = () => {
    // Solo abrir si fue un click y no un arrastre
    if (isClick.current) { 
      setIsOpen(true);
    }
  };
  const handleClose = () => setIsOpen(false);

  /**
   * Manejadores para el arrastre del botón flotante
   */
  const handleMouseDown = (e) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    dragOffset.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };
    dragStartTime.current = Date.now();
    isClick.current = true;
    setDragging(true);
    document.body.style.cursor = 'grabbing';
  };

  const handleDragEnd = useCallback(() => {
    setDragging(false);
    document.body.style.cursor = 'default';
    
    // Determinar si fue un click o un arrastre (menos de 150ms y sin moverse mucho)
    const dragDuration = Date.now() - dragStartTime.current;
    if (dragDuration > 150) {
      isClick.current = false;
    }
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!dragging) return;
    
    e.preventDefault();
    
    // Si nos movimos mucho, ya no es un click
    isClick.current = false;
    
    // Calcular nueva posición
    let newX = e.clientX - dragOffset.current.x;
    let newY = e.clientY - dragOffset.current.y;
    
    // Suavizar los límites
    const minX = 310;
    const maxX = window.innerWidth - 35;
    const minY = 59;
    const maxY = window.innerHeight - 35;
    
    // Aplicar límites suavemente
    if (newX < minX) newX = minX;
    if (newX > maxX) newX = maxX;
    if (newY < minY) newY = minY;
    if (newY > maxY) newY = maxY;
    
    // Actualizar posición directamente sin depender del valor previo
    setPosition({ x: newX, y: newY });
  }, [dragging]);

  /**
   * Efecto para manejar eventos de arrastre
   */
  useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleDragEnd);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleDragEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleDragEnd);
    };
  }, [dragging, handleMouseMove, handleDragEnd]);

  /**
   * Procesa y agrupa las recolecciones por cliente
   */
  useMemo(async () => {
    const recolecciones = Array.isArray(recoleccionesPCEjecutadas) ? recoleccionesPCEjecutadas : [];
    const choferesArray = Array.isArray(choferes) ? choferes : [];
    const clientesArray = Array.isArray(clientes) ? clientes : [];

    if (!recolecciones.length || !choferesArray.length || !clientesArray.length) {
      return { pendientes: [], completadas: [] };
    }


    try {
      const filteredRecolecciones = recolecciones.filter(_ => _.EJECUTO_PICKUP === "PENDIENTE") || [];
      const readyRecolecciones = recolecciones.filter(_ => _.EJECUTO_PICKUP === "COMPLETADO" || _.EJECUTO_PICKUP === "SIN RETIROS") || [];
      
      const recoleccionesPendientes = await Promise.all(
        filteredRecolecciones.map(async (recoleccion) => {       
            const {data: choferDataR} = await getChoferesRecoleccion(recoleccion.ID_PICKUP_CHOFER)
            const choferData = Array.isArray(choferDataR) ? choferDataR[0] : choferDataR
            
          const clienteDataR = await getRecoleccionesPendientesByCliente(recoleccion.ID_PICKUP)
          const clienteData = clienteDataR[0]
          if(!clienteData) return []

          const apoyoData = await getChoferesRecoleccion(null, null, null, null, clienteData.COD_CLIENTE, clienteData.DIRECCION)
          
          const apoyoSlots = apoyoData.data
            .filter(apoyo => apoyo.TIPO_CHOFER === "APOYO")
            .filter(apoyo => apoyo.ID_PICKUP === recoleccion.ID_PICKUP)
            .map(apoyo => {
              const choferData = choferes.find(chofer => chofer.COD_CHOFER === apoyo.COD_CHOFER);
              
              return {
                 ...choferes.find(c => c.COD_CHOFER === choferData.COD_CHOFER),
              timeSlot: apoyo.HORA_RECOLECCION,
              dia: apoyo.DIA_SEMANA,
              tipoChofer: apoyo.TIPO_CHOFER,
              patente: apoyo.PATENTE_VEHICULO,
              ID_RECOLECCION: recoleccion.ID_PICKUP,
              ID_RECOLECCION_CHOFER: recoleccion.ID_PICKUP_CHOFER,
              BULTOS_INFORMADOS_PICKUP: recoleccion.BULTOS_INFORMADOS_PICKUP,
              EJECUTO_PICKUP: recoleccion.EJECUTO_PICKUP
              };
            });

          return {
            ...recoleccion,
            cliente: {
              ...clientes.find(c => c.ID === clienteData.COD_CLIENTE),
              direccion: clienteData.DIRECCION
            },
            chofer: {
              ...choferes.find(c => c.COD_CHOFER === choferData.COD_CHOFER),
              timeSlot: choferData.HORA_RECOLECCION,
              dia: choferData.DIA_SEMANA,
              tipoChofer: choferData.TIPO_CHOFER,
              patente: choferData.PATENTE_VEHICULO,
              BULTOS_INFORMADOS_PICKUP: recoleccion.BULTOS_INFORMADOS_PICKUP,
              EJECUTO_PICKUP: recoleccion.EJECUTO_PICKUP
            },
            apoyoSlots: apoyoSlots
          };
        })
      );

      const recoleccionesCompletadas = await Promise.all(
        readyRecolecciones.map(async (recoleccion) => {
          const {data: choferDataR} = await getChoferesRecoleccion(recoleccion.ID_PICKUP_CHOFER)
          const choferData = Array.isArray(choferDataR) ? choferDataR[0] : choferDataR
          const clienteDataR = await getRecoleccionesPendientesByCliente(recoleccion.ID_PICKUP, null, null)

          const clienteData = clienteDataR[0]
          if(!clienteData) return []
          const apoyoData = await getChoferesRecoleccion(null, null, null, null, clienteData.COD_CLIENTE, clienteData.DIRECCION)

          const apoyoSlots = apoyoData.data
            .filter(apoyo => apoyo.TIPO_CHOFER === "APOYO")
            .filter(apoyo => apoyo.ID_PICKUP === recoleccion.ID_PICKUP)
            .map(apoyo => {
              const choferData = choferes.find(chofer => chofer.COD_CHOFER === apoyo.COD_CHOFER);
              return {
                 ...choferes.find(c => c.COD_CHOFER === choferData.COD_CHOFER),
              timeSlot: apoyo.HORA_RECOLECCION,
              dia: apoyo.DIA_SEMANA,
              tipoChofer: apoyo.TIPO_CHOFER,
              ID_RECOLECCION: recoleccion.ID_PICKUP,
              ID_RECOLECCION_CHOFER: recoleccion.ID_PICKUP_CHOFER,
              patente: apoyo.PATENTE_VEHICULO,
              EJECUTO_PICKUP: recoleccion.EJECUTO_PICKUP
              };
            });
          return {
            ...recoleccion,
            cliente: {
              ...clientes.find(c => c.ID === clienteData.COD_CLIENTE),
              direccion: String(clienteData.DIRECCION)
            },
            ID_RECOLECCION_CHOFER: recoleccion.ID_PICKUP_CHOFER,
            ID_RECOLECCION: recoleccion.ID_PICKUP,
            chofer: {
              ...choferes.find(c => c.COD_CHOFER === choferData.COD_CHOFER),
              timeSlot: choferData.HORA_RECOLECCION,
              dia: choferData.DIA_SEMANA,
              tipoChofer: choferData.TIPO_CHOFER,
              patente: choferData.PATENTE_VEHICULO,
              BULTOS_INFORMADOS_PICKUP: recoleccion.BULTOS_INFORMADOS_PICKUP,
              EJECUTO_PICKUP: recoleccion.EJECUTO_PICKUP
            },
            apoyoSlots: apoyoSlots
          };
        })
      );
      const groupedByClientCompletadas = {};
      const groupedByClient = {};

      // Procesar recolecciones completadas primero
      for(const recoleccion of recoleccionesCompletadas) {
        const chofer = recoleccion.chofer;
        const cliente = recoleccion.cliente;
        const clienteKey = `${cliente.NOMBRE} - ${cliente.direccion}`;

        if (!groupedByClientCompletadas[clienteKey]) {
          groupedByClientCompletadas[clienteKey] = {
            detalles: []
          };
        }

        // Agregar el retiro completado
        groupedByClientCompletadas[clienteKey].detalles.push({
          nombre: cliente.NOMBRE,
          direccion: cliente.direccion,
          COD_CLIENTE: cliente.ID,
          DIA_SEMANA: chofer.dia,
          HORA_RECOLECCION: chofer.timeSlot,
          ID_PICKUP: recoleccion.ID_PICKUP,
          ID_PICKUP_CHOFER: recoleccion.ID_PICKUP_CHOFER,
          BULTOS_INFORMADOS_PICKUP: recoleccion.BULTOS_INFORMADOS_PICKUP,
          EJECUTO_PICKUP: recoleccion.EJECUTO_PICKUP,
          chofer: {
            ID: chofer.ID,
            COD_CHOFER: chofer.COD_CHOFER,
            NOMBRE: chofer.NOMBRE,
            RUT: chofer.RUT,
            PATENTE_VEHICULO: chofer.patente,
            TIPO_CHOFER: chofer.tipoChofer
          }
        });

        // Inicializar la estructura para este cliente en groupedByClient
        if (!groupedByClient[clienteKey]) {
          groupedByClient[clienteKey] = {
            retrasados: {},
            aTiempo: {},
            completadas: groupedByClientCompletadas[clienteKey].detalles,
            clienteInfo: {
              nombre: cliente.NOMBRE,
              direccion: cliente.direccion,
              ID: cliente.ID
            }
          };
        }
      }

      // Procesar recolecciones pendientes
      for(const recoleccion of recoleccionesPendientes) {
        const chofer = recoleccion.chofer;
        const apoyoSlots = recoleccion.apoyoSlots;
        const cliente = recoleccion.cliente;
        const clienteKey = `${cliente.NOMBRE} - ${cliente.direccion}`;

        // Verificar si este retiro ya está en completadas
        const isCompleted = groupedByClientCompletadas[clienteKey]?.detalles.some(
          completado => 
            completado.HORA_RECOLECCION === chofer.timeSlot &&
            completado.chofer.COD_CHOFER === chofer.COD_CHOFER &&
            completado.ID_RECOLECCION === recoleccion.ID_RECOLECCION
        );

        // Si el retiro está completado, no lo agregamos a pendientes
        if (isCompleted) {
          continue;
        }

        // Initialize client structure if it doesn't exist
        if (!groupedByClient[clienteKey]) {
          groupedByClient[clienteKey] = {
            retrasados: {},
            aTiempo: {},
            completadas: [],
            clienteInfo: {
              nombre: cliente.NOMBRE,
              direccion: cliente.direccion,
              ID: cliente.ID
            }
          };
        }

        // Procesar chofer principal
        const choferHour = String(chofer.timeSlot).substring(0, 2);
        const recoleccionItem = {
          nombre: cliente.NOMBRE,
          direccion: cliente.direccion,
          COD_CLIENTE: cliente.ID,
          DIA_SEMANA: chofer.dia,
          HORA_RECOLECCION: chofer.timeSlot,
          ID_RECOLECCION: recoleccion.ID_PICKUP || recoleccion.ID_RECOLECCION,
          ID_RECOLECCION_CHOFER: recoleccion.ID_PICKUP_CHOFER || recoleccion.ID_RECOLECCION_CHOFER,
          chofer: {
            ID: chofer.ID,
            COD_CHOFER: chofer.COD_CHOFER,
            NOMBRE: chofer.NOMBRE,
            RUT: chofer.RUT,
            PATENTE_VEHICULO: chofer.patente,
            TIPO_CHOFER: chofer.tipoChofer
          }
        };

        if(new Date().getHours() >= Number(choferHour)) {
          if (!groupedByClient[clienteKey].retrasados[choferHour]) {
            groupedByClient[clienteKey].retrasados[choferHour] = [];
          }
          groupedByClient[clienteKey].retrasados[choferHour].push(recoleccionItem);
        } else {
          if (!groupedByClient[clienteKey].aTiempo[choferHour]) {
            groupedByClient[clienteKey].aTiempo[choferHour] = [];
          }
          groupedByClient[clienteKey].aTiempo[choferHour].push(recoleccionItem);
        }


        // Procesar apoyoSlots
        if(apoyoSlots && apoyoSlots.length > 0) {
          for(const apoyoSlot of apoyoSlots) {
            // Verificar si este apoyo ya está en completadas
            const isApoyoCompleted = groupedByClientCompletadas[clienteKey]?.detalles.some(
              completado => 
                completado.HORA_RECOLECCION === apoyoSlot.timeSlot &&
                completado.chofer.COD_CHOFER === apoyoSlot.COD_CHOFER &&
                completado.ID_RECOLECCION === recoleccion.ID_RECOLECCION
            );

            if (isApoyoCompleted) {
              continue;
            }

            const apoyoItem = {
              nombre: cliente.NOMBRE,
              direccion: cliente.direccion,
              COD_CLIENTE: cliente.ID,
              DIA_SEMANA: apoyoSlot.dia,
              HORA_RECOLECCION: apoyoSlot.timeSlot,
              ID_RECOLECCION: apoyoSlot.ID_RECOLECCION || recoleccion.ID_PICKUP || recoleccion.ID_RECOLECCION,
              ID_RECOLECCION_CHOFER: apoyoSlot.ID_RECOLECCION_CHOFER || recoleccion.ID_PICKUP_CHOFER || recoleccion.ID_RECOLECCION_CHOFER,
              chofer: {
                ID: apoyoSlot.ID,
                COD_CHOFER: apoyoSlot.COD_CHOFER,
                NOMBRE: apoyoSlot.NOMBRE,
                RUT: apoyoSlot.RUT,
                PATENTE_VEHICULO: apoyoSlot.patente,
                TIPO_CHOFER: apoyoSlot.tipoChofer
              }
            };

            if(new Date().getHours() >= Number(String(apoyoSlot.timeSlot).substring(0, 2))) {
              if (!groupedByClient[clienteKey].retrasados[String(apoyoSlot.timeSlot).substring(0, 2)]) {
                groupedByClient[clienteKey].retrasados[String(apoyoSlot.timeSlot).substring(0, 2)] = [];
              }
              groupedByClient[clienteKey].retrasados[String(apoyoSlot.timeSlot).substring(0, 2)].push(apoyoItem);
            } else {
              if (!groupedByClient[clienteKey].aTiempo[String(apoyoSlot.timeSlot).substring(0, 2)]) {
                groupedByClient[clienteKey].aTiempo[String(apoyoSlot.timeSlot).substring(0, 2)] = [];
              }
              groupedByClient[clienteKey].aTiempo[String(apoyoSlot.timeSlot).substring(0, 2)].push(apoyoItem);
            }
          }
        }
      }

      // Asegurarnos de que las completadas estén correctamente asignadas
      for (const clientKey in groupedByClientCompletadas) {
        if (!groupedByClient[clientKey]) {
          groupedByClient[clientKey] = {
            retrasados: {},
            aTiempo: {},
            completadas: groupedByClientCompletadas[clientKey].detalles,
            clienteInfo: {
              nombre: groupedByClientCompletadas[clientKey].detalles[0].nombre,
              direccion: groupedByClientCompletadas[clientKey].detalles[0].direccion,
              ID: groupedByClientCompletadas[clientKey].detalles[0].COD_CLIENTE
            }
          };
        } else {
          groupedByClient[clientKey].completadas = groupedByClientCompletadas[clientKey].detalles;
        }
      }

      // Deduplicación final para eliminar posibles duplicados en retrasados y aTiempo
      Object.keys(groupedByClient).forEach(clientKey => {
        const client = groupedByClient[clientKey];
        // Deduplicar retrasados
        Object.keys(client.retrasados).forEach(hour => {
          const seen = new Set();
          client.retrasados[hour] = client.retrasados[hour].filter(item => {
            const key = `${item.ID_RECOLECCION}_${item.chofer.COD_CHOFER}_${item.HORA_RECOLECCION}`;
            if (seen.has(key)) return false;
            seen.add(key);
            return true;
          });
        });
        
        // Deduplicar aTiempo
        Object.keys(client.aTiempo).forEach(hour => {
          const seen = new Set();
          client.aTiempo[hour] = client.aTiempo[hour].filter(item => {
            const key = `${item.ID_RECOLECCION}_${item.chofer.COD_CHOFER}_${item.HORA_RECOLECCION}`;
            if (seen.has(key)) return false;
            seen.add(key);
            return true;
          });
        });
      });

      setRetirosAgregados(groupedByClient);
    } catch (error) {
      console.error('Error procesando recolecciones:', error);
      return { pendientes: [], completadas: [] };
    }
  }, [recoleccionesPCEjecutadas, choferes, clientes]);

  /**
   * Calcula el total de retiros pendientes para el indicador visual
   */
  const totalPendientes = Object.values(retirosAgregados).reduce((total, cliente) => {
    return total + Object.values(cliente.retrasados).reduce((sum, hour) => sum + hour.length, 0);
  }, 0);

  /**
   * Recarga los datos de recolecciones
   */
  const refetchData = useCallback(() => {
    getRecoleccionesPCEjecutadas(setRecoleccionesPCEjecutadas);
  }, []);

  return (
    <>
      {display && (
        <>
          {/* Botón flotante */}
          <Box
            sx={{
              position: "absolute",
              left: position.x,
              top: position.y,
              width: 80,
              height: 80,
              backgroundColor: `${colors.blue}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              cursor: dragging ? "grabbing" : "grab",
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 24px',
              userSelect: "none",
              zIndex: 10,
              transition: dragging ? 'none' : 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 12px 32px'
              }
            }}
            onMouseDown={handleMouseDown}
            onClick={handleOpen}
          >
            <LocalShippingIcon 
              sx={{ 
                height: "2em", 
                width: "2em", 
                color: "white",
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'rotate(-10deg)'
                }
              }} 
            />

            <TimerOffIcon 
              sx={{ 
                position: "absolute", 
                bottom: 34, 
                right: 37, 
                height: "0.8em", 
                width: "0.8em", 
                color: totalPendientes === 0 
                ? colors.green
                  : totalPendientes <= 3 
                ? colors.orange
                : colors.red,
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "4px"
              }} 
            />

            {/* Indicador de muteo */}
            <Tooltip title={notificationsMuted ? "Notificaciones silenciadas" : "Notificaciones activas"}>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  toggleNotificationsMute();
                }}
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: "white",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px'
                  }
                }}
              >
                {notificationsMuted ? (
                  <NotificationsOffIcon 
                    sx={{ 
                      fontSize: 16,
                      color: colors.red,
                      animation: notificationsMuted ? 'pulse 2s infinite' : 'none',
                      '@keyframes pulse': {
                        '0%': {
                          transform: 'scale(1)',
                          opacity: 1
                        },
                        '50%': {
                          transform: 'scale(1.1)',
                          opacity: 0.7
                        },
                        '100%': {
                          transform: 'scale(1)',
                          opacity: 1
                        }
                      }
                    }} 
                  />
                ) : (
                  <NotificationsIcon 
                    sx={{ 
                      fontSize: 16,
                      color: '#FFB433'
                    }} 
                  />
                )}
              </Box>
            </Tooltip>

            <Box 
              sx={{
                position: "absolute", 
                bottom: 10, 
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                left: "50%", 
                transform: "translateX(-50%)",
                backgroundColor: "white",
                width: "80%",
                height: "0.8em",
                padding: "4px 8px",
                borderRadius: "20px",
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <Typography 
                sx={{
                  fontSize: "0.8em", 
                  fontWeight: "600", 
                  color: totalPendientes === 0 
                  ? colors.green
                    : totalPendientes <= 3 
                  ? colors.orange
                  : colors.red,
                  lineHeight: 1
                }}
              >
                {totalPendientes > 0 ? `${totalPendientes}` : "OK"}
              </Typography>
            </Box>
          </Box>

          {/* Modal de gestión de retiros */}
          <RetiroModalComponent
            isOpen={isOpen}
            handleClose={handleClose}
            retirosAgregados={retirosAgregados}
            setRetirosAgregados={setRetirosAgregados}
            choferes={choferes}
            clientes={clientes}
            refetchData={refetchData}
            initialData={null}
          />          
        </>
      )}
    </>
  );
}

export {
  RetirosAsignadosComponent
}