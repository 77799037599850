// Este archivo ha sido corregido para ESLint
/* eslint-disable no-unused-vars */
import React from 'react';
/* eslint-enable no-unused-vars */
import { useJsApiLoader } from '@react-google-maps/api';

// Lista de bibliotecas que necesitamos cargar
const libraries = ['places', 'marker'];

// ID de mapa para marcadores avanzados
// Nota: Debes reemplazar este ID con uno válido de tu cuenta de Google Cloud
const MAP_ID = '8f112ffa7a55b6a0';

// Componente que carga la API de Google Maps y proporciona un contexto para los componentes hijos
const GoogleMapsLoader = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBITfQPAHSXfc_Lw09kwU1TlwJVbJJwMtY',
    libraries,
    mapIds: [MAP_ID],
  });

  if (loadError) {
    return <div>Error al cargar Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Cargando Google Maps...</div>;
  }

  // Modificamos la forma en que pasamos el mapId a los hijos
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      // Aseguramos que las props existentes se mantengan y añadimos/sobreescribimos mapId
      return React.cloneElement(child, {
        ...child.props,
        mapId: MAP_ID,
        options: {
          ...child.props.options,
          mapId: MAP_ID,
        }
      });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};

export default GoogleMapsLoader; 