import React, { memo, useState } from "react";
import GestionMedidasIndividual from "../../../components/OperacionesComponentes/componentesNovo/GestionMedidasIndividual";
import GestionMedidasMasiva from "../../../components/OperacionesComponentes/componentesNovo/GestionMedidasMasiva";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const GestionMedidasNovo = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const tipo_usuario = userInfo ? userInfo.tipo_usuario : null;
  const [selectedModule, setSelectedModule] = useState("");

  const renderModule = () => {
    switch (selectedModule) {
      case "GestionMedidasIndividual":
        return <GestionMedidasIndividual />;
      case "GestionMedidasMasiva":
        return <GestionMedidasMasiva />;
      default:
        return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }
  };

  return (
    <Box p={1}>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        <Button
          onClick={() => setSelectedModule("GestionMedidasIndividual")}
          sx={{
            backgroundColor: selectedModule === "GestionMedidasIndividual" ? "#041562" : undefined,
            color: selectedModule === "GestionMedidasIndividual" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "GestionMedidasIndividual" ? "#DA251C" : undefined,
            },
          }}
        >
          Modificación Individual
        </Button>

        <Button
          onClick={() => setSelectedModule("GestionMedidasMasiva")}
          sx={{
            backgroundColor: selectedModule === "GestionMedidasMasiva" ? "#041562" : undefined,
            color: selectedModule === "GestionMedidasMasiva" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "GestionMedidasMasiva" ? "#DA251C" : undefined,
            },
          }}
        >
          Modificación Masiva
        </Button>
      </ButtonGroup>
      <Box mt={1}>{renderModule()}</Box>
    </Box>
  );
};

export default memo(GestionMedidasNovo);
