import React, { useState, useCallback, useRef } from "react";
import { GoogleMap, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import ciudadesPrincipales from "../../utils/ciudades";
import { mapOptions, loaderOptions } from "../../config/mapsConfig";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const center = { lat: -35.6751, lng: -71.543 };

const extendedMapOptions = {
  ...mapOptions,
  restriction: {
    latLngBounds: {
      north: -15.0,
      south: -60.0,
      west: -84.0,
      east: -62.0,
    },
    strictBounds: false,
  }
};

const MapaMatrizTransito = () => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);

  const { isLoaded } = useJsApiLoader(loaderOptions);

  const handleClickCiudadPrincipal = useCallback(
    (city) => {
      if (selectedCity && selectedCity.name === city.name) {
        setSelectedCity(null);
      } else {
        setSelectedCity(city);
      }
    },
    [selectedCity]
  );

  const handleClickMarker = useCallback((marker) => {
    setSelectedMarker(marker);
  }, []);

  const handleCloseInfoWindow = useCallback(() => {
    setSelectedMarker(null);
  }, []);

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;
    
    if (window.google && window.google.maps.marker) {
      const { AdvancedMarkerElement } = window.google.maps.marker;
      
      // Limpiar marcadores existentes
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];
      
      // Crear nuevos marcadores
      const newMarkers = ciudadesPrincipales.map((city) => {
        const marker = new AdvancedMarkerElement({
          position: { lat: city.lat, lng: city.lng },
          map,
          title: city.name
        });
        
        marker.addEventListener('gmp-click', () => {
          handleClickCiudadPrincipal(city);
          handleClickMarker(city);
        });

        return marker;
      });

      markersRef.current = newMarkers;
    }
  }, [handleClickCiudadPrincipal, handleClickMarker]);

  // Limpiar marcadores al desmontar
  React.useEffect(() => {
    return () => {
      const markers = markersRef.current;
      if (markers.length > 0) {
        markers.forEach(marker => {
          if (marker) {
            marker.setMap(null);
          }
        });
        markersRef.current = [];
      }
    };
  }, []);

  if (!isLoaded) return <div>Cargando mapa...</div>;

  return (
    <div style={{ position: "relative" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5}
        options={extendedMapOptions}
        onLoad={handleMapLoad}
      >
        {selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
            onCloseClick={handleCloseInfoWindow}
          >
            <div>
              <h3>{selectedMarker.name}</h3>
              <p>Lat: {selectedMarker.lat.toFixed(4)}</p>
              <p>Lng: {selectedMarker.lng.toFixed(4)}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
};

export default MapaMatrizTransito;
