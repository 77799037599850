import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import menuRules from "../routes/menuRules";

const ProtectedRoute = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const location = useLocation();

  // 1. Si no hay usuario, se redirige a login
  if (!userInfo || !userInfo.tipo_usuario) {
    return <Navigate to="/login" replace />;
  }

  // 2. Define las rutas que quieres ignorar (no verificar en menuRules)
  const rutasOmitidas = [
    "",
    "inicio-sac",
    "inicio-operaciones",
    "inicio-facturacion",
    "inicio-supervisora",
    "inicio-administracion",
    "inicio-clientes",
    "inicio-graficos",
    "inicio-jefatura",
    "inicio-controlCalidad",
    "inicio-agente",
    "inicio-informatica",
  ];

  // 3. Quita el primer "/" de la ruta (si existe)
  let rutaActual = location.pathname.replace(/^\/+/, "");

  // 4. Si la ruta está en rutasOmitidas, no verifiques en menuRules
  if (rutasOmitidas.includes(rutaActual)) {
    return <Outlet />;
  }

  // 5. Si no está omitida, se revisa en menuRules
  const permisosUsuario = menuRules[userInfo.tipo_usuario] || {};

  if (!permisosUsuario[rutaActual]) {
    console.warn(`⚠️ Acceso denegado a la ruta: ${rutaActual}`);
    return <Navigate to="/unauthorized" replace />;
  }

  // 6. Si todo está OK, deja pasar al usuario
  return <Outlet />;
};

export default ProtectedRoute;
