import React, { useState } from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import {
  ExpandLess,
  ExpandMore,
  Plagiarism,
  Email,
  Download,
  Filter2,
  Edit,
  AllInbox,
  SimCardDownload,
} from "@mui/icons-material";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
/* import BusinessIcon from "@mui/icons-material/Business";
import AssignmentIcon from "@mui/icons-material/Assignment"; */
import EditOffIcon from "@mui/icons-material/EditOff";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import PostAddIcon from "@mui/icons-material/PostAdd";

function MenuOperaciones({ isDrawerOpen, openSubMenu, handleClick, isVisible, hasVisibleSubmenus, location }) {
  const [openSubSubMenu, setOpenSubSubMenu] = useState("");
  const userInfo = useSelector((state) => state.user.userInfo);

  const handleSubSubMenuClick = (menu) => {
    if (openSubSubMenu === menu) {
      setOpenSubSubMenu("");
    } else {
      setOpenSubSubMenu(menu);
    }
  };

  return (
    hasVisibleSubmenus([
      "ListaCorreos",
      "CubicosOperaciones",
      "ManifiestosRetorno",
      "ManifiestoSalida",
      "ManifiestoIngresoBodega",
      "ManifiestosSalidaInhouse",
      "DescargaManifiestos",
      "ManifiestoEntradaAgente",
      "ManifiestoEntradaSantiago",
      "ListadosManifiestos",
      "CreacionEtiqueta",
    ]) && (
      <>
        <ListItemButton onClick={() => handleClick("operaciones")}>
          <ListItemIcon sx={{ color: "white" }}>
            <IntegrationInstructionsIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Operaciones" sx={{ color: "white" }} />}
          {isDrawerOpen &&
            (openSubMenu === "operaciones" ? (
              <ExpandLess sx={{ color: "white" }} />
            ) : (
              <ExpandMore sx={{ color: "white" }} />
            ))}
        </ListItemButton>

        <Collapse in={openSubMenu === "operaciones"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: isDrawerOpen ? 4 : 2 }}>
            {isVisible("SeleccionManifiestoOD") && (
              <ListItemButton
                component={Link}
                to="/SeleccionManifiestoOD"
                sx={{
                  bgcolor: location.pathname === "/SeleccionManifiestoOD" ? "white" : "transparent",
                  color: location.pathname === "/SeleccionManifiestoOD" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/SeleccionManifiestoOD" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/SeleccionManifiestoOD" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/SeleccionManifiestoOD" ? "10px" : "0px",
                  margin: location.pathname === "/SeleccionManifiestoOD" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <FormatListNumberedIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Selección Manifiestos OD" />}
              </ListItemButton>
            )}
            {isVisible("SeleccionManifiestoCB") && (
              <ListItemButton
                component={Link}
                to="/SeleccionManifiestoCB"
                sx={{
                  bgcolor: location.pathname === "/SeleccionManifiestoCB" ? "white" : "transparent",
                  color: location.pathname === "/SeleccionManifiestoCB" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/SeleccionManifiestoCB" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/SeleccionManifiestoCB" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/SeleccionManifiestoCB" ? "10px" : "0px",
                  margin: location.pathname === "/SeleccionManifiestoCB" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <FormatListNumberedIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Selección Manifiesto Cliente" />}
              </ListItemButton>
            )}
            {/* menu varios */}
            {isVisible("VariosOperaciones") && (
              <ListItemButton
                onClick={() => handleSubSubMenuClick("VariosOperaciones")}
                sx={{ justifyContent: "flex-end", color: "white" }}
              >
                <ListItemIcon sx={{ color: "white" }}>
                  <AllInbox />
                </ListItemIcon>
                <ListItemText primary="Varios" />
                {openSubSubMenu === "VariosOperaciones" ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
            <Collapse in={openSubSubMenu === "VariosOperaciones"} timeout="auto" unmountOnExit>
              {/* New client specific operations list here */}
              <List>
                {isVisible("CreacionEtiqueta") && (
                  <ListItemButton
                    component={Link}
                    to="/CreacionEtiqueta"
                    sx={{
                      bgcolor: location.pathname === "/CreacionEtiqueta" ? "white" : "transparent",
                      color: location.pathname === "/CreacionEtiqueta" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/CreacionEtiqueta" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/CreacionEtiqueta" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/CreacionEtiqueta" ? "10px" : "0px",
                      margin: location.pathname === "/CreacionEtiqueta" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter2 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Impresion de etiqueta" />}
                  </ListItemButton>
                )}
                {isVisible("CubicosOperaciones") && (
                  <ListItemButton
                    component={Link}
                    to="/CubicosOperaciones"
                    sx={{
                      bgcolor: location.pathname === "/CubicosOperaciones" ? "white" : "transparent",
                      color: location.pathname === "/CubicosOperaciones" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/CubicosOperaciones" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/CubicosOperaciones" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/CubicosOperaciones" ? "10px" : "0px",
                      margin: location.pathname === "/CubicosOperaciones" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Plagiarism />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Editor Cubicos Retornos" />}
                  </ListItemButton>
                )}

                {isVisible("ListadoPendientes") && (
                  <ListItemButton
                    component={Link}
                    to="/ListadoPendientes"
                    sx={{
                      bgcolor: location.pathname === "/ListadoPendientes" ? "white" : "transparent",
                      color: location.pathname === "/ListadoPendientes" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ListadoPendientes" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ListadoPendientes" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ListadoPendientes" ? "10px" : "0px",
                      margin: location.pathname === "/ListadoPendientes" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <PendingActionsIcon />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Estado Manifiestos" />}
                  </ListItemButton>
                )}
                {isVisible("DescargaManifiestos") && (
                  <ListItemButton
                    component={Link}
                    to="/DescargaManifiestos"
                    sx={{
                      bgcolor: location.pathname === "/DescargaManifiestos" ? "white" : "transparent",
                      color: location.pathname === "/DescargaManifiestos" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/DescargaManifiestos" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/DescargaManifiestos" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/DescargaManifiestos" ? "10px" : "0px",
                      margin: location.pathname === "/DescargaManifiestos" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Download />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Descarga de manifiestos" />}
                  </ListItemButton>
                )}
                {isVisible("ConsultaCodigoBarra") && (
                  <ListItemButton
                    component={Link}
                    to="/ConsultaCodigoBarra"
                    sx={{
                      bgcolor: location.pathname === "/ConsultaCodigoBarra" ? "white" : "transparent",
                      color: location.pathname === "/ConsultaCodigoBarra" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ConsultaCodigoBarra" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ConsultaCodigoBarra" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ConsultaCodigoBarra" ? "10px" : "0px",
                      margin: location.pathname === "/ConsultaCodigoBarra" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Search />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Consulta Codigo Barra NOVO" />}
                  </ListItemButton>
                )}
                {isVisible("GestionMedidasNovo") && (
                  <ListItemButton
                    component={Link}
                    to="/GestionMedidasNovo"
                    sx={{
                      bgcolor: location.pathname === "/GestionMedidasNovo" ? "white" : "transparent",
                      color: location.pathname === "/GestionMedidasNovo" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/GestionMedidasNovo" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/GestionMedidasNovo" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/GestionMedidasNovo" ? "10px" : "0px",
                      margin: location.pathname === "/GestionMedidasNovo" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Edición Medidas NOVO" />}
                  </ListItemButton>
                )}
                {isVisible("ListaCorreos") && (
                  <ListItemButton
                    component={Link}
                    to="/ListaCorreos"
                    sx={{
                      bgcolor: location.pathname === "/ListaCorreos" ? "white" : "transparent",
                      color: location.pathname === "/ListaCorreos" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ListaCorreos" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ListaCorreos" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ListaCorreos" ? "10px" : "0px",
                      margin: location.pathname === "/ListaCorreos" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Gestor de correos" />}
                  </ListItemButton>
                )}
                {userInfo.id === 739 || userInfo.id === 722 || userInfo.id === 70 || userInfo.tipo_usuario === "ADMIN"
                  ? isVisible("GestorManifiestoSalida") && (
                      <ListItemButton
                        component={Link}
                        to="/GestorManifiestoSalida"
                        sx={{
                          bgcolor: location.pathname === "/GestorManifiestoSalida" ? "white" : "transparent",
                          color: location.pathname === "/GestorManifiestoSalida" ? "#041562" : "white",
                          ".MuiListItemText-primary": {
                            fontSize: "0.775rem",
                          },
                          pl: isDrawerOpen ? 4 : 2,
                          "& .MuiListItemIcon-root": {
                            color: location.pathname === "/GestorManifiestoSalida" ? "#041562" : "white",
                            minWidth: isDrawerOpen ? "auto" : 25,
                            mr: isDrawerOpen ? 2 : "auto",
                            justifyContent: "center",
                          },
                          "& .MuiListItemText-primary": {
                            color: location.pathname === "/GestorManifiestoSalida" ? "#041562" : "white",
                          },
                          "&:hover": {
                            bgcolor: "white",
                            ".MuiListItemIcon-root": { color: "#041562" },
                            ".MuiListItemText-primary": { color: "#041562" },
                            borderRadius: "10px",
                          },
                          borderRadius: location.pathname === "/GestorManifiestoSalida" ? "10px" : "0px",
                          margin: location.pathname === "/GestorManifiestoSalida" ? "8px" : "0px",
                          overflow: "hidden",
                        }}
                      >
                        <ListItemIcon>
                          <EditOffIcon />
                        </ListItemIcon>
                        {isDrawerOpen && <ListItemText primary="Gestor Manifiestos" />}
                      </ListItemButton>
                    )
                  : null}
                {isVisible("InformeCargaDiaria") && (
                  <ListItemButton
                    component={Link}
                    to="/InformeCargaDiaria"
                    sx={{
                      bgcolor: location.pathname === "/InformeCargaDiaria" ? "white" : "transparent",
                      color: location.pathname === "/InformeCargaDiaria" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/InformeCargaDiaria" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/InformeCargaDiaria" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/InformeCargaDiaria" ? "10px" : "0px",
                      margin: location.pathname === "/InformeCargaDiaria" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <FormatListNumberedRtlIcon />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Informe Diario" />}
                  </ListItemButton>
                )}
                {isVisible("ConsultaInformeCargaDiaria") && (
                  <ListItemButton
                    component={Link}
                    to="/ConsultaInformeCargaDiaria"
                    sx={{
                      bgcolor: location.pathname === "/ConsultaInformeCargaDiaria" ? "white" : "transparent",
                      color: location.pathname === "/ConsultaInformeCargaDiaria" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ConsultaInformeCargaDiaria" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ConsultaInformeCargaDiaria" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ConsultaInformeCargaDiaria" ? "10px" : "0px",
                      margin: location.pathname === "/ConsultaInformeCargaDiaria" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <SimCardDownload />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Consulta Informe Diario" />}
                  </ListItemButton>
                )}

                {isVisible("SolicitudModificacion") && (
                  <ListItemButton
                    component={Link}
                    to="/SolicitudModificacion"
                    sx={{
                      bgcolor: location.pathname === "/SolicitudModificacion" ? "white" : "transparent",
                      color: location.pathname === "/SolicitudModificacion" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/SolicitudModificacion" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/SolicitudModificacion" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/SolicitudModificacion" ? "10px" : "0px",
                      margin: location.pathname === "/SolicitudModificacion" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <PostAddIcon />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Solicitud Modificacion" />}
                  </ListItemButton>
                )}
              </List>
            </Collapse>
          </List>
        </Collapse>
      </>
    )
  );
}

export default MenuOperaciones;
