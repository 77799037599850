import React, { useState } from "react";
import { Typography, Autocomplete, TextField, Box } from "@mui/material";
import ListadosManifiestos from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorOd/ListadosManifiestos";
import Inhouse from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorOd/Inhouse";
import EntradaAnden from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorOd/EntradaAnden";
import SalidaAnden from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorOd/SalidaAnden";
import EntradaAgente from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorOd/EntradaAgente";
import SalidaAgente from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorOd/SalidaAgente";
import IngresoSantiago from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorOd/IngresoSantiago";
import RetornoClientes from "../../../components/OperacionesComponentes/componentesManifiestos/ManifiestosPorOd/RetornoClientes";
import { useSelector } from "react-redux";

const opcionesManifiestos = [
  { label: "1. Inhouse", value: "Inhouse" },
  { label: "2. Entrada Anden", value: "EntradaAnden" },
  { label: "3. Salida Anden", value: "SalidaAnden" },
  { label: "4. Entrada Agente", value: "EntradaAgente" },
  { label: "5. Manifiestos Ruta", value: "ManifiestosRuta" },
  { label: "6. Salida Agente", value: "SalidaAgente" },
  { label: "7. Ingreso Santiago", value: "IngresoSantiago" },
  { label: "8. Retorno Clientes", value: "RetornoClientes" },
];

const SeleccionManifiestoOD = () => {
  const [selectedModule, setSelectedModule] = useState(null);
  const userInfo = useSelector((state) => state.user.userInfo);

  const usuarioAgente = ["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo?.tipo_usuario);
  const usuarioOperaciones = ["OPERACIONES", "SUPERVISOR"].includes(userInfo?.tipo_usuario);

  const opcionesFiltradas = usuarioAgente
    ? opcionesManifiestos.filter(({ value }) => ["EntradaAgente", "ManifiestosRuta", "SalidaAgente"].includes(value))
    : usuarioOperaciones
      ? opcionesManifiestos.filter(({ value }) => !["EntradaAgente", "SalidaAgente"].includes(value))
      : opcionesManifiestos;

  const renderModule = () => {
    switch (selectedModule) {
      case "ManifiestosRuta":
        return <ListadosManifiestos />;
      case "Inhouse":
        return <Inhouse />;
      case "EntradaAnden":
        return <EntradaAnden />;
      case "SalidaAnden":
        return <SalidaAnden />;
      case "EntradaAgente":
        return <EntradaAgente />;
      case "SalidaAgente":
        return <SalidaAgente />;
      case "IngresoSantiago":
        return <IngresoSantiago />;
      case "RetornoClientes":
        return <RetornoClientes />;

      default:
        return <Typography variant="h6">Seleccione Manifiesto a Realizar por OD</Typography>;
    }
  };

  return (
    <Box>
      <Autocomplete
        options={opcionesFiltradas}
        getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => setSelectedModule(newValue ? newValue.value : "")}
        disableClearable
        freeSolo={false}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Seleccionar Manifiesto"
            variant="outlined"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
      <Box mt={2}>{renderModule()}</Box>
    </Box>
  );
};

export default SeleccionManifiestoOD;
