const BASE_URL = process.env.REACT_APP_BACKEND_URL
const getClients = async (setData, setError) => {
  const url = `${BASE_URL}clientes/obtenerClientes`
  try {
    const r = await fetch(url)
    if(!r.ok){
      throw new Error("Algo fue mal obteniendo los clientes.")
    }
    const rr = await r.json()
    setData(rr)
  } catch(e){
    setError((prev) => ({...prev, clientes:e.message}))
  }
}

const getDestinatarios = async (id, setDestinatarios, setDestinatariosError) => {
  try{
    const r = await fetch(`${BASE_URL}clientes/obtenerDestinatarios/${id}`,{
      headers:{
        Accept:"*/*",
        "Content-Type":"application/json; charset=utf-8",
        "Access-Control-Allow-Origin":"*"
      }
    })
    const rr = await r.json()

    if(r.status === 404) throw new Error(`${rr['Error']}`)
    if(!r.ok){
      throw new Error("Algo fue mal durante la obtencion del destinatario.")
    }
    setDestinatarios(rr)
  }catch(e){
    setDestinatariosError(e.message)
  }
}

const getDestinatariosAgencia = async (id) => {
  try{
    const r = await fetch(`${BASE_URL}clientes/obtenerDestinatariosPorAgencia/${id}`,{
      headers:{
        Accept:"*/*",
        "Content-Type":"application/json; charset=utf-8",
        "Access-Control-Allow-Origin":"*"
      }
    })
    const rr = await r.json()

    if(r.status === 404) throw new Error(`${rr['Error']}`)
    if(!r.ok){
      throw new Error("Algo fue mal durante la obtencion del destinatario.")
    }
    return rr
  }catch(e){
    return e.message
  }
}


const deleteReporteAutomaticoAgente = async (email) => {
  try{
    const r = await fetch(`${BASE_URL}clientes/eliminarReporteAutomaticoAgente/${email}`, {
      method: "DELETE"
    })
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante la eliminacion.")
    return rr
  }catch(e){
    return e.message
  }
}



const getReportesAutomaticosProgramados = async (cod_cliente, setReportes, setReportesError) => {
  try{
    const r = await fetch(`${BASE_URL}clientes/obtenerReportesAutomaticos/${cod_cliente}`,{
      headers:{
        Accept:"*/*",
        "Content-Type":"application/json; charset=utf-8",
        "Access-Control-Allow-Origin":"*"
      }
    })
    const rr = await r.json()

    if(r.status === 404) throw new Error(rr['Error'])
    if(!r.ok){
      throw new Error("Algo fue mal durante la obtencion de los reportes automaticos.")
    }
    setReportes(rr)
  }catch(e){
    setReportesError(e.message)
  }
}

const insertReporteAtuomaticoProgramado = async (data, setDestinatariosProgramadosError) => {
  try{
    const {cod_cliente, horario,destinatarios, cabeceras} = data
    const r = await fetch(`${BASE_URL}clientes/crearReporteAutomatico`, {
      headers:{
        Accept:"*/*",
        "Content-Type":"application/json; charset=utf-8",
        "Access-Control-Allow-Origin":"*"
      },
      method:"POST",
      body:JSON.stringify({cod_cliente, horario, destinatarios, cabeceras})
    })
    const rr = await r.json()
    if(!r.ok ) throw new Error(`Algo fue mal durante el registro del nuevo reporte: ${rr['Error']}`)
    return true
  }catch(e){
    setDestinatariosProgramadosError(e.message)
    return false
  }
}


const getDestinatariosAsignables = async (cod_cliente,setDestinatariosAsignables, setDestinatariosAsignError) => {
  try {
    const r = await fetch(`${BASE_URL}clientes/obtenerDestinatariosAsignables/${cod_cliente}`, {
      headers:{
        Accept:"*/*",
        "Content-Type":"application/json; charset=utf-8",
        "Access-Control-Allow-Origin":"*"
      }
    })
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante la obtencion de destinatarios asignables.")
    setDestinatariosAsignables(rr)
  } catch(e){
    setDestinatariosAsignError(e.message)
  }
}

const deleteActualReporteAutomatico = async (cod_cliente, setDelError) => {
  try {
    const r = await fetch(`${BASE_URL}clientes/eliminarReporteAutomatico/${cod_cliente}`,{
      headers:{
        Accept:"*/*",
        "Content-Type":"application/json; charset=utf-8",
        "Access-Control-Allow-Origin":"*"
      },
      method:"DELETE"
    })
    const rr = await r.json()
    if(!r.ok) throw new Error(`Algo fue mal durante la eliminacion: ${rr['Error']}`)
    return true
  }catch(e){
    setDelError(e.message)
    return false
  }
}

const getRegistroDestinatariosCorreoEnviado = async (cod_cliente, setRegistroDest, setRegistroDestError) => {
  try{
    const r = await fetch(`${BASE_URL}clientes/obtenerReportesEnviadosStatus/${cod_cliente}`, {
      headers:{
        Accept:"*/*",
        "Content-Type":"application/json; charset=utf-8",
        "Access-Control-Allow-Origin":"*"
      }
    })
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante la obtencion de estado de los destinatarios.")
    setRegistroDest(rr)
  } catch(e){
    setRegistroDestError(e.message)
  }
}

const getReporteCols = async (cod_cliente, setData) => {
  try{
    const r = await fetch(`${BASE_URL}clientes/obtenerReporteAutomaticoCols${cod_cliente && `?COD_CLIENTE=${cod_cliente}` }`)
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante la obtencion de las cabeceras.")
    setData(rr)
  } catch(e) {
    return e.message
  }
}


const getAgentes = async () => {
  try{
    const r = await fetch(`${BASE_URL}correosProgramadosRoute/getAgentes`)
    const rr = await r.json()
    return rr
  } catch(e){
    return e.message
  }
}


const getAgentesReporteCols = async (email) => {
  try{
    const r = await fetch(`${BASE_URL}correosProgramadosRoute/getAgentesReporteAutoHeaders?EMAIL=${email}`)
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante la obtencion de las cabeceras.")
    return rr
  } catch(e){
    return e.message
  }
} 


const insertReporteAutomaticoAgente = async (data) => {
  try{
    const r = await fetch(`${BASE_URL}correosProgramadosRoute/insertReporteAutomaticoAgentes`, {
      method: "POST",
       headers: {
        'Content-Type': 'application/json'  // Añadir esta línea
      },
      body: JSON.stringify(data)
    })
    const rr = await r.json()
    if(!r.ok) throw new Error("Algo fue mal durante el registro del nuevo reporte.")
    return rr
  } catch(e){
    return e.message
  }
}

export {
  getReportesAutomaticosProgramados,
  getDestinatariosAsignables,
  getClients,
  getDestinatarios,
  getRegistroDestinatariosCorreoEnviado,
  deleteActualReporteAutomatico,
  insertReporteAtuomaticoProgramado,
  getReporteCols,
  getAgentes,
  getAgentesReporteCols,
  insertReporteAutomaticoAgente,
  getDestinatariosAgencia,
  deleteReporteAutomaticoAgente
}
